import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  DiscoveryToolbarNavbarHeight,
  IDiscoverEmotionTheme,
  useDiscoverTheme,
} from '../../common/emotion';
import { Tabs } from '@mui/material';

export const LeftNav = props => {
  const {
    colors: { ActiveTabIndicator },
  } = useDiscoverTheme();

  return (
    <Tabs
      {...props}
      sx={{
        '& .MuiTabs-indicator': {
          color: ActiveTabIndicator,
        },
        button: {
          width: '56px',
          height: '52px',
        },
      }}
    />
  );
};

export const SaveDropdownStyles = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 4px;

  .dropdown-save-icon {
    padding-left: 4px;
    padding-top: 5px;
  }
`;

export const NavWithLeftMargin = styled.div<{ leftOffset: number }>(
  ({ leftOffset }) => {
    return css`
      width: auto;
      max-width: 50vw;
      margin-left: ${leftOffset - 5}px;
    `;
  },
);

export const NavItemFullWidth = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 4px;
`;

export const DiscoveryToolbarNavbar = styled.nav(
  ({
    theme: {
      colors: { PanelBackground, PrimaryColor } = {} as any,
    } = {} as any,
  }: {
    theme?: IDiscoverEmotionTheme;
  }) => css`
    background-color: transparent;
    border-radius: 0px;
    border: none;
    margin: 0px;

    & .container {
      width: 100%;
      padding: 0px;
      height: ${DiscoveryToolbarNavbarHeight};
      display: flex;
    }

    #discovery-toolbar-left,
    #discovery-toolbar-right {
      padding-left: 0;
      margin-right: 12px;
    }
    #discovery-toolbar-left,
    #discovery-toolbar-actions {
      display: flex;
    }
    #discovery-toolbar-actions {
      margin-left: auto;
      align-items: center;
      padding-top: 4px;
      button {
        height: 50px;
      }
      .pin-item {
        button {
          min-width: 36px;
          width: 36px;
        }
      }
    }
    #discovery-toolbar-right {
      padding-top: 3px;
      button {
        width: 56px;
      }
    }

    #discovery-toolbar-left {
      z-index: 100;
      position: relative;
      border-bottom: none;
    }

    .down-chevron {
      padding-left: 5px;
      padding-right: 5px;
    }
    #discovery-toolbar-left,
    #discovery-toolbar-right {
      li.active > a {
        background-color: ${PanelBackground} !important;
      }
    }

    .dropdown.open .down-chevron {
      .sicon-chevron-down {
        color: ${PrimaryColor} !important;
      }
      border-color: ${PrimaryColor};
      border-radius: 3px;
      border-style: solid;
      border-width: 2px;
      padding-left: 2px;
      padding-right: 5px;
    }
  `,
);

export const ToolbarDropdownRoot = styled.div(
  ({ theme: { colors: { ShareDropdownColor } = {} as any } = {} }: any) => css`
    display: flex;
    align-items: center;
    color: ${ShareDropdownColor};
  `,
);
