import _ from 'lodash';
import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IDiscoveryTracked, ILayout, IVizQueryError } from '../../../discovery';
import { VIZ_SELECTORS, getConfigPanelDetail } from './viz-selectors';
import { ChartSpecs } from '../../../discovery/ChartSpecs';
import { Viz } from '../../../discovery/VizUtil';
import { IAppliedFilters } from '../../../datasets';
import { IDashletFilter } from '../../utilities/sugar-filter-converter/sugar-filter-converter.interfaces';
import { addDashletSuffix, SugarFilterContext } from '../../Constants';
import { convertSugarFilter } from '../../utilities/sugar-filter-converter/sugar-filter-converter';
import { ILinkToReport } from '../../../discovery/viz-redirect';

export const useDiscoveryIdSelector = ({
  discoveryId,
}: { discoveryId?: string } = {}) => {
  const id = useSelector(state =>
    VIZ_SELECTORS.getDiscoveryId(state, { discoveryId }),
  );
  return id;
};

export const useOpenDiscoveriesSelector = () => {
  const openDiscoveries: { [key: string]: IDiscoveryTracked } = useSelector(
    (state: any) => state.discover?.openDiscoveries,
  );
  return openDiscoveries;
};

export const useConfigPanelDetailSelector = ({ discoveryId }) => {
  const configPanelDetail = useSelector(state =>
    getConfigPanelDetail(state, { discoveryId }),
  );
  return configPanelDetail;
};

export const useOpenDiscoverySelector = ({
  discoveryId,
}: { discoveryId?: string } = {}) => {
  const _discoveryId = useDiscoveryIdSelector({ discoveryId });
  const openDiscoveries = useOpenDiscoveriesSelector();
  const openDiscovery = useMemo(() => {
    return openDiscoveries[_discoveryId];
  }, [_discoveryId, openDiscoveries]);
  return openDiscovery;
};

export const useOpenDiscoveryPresentStateSelector = ({
  discoveryId,
}: { discoveryId?: string } = {}) => {
  const open = useOpenDiscoverySelector({ discoveryId });
  const present = useMemo(() => open?.present, [open?.present]);
  return present;
};

export const useOpenVizSelector = ({
  discoveryId,
}: { discoveryId?: string } = {}) => {
  const present = useOpenDiscoveryPresentStateSelector({ discoveryId });
  const viz = useMemo(() => present?.viz, [present?.viz]);
  return viz;
};

export const useOpenVizDatasetSelector = ({
  discoveryId,
}: { discoveryId?: string } = {}) => {
  const viz = useOpenVizSelector({ discoveryId });
  return viz?.dataset;
};

export const useOpenVizLayoutSelector = ({
  discoveryId,
}: { discoveryId?: string } = {}): ILayout => {
  const viz = useOpenVizSelector({ discoveryId });
  return viz?.layout;
};

export const useOpenVizChartSpec = ({
  discoveryId,
}: { discoveryId?: string } = {}) => {
  const viz = useOpenVizSelector({ discoveryId });
  return ChartSpecs[viz.chartType];
};

export const useOpenVizDatasetAttributesSelector = ({
  discoveryId,
}: { discoveryId?: string } = {}) => {
  const dataset = useOpenVizDatasetSelector({ discoveryId });
  return dataset?.attributes;
};

export const useActiveVizFieldSelector = ({
  discoveryId,
  name,
}: {
  discoveryId?: string;
  name: string;
}) => {
  const attributes = useOpenVizDatasetAttributesSelector({ discoveryId });
  const field = useMemo(() => _.find(attributes, { name }), [attributes, name]);
  return field;
};

export const useFieldAnnotationValueSelector = ({
  discoveryId,
  name,
  key,
}: {
  discoveryId?: string;
  name: string;
  key: string;
}) => {
  const field = useActiveVizFieldSelector({ discoveryId, name });
  const value = useMemo(() => _.find(field?.annotations, { key })?.value, [
    field?.annotations,
    key,
  ]);
  return value;
};

export const useQueryIdSelector = ({
  discoveryId,
}: { discoveryId?: string } = {}) => {
  const viz = useOpenVizSelector({ discoveryId });
  const queryId = useMemo(() => viz?.queryId, [viz?.queryId]);
  return queryId;
};

export const useVizOptionsSelector = ({
  discoveryId,
}: { discoveryId?: string } = {}) => {
  const viz = useOpenVizSelector({ discoveryId });
  const options = useMemo(() => viz?.options, [viz?.options]);
  return options;
};

export const useVizRuntimeFilterSelector = ({
  discoveryId,
}: { discoveryId?: string } = {}): IAppliedFilters => {
  const filters = useVizOptionSelector({
    discoveryId,
    option: 'filters',
  });

  const availableRuntimeFilters = useVizOptionSelector({
    discoveryId,
    option: 'runtimeFilters',
  });

  const sugarRuntimeFilters: IDashletFilter = useContext(
    SugarFilterContext,
  ) as IDashletFilter;

  const additionalFilters = convertSugarFilter(
    sugarRuntimeFilters[discoveryId],
    availableRuntimeFilters,
  );

  return useMemo(() => {
    return !_.isEmpty(additionalFilters)
      ? { ...filters, ...additionalFilters }
      : filters;
  }, [additionalFilters, filters]);
};

const useParsedValue = ({ rawValue: raw, defaultValue, skipParse }) => {
  return useMemo(() => {
    if (_.isUndefined(raw)) {
      return defaultValue;
    }
    if (skipParse) {
      return raw;
    }
    try {
      return JSON.parse(raw);
    } catch {
      return raw;
    }
  }, [raw, skipParse, defaultValue]);
};

export const useVizOptionSelector = <T = any>({
  discoveryId,
  option,
  skipParse = false,
  defaultValue,
}: {
  discoveryId?: string;
  option: string;
  skipParse?: boolean;
  defaultValue?: T;
}) => {
  const options = useVizOptionsSelector({ discoveryId });
  const raw = _.get(options, option);
  return useParsedValue({
    rawValue: raw,
    defaultValue,
    skipParse,
  });
};

export const useReportLinkMaybeSideDrawer = ({
  discoveryId,
}): ILinkToReport => {
  const linkToReportOption = useVizOptionSelector({
    discoveryId,
    option: 'linkToReport',
    defaultValue: {},
  });

  const { isDashletMode = false, hasSideDrawerDrill = false } = useSelector(
    (state: any) => state.dashlet ?? {},
  );

  return useMemo(() => {
    if (isDashletMode && hasSideDrawerDrill) {
      linkToReportOption.id = addDashletSuffix(linkToReportOption?.id);
    }

    return linkToReportOption;
  }, [isDashletMode, hasSideDrawerDrill, linkToReportOption]);
};

export const useCustomFormatToggleSelector = ({
  discoveryId,
  toggleName,
  skipParse = false,
  defaultValue = {},
}) => {
  const viz = useOpenVizSelector({ discoveryId });
  const customFormatToggles = Viz.getCustomFormatTogglesFromViz(viz);

  const unparsedValue = _.find(customFormatToggles, { key: toggleName });

  return useParsedValue({
    rawValue: unparsedValue,
    defaultValue,
    skipParse,
  });
};

export const useLiveQuerySelector = ({
  discoveryId,
}: { discoveryId?: string } = {}) => {
  const value = useVizOptionSelector({
    discoveryId,
    option: 'useLiveQuery',
    defaultValue: true,
  });
  return value;
};

export const useVizNameSelector = ({
  discoveryId,
}: { discoveryId?: string } = {}) => {
  const viz = useOpenVizSelector({ discoveryId });
  const vizName = useMemo(() => viz?.name, [viz?.name]);
  return vizName;
};

export const useMonitorEventIdSelector = ({
  discoveryId,
}: { discoveryId?: string } = {}) => {
  const present = useOpenDiscoveryPresentStateSelector({ discoveryId });
  const viz = useMemo(() => present?.monitorEventId, [present?.monitorEventId]);
  return viz;
};

export const useUndoStackSelector = ({
  discoveryId,
}: { discoveryId?: string } = {}) => {
  const open = useOpenDiscoverySelector({ discoveryId });
  const undoStack = useMemo(() => open?.past, [open?.past]);
  return undoStack;
};

export const useQueryErrorSelector = ({
  discoveryId,
}: { discoveryId?: string } = {}) => {
  const present = useOpenDiscoveryPresentStateSelector({ discoveryId });
  const vizQueryError: IVizQueryError = useMemo(() => present?.vizQueryError, [
    present?.vizQueryError,
  ]);
  return vizQueryError;
};

export const useHasUndoItemsSelector = ({
  discoveryId,
}: { discoveryId?: string } = {}) => {
  const undoStack = useUndoStackSelector({ discoveryId });
  return !_.isEmpty(undoStack);
};

export const useQueryErrorHttpCodeSelector = ({
  discoveryId,
}: { discoveryId?: string } = {}) => {
  const queryError: any = useQueryErrorSelector({ discoveryId });
  const statusCode: number = useMemo(
    () => queryError?.error?.networkError?.statusCode,
    [queryError?.error?.networkError?.statusCode],
  );
  return statusCode;
};
