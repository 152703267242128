import { css } from '@emotion/react';
import {
  ellipsis,
  vbox,
  hbox,
  font,
  flexBox,
  flexFill,
  svgColorizePartial,
  noSelect,
  positionAbsoluteContainer,
  DiscoveryToolbarNavbarHeight,
  VizTabNavOuterHeight,
  ModernBorderRadius,
} from './mixins';
import { DefaultFontName } from '../../components/ui/fonts';
import { whiteStripesFill } from '../Constants';

const dropdownWidth = '200px';
const pillHeight = '16px';

export const discoverStyles = ({
  theme: {
    darkMode,
    colors: {
      ActiveLinkText,
      anomalyColor,
      baseFontColor,
      ChartLabelTextColor,
      ContentBackground,
      ContentText,
      CorvanaBrandOrangeHighlight,
      CorvanaBrightBlue,
      CorvanaFaintBlue,
      CorvanaLightText,
      CorvanaMediumBorder,
      DefaultButtonTextColor,
      DefaultButtonTextColorActive,
      DefaultButtonTextSecondaryColorActive,
      DisabledPrimaryButtonColor,
      DropdownBackgroundHover,
      DropdownTextHover,
      DropdownTextPrimary,
      ErrorStatusBackground,
      ErrorStatusTextColor,
      FieldPillBackgroundActive,
      FieldPillBackgroundActiveBorder,
      FieldPillBackgroundDrag,
      FieldPillBackgroundHover,
      FieldPillBackgroundHoverBorder,
      Gray20,
      InputTextColor,
      LightFontWeight,
      MediumFontWeight,
      negativeColor,
      PanelBorder,
      PanelIconActiveColor,
      PanelIconColor,
      PanelIconDisabledColor,
      PanelIconHoverColor,
      PanelIconSecondaryColor,
      PrimaryButtonTextColor,
      RegularFontWeight,
      SensitivityHeaderText,
      Strokes,
      BorderColor,
      ToolIconColor,
      ToolbarIconFill,
      ToolIconHoverColor,
      ToolIconSecondaryHoverColor,
      TooltipTextColor,
      PanelBackground,
      Gray70,
      PlaceholderText,
    },
  },
}) => css`
  .panel-tabs {
    position: absolute;
    left: -105px;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100px;
  }
  .panel-tabs .panel-tab-icon {
    cursor: pointer;
  }
  .panel-tabs .panel-tab-icon path {
    fill: ${CorvanaBrandOrangeHighlight};
  }
  .panel-tabs .panel-tab-icon.disabled path {
    fill: ${CorvanaLightText};
  }
  .panel-tabs .panel-tab-icon.disabled rect {
    stroke: none;
  }
  #monitor-detail-panel {
    position: relative;
    width: 320px;
  }
  #monitor-detail-panel .viz-insights-panel {
    flex: 1;
    overflow-y: auto;
  }
  #discover-content-panel {
    ${vbox()}
    height: 100%;
    overflow: hidden;
  }
  #discover-content-panel .discover-open-panel {
    ${hbox()}
    flex: 1;
    height: 100%;
  }
  #discover-content-panel .discover-tab-panel {
    ${vbox()}
    flex: 1;
  }
  #discover-content-panel .hidden {
    display: none;
  }
  ${
    darkMode
      ? `.viz-container .panel.panel-default {
    border-color: ${BorderColor};
  }`
      : ''
  }
  .new-discovery-placeholder-label {
    margin-left: 10px;
  }
  textarea.form-control.jsonTextArea {
    height: 180px;
  }
  .viz-detail,
  .monitor-detail {
    flex: 1;
    ${hbox()}
    height: calc(100% - ${DiscoveryToolbarNavbarHeight});
    max-height: calc(100% - ${DiscoveryToolbarNavbarHeight});
  }
  .viz-detail > .left-panel,
  .monitor-detail > .left-panel,
  .viz-detail > .right-panel,
  .monitor-detail > .right-panel {
    display: flex;
  }
  .viz-chart-top-panel {
    background-color: ${ContentBackground};
    width: calc(100% - 20px);
    display: flex;
    flex-flow: column nowrap;
    margin-left: 20px;
    margin-bottom: 1rem;
    border-top: 1px solid ${Strokes};
    min-height: 42px;
      
    &.details-closed {
      width: 100%;
      padding-left: 20px;
      margin-left: 0;
    }
  }
  .viz-detail > .content {
    background-color: ${ContentBackground};
    flex: 1;
    ${vbox()}
    position: relative;
  }
  .viz-detail > .content .viz-chart {
    display: flex;
    flex: 1;
    max-width: 100%;
    user-select: none;
    height: 100%;
    width: 100%;
  }
  .viz-detail > .content .viz-chart .viz-chart-container {
    height: 100%;
    width: 100%;
  }
  .viz-detail > .content .viz-chart > svg {
    height: 100%;
    width: 100%;
  }
  .viz-detail > .content .viz-chart > svg [fill="${whiteStripesFill}"] {
    pointer-events: none;
  }
  .viz-detail > .content .viz-chart .x-axis-label,
  .viz-detail > .content .viz-chart .y-axis-label {
    ${font({ size: '18px', weight: MediumFontWeight })}
    text-anchor: middle;
    fill: ${ContentText};
  }
  .viz-detail > .content .viz-chart .xAxisTickFont .tick line,
  .viz-detail > .content .viz-chart .xAxisTickFont .domain {
    stroke: ${ContentText};
    opacity: 0.48;
  }
  .viz-detail > .content .viz-chart .xAxisTickFont.mutedLine .domain,
  .viz-detail > .content .viz-chart .yAxisTickFont.mutedLine .domain {
    stroke: ${CorvanaMediumBorder};
  }
  .viz-detail > .content .viz-chart .xAxisTickFont .tick text,
  .viz-detail > .content .viz-chart .yAxisTickFont .tick text {
    ${font({ size: '12px', weight: LightFontWeight })}
    fill: ${ContentText};
  }
  .viz-detail .viz-placeholder,
  .viz-chart-container .viz-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
  }
  .viz-detail .viz-placeholder > div {
    display: flex;
    flex-direction: column;
  }
  .viz-detail .viz-placeholder.error p,
  .viz-detail .viz-placeholder.error ul {
    color: ${ErrorStatusTextColor};
    font-weight: ${LightFontWeight};
  }
  .viz-detail .viz-placeholder.error ul {
    margin: auto;
    max-height: 20vh;
    overflow-y: auto;
  }
  .viz-detail .viz-placeholder.error Button {
    margin: 30px auto;
    width: fit-content;
  }
  .viz-detail .viz-placeholder p {
    ${font({ size: '20px', weight: LightFontWeight, color: CorvanaLightText })}
    text-align: center;
    margin-top: 16px;
  }
  .clipped-chart-body {
    /* Safari Blend Mode fix */
    isolation: isolate;
  }
  .monitor-detail > .content {
    background-color: ${ContentBackground};
    flex: 1;
    ${vbox()}
    position: relative;
    user-select: none;
  }
  .monitor-detail > .content > .time-filter-buttons {
    padding: 20px 0px 0px 20px;
  }
  .monitor-detail > .content > .time-filter-buttons label.btn {
    ${font({ size: '14px', weight: LightFontWeight })}
  }
  .monitor-detail
    > .content
    > .time-filter-buttons
    .custom-time-toggle-button
    > input {
    display: none;
  }
  .monitor-detail > .content > .time-filter-buttons button > img {
    height: 24px;
    margin-top: 5px;
  }
  .monitor-detail > .content > .monitor-chart {
    flex: 1;
    margin-bottom: 20px;
  }
  .monitor-detail > .content > .monitor-chart > svg {
    height: 100%;
    width: 100%;
  }
  .monitor-detail > .content > .monitor-chart .anomalyLine,
  .monitor-detail > .content > .monitor-chart .anomalyBar,
  .monitor-detail > .content > .monitor-chart .valArea,
  .monitor-detail > .content > .monitor-chart .valLine {
    clip-path: url('#clip-monitor-detail');
  }
  .queryToolTable {
    max-height: 300px;
    margin-bottom: 15px;
  }
  .control-button-group button:last-of-type {
    margin-right: 20px;
  }
  .control-button-group button {
    border: none;
    padding: 0;
    margin-right: 10px;
  }
  .control-button-group button .panel-tab-icon {
    cursor: pointer;
  }
  .control-button-group button .panel-tab-icon rect:not(#border),
  .control-button-group button .panel-tab-icon circle {
    fill: ${CorvanaBrandOrangeHighlight};
  }
  .control-button-group button .panel-tab-icon.disabled #border {
    stroke: none;
  }
  .control-button-group button .panel-tab-icon.disabled rect:not(#border),
  .control-button-group button .panel-tab-icon.disabled circle {
    fill: ${CorvanaLightText};
  }
  .control-button-group button img {
    height: 24px;
  }
  .control-button-group button,
  .control-button-group button:focus,
  .control-button-group button:hover,
  .control-button-group button:active,
  .control-button-group button:active:focus {
    background: none;
    box-shadow: none;
  }
  #anomaly-settings {
    padding: 8px;
  }
  #anomaly-settings > div {
    margin-bottom: 10px;
  }
  #anomaly-settings a {
    color: ${CorvanaBrightBlue};
    cursor: pointer;
  }
  #anomaly-settings .help-icon {
    margin-left: 8px;
    display: inline-block;
    position: relative;
    top: 4px;
  }
  #anomaly-settings .help-icon path {
    fill: ${ToolIconColor};
  }
  #anomaly-settings .help-icon:hover path {
    fill: ${ToolIconHoverColor};
  }
  .settings-card {
    background-color: ${ContentBackground};
    border: 1px solid ${PanelBorder};
    border-radius: 4px;
    padding: 16px;
  }
  .sensitivity-header {
    text-align: center;
  }
  .sensitivity-header h1 {
    ${font({ size: '24px', weight: MediumFontWeight })}
    margin-top: 0px;
    margin-bottom: 5px;
  }
  .sensitivity-header h2 {
    ${font({
      size: '16px',
      weight: LightFontWeight,
      color: SensitivityHeaderText,
    })}
    margin-top: 14px;
  }
  .sensitivity-body {
    padding: 6px 9px 9px 9px;
    height: 100%;
    ${flexBox()}
    ${vbox()}
  }
  .sensitivity-body .sensitivity-panel {
    ${flexFill()}
  }
  .sensitivity-container {
    display: flex;
    margin-bottom: 20px;
  }
  .sensitivity-panel {
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
  .sensitivity-panel > p {
    ${font({ size: '24px', weight: LightFontWeight })}
    margin: 0 0 16px;
  }
  .sensitivity-item > .form-group {
    margin-bottom: 0px;
  }
  .second-sensitivity-item {
    margin-left: 32px;
  }
  .sensitivityWidget {
    width: 568px;
  }
  .anomaly-sensitivity-dialog .valLine {
    stroke-width: 1px;
  }
  .anomaly-sensitivity-dialog .control-label {
    ${font({ size: '16px' })}
    margin-bottom: 8px;
    text-align: center;
    padding: 5px 0px;
    border-radius: 2px;
  }
  .anomaly-sensitivity-dialog .control-label.current {
    ${font({ size: '14px' })}
    background-color: ${CorvanaFaintBlue};
  }
  .anomaly-sensitivity-dialog .control-label.adjusted {
    ${font({ size: '14px' })}
    background-color: ${CorvanaBrightBlue};
    color: ${ContentBackground};
  }
  .anomaly-sensitivity-dialog .anomaly-count-header {
    margin-bottom: 8px;
  }
  .anomaly-sensitivity-dialog .btn {
    min-width: 124px;
  }
  .anomaly-sensitivity-dialog .btn-primary {
    margin-left: 10px;
  }
  #sensitivity-slider {
    height: 164px;
    width: 584px;
    padding-top: 33px;
    padding-left: 16px;
    padding-right: 16px;
  }
  #sensitivity-slider .anomalySlider {
    margin-bottom: 9px;
  }
  #impact-summary {
    margin-left: 32px;
  }
  #impact-summary .bar.current {
    fill: ${CorvanaFaintBlue};
  }
  #impact-summary .bar-label.current {
    fill: ${baseFontColor};
  }
  #impact-summary .impact-summary-container {
    display: flex;
    height: 164px;
    width: 584px;
  }
  #impact-summary .impact-summary-container .chart {
    width: 185px;
    height: 100%;
    margin: auto;
  }
  #impact-summary .impact-summary-container .percent-change {
    width: 200px;
    height: 100%;
    margin: auto;
  }
  .percent-widget {
    color: ${CorvanaLightText};
    text-align: center;
    font-weight: ${LightFontWeight};
    width: 100%;
    margin-top: -22px;
  }
  .percent-widget span:first-of-type {
    ${font({ size: '110px' })}
  }
  .percent-widget span.smaller {
    ${font({ size: '80px' })}
  }
  .percent-widget span:last-of-type {
    ${font({ size: '36px' })}
  }
  .percent-widget.negative {
    color: ${negativeColor};
  }
  .percent-widget.smaller {
    margin-top: 0px;
  }
  .timeframe-selection {
    display: inline-block;
  }
  .timeframe-selection select {
    display: inline-block;
    ${font({ size: '14px' })}
    width: auto;
    height: 30px;
    margin-left: 10px;
  }
  .vertical-center {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  .viz-container,
  .monitor-detail-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-content: stretch;
    align-items: stretch;
    justify-content: flex-start;
    background-color: ${ContentBackground};
    user-select: none;
    height: calc(100% - ${VizTabNavOuterHeight});
  }
  .viz-detail hr,
  .monitor-detail hr {
    margin: 0;
    border-top: 1px solid ${Strokes};
  }
  .viz-layout .search-input input {
    border-radius: ${ModernBorderRadius} ${ModernBorderRadius} 0 0;
    box-shadow: none;
    padding-right: 24px;
    padding-left: 30px;
    border: none;
    width: 100%;
    padding-bottom: 3px;
    
    &:focus {
      box-shadow: none;
    }
  }
  .viz-layout .search-input hr {
    position: absolute;
    bottom: 5px;
    right: 0px;
    left: 0px;
    margin: 0px 8px;
  }
  ${svgColorizePartial({
    fromColor: PanelIconColor,
    toColor: PanelIconActiveColor,
  })('nav li.active .panel-icon')}

  ${svgColorizePartial({
    fromColor: PanelIconColor,
    toColor: PanelIconDisabledColor,
  })(`
  nav li.disabled .panel-icon,
  nav li.disabled .tool-icon
`)}
.nav > li.disabled > a:focus,
.nav > li.disabled > a:hover {
    cursor: default;
  }

  #discover {
    background-color: ${ContentBackground};
  }
  #discover .dataPanel {
    height: 100%;
  }
  #discover .list-table-container {
    /* account for the button toolbar*/
    height: calc(100% - 70px);
  }
  .viz-detail .closable-panel-body {
    height: 100%;
  }
  .viz-detail
    .closable-panel-left
    > .panel-show
    > .panel.panel-default
    .panel-heading::after {
    border: none;
  }
  .viz-config-panel {
    ${vbox()}
    padding: 0px 8px 8px 8px;
    height: 100%;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    ${noSelect()}
  }
  .viz-config-panel .viz-layout {
    ${vbox()}
    flex: 1;
    position: relative;
  }
  .viz-config-panel .viz-layout .layout-container {
    ${hbox()}
    ${positionAbsoluteContainer()}
  flex: 1;
  }
  .viz-config-panel .viz-layout .layout-container label {
    ${font({ size: '14px', weight: RegularFontWeight, color: ContentText })}
    margin: 8px 0px;
  }
  .viz-config-panel .viz-layout .layout-container .fields {
    min-width: 50%;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    padding-right: 8px;
    padding-bottom: 8px;
    position: relative;
  }
  .viz-config-panel .viz-layout .layout-container .fields .field-tools {
    position: absolute;
    right: 8px;
    top: 10px;
    cursor: pointer;
    width: 15px;
    height: 15px;
  }
  .viz-config-panel .viz-layout .layout-container .fields .search-button {
    background: none;
    border: 1px solid ${PanelBackground};
    padding: 0px 4px;
  }

  ${svgColorizePartial({ fromColor: ContentText, toColor: ActiveLinkText })(
    '.viz-config-panel .viz-layout .layout-container .fields .search-button:hover svg',
  )}

  .viz-config-panel .viz-layout .layout-container .fields .search-button:active,
.viz-config-panel .viz-layout .layout-container .fields .search-button.active {
    border: 1px solid ${Strokes};
    border-radius: 1px;
    box-shadow: none;
  }
  .viz-config-panel .viz-layout .layout-container .fields .actions-button {
    border: none;
    background: none;
    box-shadow: none;
    padding: 0px;
    width: 20px;
  }

  ${svgColorizePartial({ fromColor: Strokes, toColor: ActiveLinkText })(
    '.viz-config-panel .viz-layout .layout-container .fields .search-input .clear-icon:hover',
  )}

  .viz-config-panel .viz-layout .layout-container .fields .field-list {
    flex: 1;
    background-color: ${ContentBackground};
    border: 1px solid ${PanelBorder};
    border-top: none;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0 0 ${ModernBorderRadius} ${ModernBorderRadius};
  }
  .fields .field-pill-drag-wrapper {
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .viz-field-text.missing-error,
  .filter-item span.missing-error {
    background: ${ErrorStatusBackground};
    border-radius: 3px;
  }
  .calc-dialog .fields li {
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .viz-config-panel
    .viz-layout
    .layout-container
    .fields
    .field-list
    .no-search-results {
    ${font({ size: '12px', weight: LightFontWeight })}
    font-style: italic;
    text-align: center;
    color: ${CorvanaLightText};
    margin: 5px;
  }
  .viz-config-panel .viz-layout .layout-container .shelves {
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 50%;
    max-width: 50%;
    
    overflow: auto;
  }
  .viz-config-panel .viz-layout .layout-container .shelves > div {
    flex: 1;
    ${vbox()}
  }
  .viz-config-panel .viz-layout .layout-container .shelves .shelf {
    background-color: ${ContentBackground};
    border: 1px solid ${PanelBorder};
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    min-height: 60px;
    border-radius: ${ModernBorderRadius};
  }
  .viz-config-panel
    .viz-layout
    .layout-container
    .shelves
    .shelf
    .drop-fields-here {
    ${font({
      size: '12px',
      weight: LightFontWeight,
      color: darkMode ? PlaceholderText : Gray70,
    })}
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
    min-height: ${pillHeight};
    margin-bottom: 8px;
  }
  .viz-config-panel
    .viz-layout
    .layout-container
    .shelves
    .shelf.drop-valid
    .empty-shelf {
    ${font({ size: '12px', weight: LightFontWeight, color: CorvanaLightText })}
  }
  .viz-config-panel
    .viz-layout
    .layout-container
    .shelves
    .shelf.drop-valid
    .drop-fields-here {
    ${font({
      size: '12px',
      weight: LightFontWeight,
      color: darkMode ? PlaceholderText : Gray70,
    })}
  }
  .viz-config-panel
    .viz-layout
    .layout-container
    .shelves
    .shelf.drop-valid.drop-hover {
    border: 1px solid ${CorvanaBrightBlue};
  }
  .viz-config-panel
    .viz-layout
    .layout-container
    .shelves
    .shelf.drop-valid.drop-hover
    .drop-fields-here {
    ${font({ size: '12px', weight: LightFontWeight, color: CorvanaBrightBlue })}
  }
  .viz-config-panel
    .viz-layout
    .layout-container
    .shelves
    .shelf.drop-invalid.drop-hover {
    border: 1px solid ${negativeColor};
  }
  .viz-config-panel
    .viz-layout
    .layout-container
    .shelves
    .shelf.drop-in-process.drop-invalid {
    opacity: 0.48;
  }
  .viz-config-panel .viz-layout .dataset {
    ${font({ size: '14px', weight: LightFontWeight, color: ContentText })}
    ${ellipsis()}
  position: absolute;
    bottom: 0;
  }
  .viz-config-panel .viz-layout .dataset label {
    ${font({ size: '14px', weight: MediumFontWeight, color: ContentText })}
    margin-right: 8px;
    margin-bottom: 0px;
  }
  .viz-config-panel .viz-layout .dataset img {
    margin-right: 8px;
  }
  .viz-config-panel .viz-formatting {
    ${vbox()}
    flex: 1;
  }
  .viz-config-panel .viz-formatting .layout-container {
    ${positionAbsoluteContainer()}
    padding: 0px 8px 8px 8px;
  }

  .viz-config-panel .viz-formatting .layout-container > *:last-of-type {
    padding-bottom: 8px;
  }

  .viz-config-panel .viz-formatting .viz-formatting-header {
    ${font({ size: '14px', weight: RegularFontWeight })}
    margin-bottom: 8px;
    margin-top: 1em;

    &:first-of-type {
      margin-top: unset;
    }
  }
  .viz-config-panel
    .viz-formatting
    .viz-formatting-header
    .viz-formatting-header-left {
    display: inline-block;
  }
  .viz-config-panel
    .viz-formatting
    .viz-formatting-header
    .viz-formatting-header-right {
    float: right;
  }
  .viz-config-panel .viz-formatting .viz-formatting-form-group {
    background-color: ${ContentBackground};
    border: 1px solid ${Strokes};
    border-radius: ${ModernBorderRadius};
    padding: 12px;
    margin-bottom: 1em;

    .form-group {
      margin-bottom: 0;
    }
  }
  .viz-config-panel
    .viz-formatting
    .viz-formatting-form-group
    .checkbox.disabled
    span {
    opacity: 0.48;
  }
  .viz-config-panel .viz-formatting .viz-formatting-form-group .checkbox {
    margin-top: 0px;
    margin-bottom: 8px;
  }
  .viz-config-panel
    .viz-formatting
    .viz-formatting-form-group
    .checkbox:last-of-type {
    margin-bottom: 0px;
  }
  .viz-config-panel
    .viz-formatting
    .viz-formatting-form-group
    .checkbox.enableReportLink {
    margin-bottom: 0px;
    margin-right: 8px;
    height: 40px;
    display: flex;
    align-items: center;
  }
  .viz-config-panel .viz-formatting .viz-formatting-form-group .checkbox input {
    margin: 0px;
    margin-right: 8px;
    position: initial;
  }
  .viz-config-panel .viz-formatting .viz-formatting-form-group label {
    ${font({ size: '12px', weight: LightFontWeight })}
    height: 32px;
    display: flex;
    align-items: center;
    padding: 0px;
  }
  .viz-config-panel
    .viz-formatting
    .viz-formatting-form-group
    label.toggle-group {
    ${font({ size: '12px', weight: MediumFontWeight })}
  }
  .viz-config-panel .viz-chart-type label {
    ${font({ size: '14px', weight: RegularFontWeight, color: ContentText })}
    margin-bottom: 8px;
  }
  .viz-config-panel .viz-chart-type .dropdown,
  .viz-config-panel .viz-chart-type .dropdown-toggle {
    ${hbox()}
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .viz-config-panel .viz-chart-type .dropdown-toggle {
    padding: 6px 0px 6px 8px;
    box-shadow: none;
    background-color: ${ContentBackground};
  }
  .viz-config-panel .viz-chart-type .dropdown-menu {
    width: 100%;
  }
  .viz-config-panel .viz-chart-type .dropdown-menu li a {
    ${hbox()}
    align-items: flex-end;
    width: 100%;
  }
  .viz-config-panel .viz-chart-type .icon {
    width: 24px;
    height: 24px;
    margin-right: 16px;
  }
  .viz-config-panel .viz-chart-type .icon svg {
    width: 24px;
    height: 24px;
  }
  .viz-config-panel .viz-chart-type .dropdown-menu > .chart-type {
    ${hbox()}
  }

  ${svgColorizePartial({
    fromColor: PanelIconColor,
    toColor: ToolIconHoverColor,
  })(`
  .viz-config-panel .viz-chart-type .dropdown-menu > .chart-type:hover,
  .viz-config-panel .viz-chart-type .dropdown-menu > .chart-type.active:hover 
`)}
  ${svgColorizePartial({
    fromColor: PanelIconSecondaryColor,
    toColor: ToolIconSecondaryHoverColor,
  })(`
  .viz-config-panel .viz-chart-type .dropdown-menu > .chart-type:hover,
  .viz-config-panel .viz-chart-type .dropdown-menu > .chart-type.active:hover 
`)}

.viz-config-panel .viz-chart-type .dropdown-menu > .chart-type:hover a,
.viz-config-panel .viz-chart-type .dropdown-menu > .chart-type.active:hover a {
    color: ${DropdownTextHover};
  }

  ${svgColorizePartial({
    fromColor: PanelIconColor,
    toColor: DefaultButtonTextColorActive,
  })('.viz-config-panel .viz-chart-type .dropdown-menu > .chart-type.active')}
  ${svgColorizePartial({
    fromColor: PanelIconSecondaryColor,
    toColor: DefaultButtonTextSecondaryColorActive,
  })('.viz-config-panel .viz-chart-type .dropdown-menu > .chart-type.active')}

.viz-config-panel .viz-chart-type .dropdown-menu > .chart-type.active a {
    background-color: ${DropdownBackgroundHover};
    color: ${DefaultButtonTextColorActive};
  }
  .viz-config-panel .viz-chart-type .viz-chart-selected {
    ${hbox()}
    align-items: flex-end;
  }
  .viz-field-pill.drag-preview {
    ${font({ size: '12px', weight: LightFontWeight, color: ContentText })}
    min-width: 100px;
    border: 1px solid ${FieldPillBackgroundDrag};
    border-radius: 4px;
    background-color: ${FieldPillBackgroundDrag};
    opacity: 0.9;
  }
  .viz-field-pill.drag-preview .viz-field-dropdown {
    overflow: hidden;
  }
  .viz-field-pill.drag-preview .viz-field-text {
    max-width: 100%;
  }
  .viz-field-pill.drag-preview .viz-field-label {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .viz-field-pill.dragging:hover .viz-field-label,
  .viz-field-pill.dragging .viz-field-label {
    background-color: transparent;
  }
  .viz-field-pill.dragging:hover .viz-field-text,
  .viz-field-pill.dragging .viz-field-text {
    border: 1px dashed ${PanelBorder};
    border-radius: 4px;
    background-color: transparent;
  }
  .viz-field-pill.dragging:hover .dropdown,
  .viz-field-pill.dragging .dropdown {
    background-color: transparent;
  }
  .viz-field-pill.dragging:hover .dropdown .caret,
  .viz-field-pill.dragging .dropdown .caret {
    display: none;
  }
  .viz-field-pill.dragging:hover > div,
  .viz-field-pill.dragging > div,
  .viz-field-pill.dragging:hover svg,
  .viz-field-pill.dragging svg {
    background-color: transparent;
    opacity: 0.3;
  }
  .shelf .viz-field-pill {
    /* the individual drop locations will give it top & bottom spacing*/
    margin: 0px 5px;
  }
  .viz-field-pill {
    height: ${pillHeight};
    margin: 0 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }
  .viz-field-pill .viz-field-icon {
    min-width: 24px;
    min-height: 24px;
  }
  .viz-field-pill .viz-field-icon.small {
    min-width: 12px;
    min-height: 12px;
  }
  .viz-field-pill.invalid-field {
    background: pink;
  }
  .viz-field-pill .viz-field-text {
    flex: 1;
    max-width: calc(100% - 32px);
  }
  .viz-field-pill .viz-field-text .dropdown {
    display: flex;
    flex: 1;
    border: 1px solid transparent;
    border-radius: 4px;
  }
  .viz-field-pill .viz-field-text .btn-group.open .dropdown-toggle,
  .viz-field-pill .viz-field-text button:active,
  .viz-field-pill .viz-field-text button:hover,
  .viz-field-pill .viz-field-text button:focus,
  .viz-field-pill .viz-field-text button {
    border: none;
    background-color: transparent;
    padding: 0px;
    box-shadow: none;
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    max-width: 100%;
    height: 17px;
  }
  .viz-field-pill .viz-field-text .viz-field-label {
    ${font({ size: '12px', weight: LightFontWeight, color: ContentText })}
    ${ellipsis()}
  flex: 1;
    margin-right: 2px;
    text-align: left;
  }
  .viz-field-pill .viz-field-text .caret {
    display: none;
    font-size: 12px;
  }
  .viz-field-pill .dropdown:hover {
    border: 1px solid ${FieldPillBackgroundHoverBorder};
    border-radius: 4px;
    background-color: ${FieldPillBackgroundHover};
  }
  .viz-field-pill .dropdown:hover .btn-default:hover:not(:disabled) {
    background-color: ${FieldPillBackgroundHover};
  }
  .viz-field-pill .dropdown.open {
    border: 1px solid ${FieldPillBackgroundActiveBorder};
    border-radius: 4px;
    background-color: ${FieldPillBackgroundActive};
  }
  .viz-field-pill .dropdown.open .viz-field-label {
    color: ${ContentBackground};
  }
  .viz-field-pill .dropdown:hover .caret,
  .viz-field-pill .dropdown.open .caret {
    margin-right: 4px;
    display: flex;
    width: 1em;
    height: 1em;
  }
  .viz-field-pill .dropdown.open .caret {
    visibility: visible;
    color: ${ContentBackground};
  }
  .portal-dropdown .field-pill-dropdown__dropdown-menu li > a {
    padding: 4px 8px 4px 14px;
  }
  .portal-dropdown .field-pill-dropdown__dropdown-menu.shifted li > a {
    padding-left: 24px;
  }
  .shelf .viz-field-pill .viz-field-text {
    width: calc(100% - 32px);
  }
  .viz-select-dataset {
    width: 100%;
  }
  .bar-chart-advanced-config {
    ${font({ size: '12px', weight: LightFontWeight, color: ContentText })}
    padding: 5px 20px;
  }
  .bar-chart-advanced-config .form-inline .form-group {
    margin-bottom: 0px;
  }
  .bar-chart-advanced-config button,
  .bar-chart-advanced-config input,
  .bar-chart-advanced-config label {
    ${font({ size: '12px', weight: LightFontWeight, color: ContentText })}
  }
  .bar-chart-advanced-config input[type='text'] {
    width: 70px;
  }
  .bar-chart-advanced-config .update-button,
  .bar-chart-advanced-config .row-count {
    flex: 1;
  }
  .shelf-drop-location {
    height: 4px;
    margin: 1px 5px;
  }
  .shelf-drop-location.drop-valid {
    background-color: ${CorvanaBrightBlue};
  }
  .chart-tooltip-label {
    display: inline-block;
    ${font({ size: '12px', weight: LightFontWeight, color: TooltipTextColor })}
    fill: ${TooltipTextColor};
    margin-right: 20px;
    text-overflow: ellipsis;
    max-width: 175px;
    overflow: hidden;
  }
  .chart-tooltip-value {
    display: inline-block;
    ${font({
      size: '12px',
      weight: RegularFontWeight,
      color: TooltipTextColor,
    })}
    fill: ${TooltipTextColor};
  }
  .chart-tooltip-extra {
    display: inline-block;
    ${font({ size: '10px', weight: LightFontWeight, color: TooltipTextColor })}
    fill: ${TooltipTextColor};
    font-style: italic;
    white-space: nowrap;
  }
  .legend-items {
    overflow-y: auto;
    padding: 3px 0px;
  }
  .legend-items .title-legend-item {
    ${font({ size: '14px', weight: MediumFontWeight })}
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .legend-items .title-legend-item:not(:first-of-type) {
    margin-top: 16px;
  }
  .legend-items .legend-item {
    display: flex;
    padding: 3px 0px;
    cursor: default;
  }
  .legend-items .legend-item .legend-label {
    flex: 1;
    padding-left: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .legend-items .pivot-legend-item.legend-item .legend-label {
    ${font({ size: '12px', weight: RegularFontWeight })}
    overflow-x: hidden;
    text-overflow: ellipsis;
    padding-left: 0px;
  }
  .legend-items .pivot-legend-item.legend-item .legend-color-scale .swatch {
    margin: 4px 0;
    height: 16px;
  }
  #field-tooltip-advanced {
    pointer-events: none;
  }
  #field-tooltip,
  #field-tooltip-advanced,
  #funnel-summary-tooltip,
  #legend-tooltip {
    font-family: inherit;
    ${font({ size: '12px', weight: LightFontWeight, color: ContentBackground })}
  }
  #field-tooltip .tooltip-inner,
  #field-tooltip-advanced .tooltip-inner,
  #funnel-summary-tooltip .tooltip-inner,
  #legend-tooltip .tooltip-inner {
    overflow-wrap: break-word;
    text-align: left;
  }
  #funnel-summary-tooltip .tooltip-inner {
    max-width: unset;
  }
  #verbose-filter-tooltip .tooltip-inner {
    max-width: 640px;
    overflow: hidden;
  }
  #verbose-filter-tooltip .verbose-filter-tooltip-text {
    width: auto;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    overflow-wrap: break-word;
    ${font({ weight: RegularFontWeight })}
  }
  #verbose-filter-tooltip .edit-link {
    overflow: hidden;
    text-align: left;
    ${font({ weight: LightFontWeight, style: 'italic' })}
  }
  .left-panel .dropdown-menu {
    background-color: ${ContentBackground};
    border: 1px solid ${Strokes};
  }
  .left-panel .dropdown-menu > li.selected > a {
    background-color: ${DropdownBackgroundHover};
    color: ${DropdownTextHover};
  }
  .left-panel .dropdown-menu > li > a {
    ${font({
      size: '12px',
      weight: LightFontWeight,
      color: DropdownTextPrimary,
    })}
    padding: 4px 8px;
  }
  .left-panel .dropdown-menu > li > a:hover {
    background-color: ${DropdownBackgroundHover};
    color: ${DropdownTextHover};
  }

  .filter-dialog .filter-dialog-content {
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    background-color: ${PanelBackground};
  }
  .filter-dialog .filter-content {
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
  }
  .filter-dialog .filter-content .filter-panel.filter-condition {
    padding: 1.1em;

    .logical-operator {
      display: inline-block;
    }
  }
  .filter-dialog .filter-content .filter-panel.filter-condition .dropdown-menu {
    max-height: 30vh;
    overflow-y: scroll;
  }
  .filter-dialog .filter-content .section-label {
    ${font({ size: '24px', weight: LightFontWeight, color: ContentText })}
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    align-items: center;
  }
  .filter-dialog .filter-content .filter-panel {
    ${font({ size: '14px', weight: LightFontWeight, color: ContentText })}
    background-color: ${ContentBackground};
    border: 1px solid ${Strokes};
    padding: 12px;
    margin-bottom: 16px;
    position: relative;
  }
  .filter-dialog .filter-content .filter-type {
    height: 84px;
  }
  .filter-dialog .filter-content .filter-preview {
    flex: 1;
    overflow-y: auto;
  }
  .filter-dialog .dropdown.btn-group,
  .filter-dialog .form-control {
    margin-left: 12px;
  }
  .filter-dialog .dropdownStyle {
    ${font({ size: '12px', weight: LightFontWeight, color: InputTextColor })}
    min-width: ${dropdownWidth};
    height: 40px;
  }
  .filter-dialog .dropdown-menu {
    min-width: ${dropdownWidth};
  }
  .filter-dialog .buttonGroup {
    margin-top: 8px;
  }
  .filter-dialog .buttonGroup button.btn {
    ${font({
      size: '16px',
      weight: RegularFontWeight,
      color: DefaultButtonTextColor,
    })}
    height: 32px;
    width: 126px;
    padding: 0px;
  }
  .filter-dialog .buttonGroup button.btn-primary {
    ${font({
      size: '16px',
      color: PrimaryButtonTextColor,
      weight: RegularFontWeight,
    })}
  }
  .filter-dialog .buttonGroup button:not(:last-of-type) {
    margin-right: 12px;
  }
  .filter-dialog .buttonGroup button.btn-primary.disabled,
  .filter-dialog .buttonGroup button.btn-primary[disabled] {
    background-color: ${DisabledPrimaryButtonColor};
    border-color: ${DisabledPrimaryButtonColor};
    opacity: 1;
  }
  .condition-inputs {
    display: flex;
    flex-direction: column;
  }
  .condition-inputs .form-group {
    display: inline-flex;
    margin: 5px 0;
  }
  .condition-inputs input[type='text'] {
    ${font({ size: '14px', weight: LightFontWeight, color: InputTextColor })}
    min-width: ${dropdownWidth};
    margin-left: 12px;
    padding-right: 8px;
  }
  .condition-inputs .radio-inline {
    display: flex;
    align-items: center;
    ${font({ size: '14px', weight: LightFontWeight, color: InputTextColor })}
    margin-left: 0px;
    min-width: 40px;
  }
  .condition-inputs .radio-inline input[type='radio'] {
    position: static;
    margin: 0 12px 0 0;
  }
  .condition-inputs .condition {
    position: relative;
  }
  .condition-inputs .condition svg {
    height: 18px;
    width: 18px;
    position: absolute;
    top: 16px;
    right: -32px;
  }
  .condition-inputs .condition svg:hover {
    cursor: pointer;
  }

  ${svgColorizePartial({
    fromColor: PanelIconColor,
    toColor: ToolIconHoverColor,
  })('.condition-inputs .condition svg:hover')}

  ${svgColorizePartial({
    fromColor: ToolbarIconFill,
    toColor: ToolIconHoverColor,
  })('.condition-inputs .condition svg:hover')}

.filter-nav-item {
    display: flex;
    flex-direction: column;
  }
  .filter-nav-item:first-of-type > .filter-nav-content {
    margin-left: 4px;
  }
  .filter-nav-item .filter-nav-icon {
    display: flex;
    justify-content: center;
    margin-bottom: 6px;
  }
  .filter-nav-item .filter-nav-content {
    margin: 0 8px;
  }
  /*  Make the content area flex vertically*/
  .filter-content.grow {
    flex: 1;
  }
  .filter-content.grow .tab-content,
  .filter-content.grow .tab-pane.active {
    ${vbox()}
    ${flexFill()}
  }
  .string-filter .item-list,
  .timestamp-filter .item-list {
    ${flexFill()}
    overflow-y: auto;
  }
  .string-filter .info,
  .timestamp-filter .info {
    ${font({ size: '16px', weight: LightFontWeight })}
  }
  .string-filter label,
  .timestamp-filter label {
    ${font({ weight: LightFontWeight })}
  }
  .tooltip-content {
    display: inline-block;
    margin: 8px 8px 8px 10px;
    line-height: 18px;
  }
  .tooltip-content hr {
    margin: 4px 0px;
  }
  .tooltip-entry {
    width: 100%;
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    max-width: 30em;
    word-wrap: break-word;
  }
  .funnel .tooltip-entry {
    max-width: 20em;
    white-space: break-spaces;
  }
  .tooltip-entry svg {
    margin: 1px 5px;
  }
  .date-filter-tooltip .date-range {
    ${font({ weight: RegularFontWeight })}
  }
  .date-filter-tooltip .edit-link {
    ${font({ weight: LightFontWeight, style: 'oblique' })}
  }
  .query-error {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    flex: 1;
  }
  .query-error h3 {
    color: ${negativeColor};
  }
  .query-error .error-message {
    ${font({ size: '16px', weight: LightFontWeight })}
  }
  .query-error .variables {
    margin-top: 30px;
    flex: 1;
  }
  .query-error pre {
    width: 100%;
    user-select: text;
  }
  .no-search-results {
    ${font({ size: '14px', weight: LightFontWeight, color: CorvanaLightText })}
    font-style: italic;
  }
  #filter-condition-button-tooltip {
    ${font({ size: '12px', weight: LightFontWeight, color: ContentBackground })}
  }
  #filter-condition-button-tooltip > .tooltip-inner {
    padding: 6px 8px;
  }
  .active-filters {
    ${font({ size: '14px', weight: LightFontWeight })}
    display: flex;
    flex: 1 1 auto;
    min-height: 20px;
    padding-left: 20px;
    cursor: default;
  }
  .active-filters .display-control {
    display: flex;
  }
  .active-filters .display-control.condensed {
    height: 20px;
    overflow: hidden;
  }
  .active-filters .display-control.expanded {
    flex-flow: row wrap;
  }

  .active-filters .filter-item {
    display: flex;
    align-items: center;
    padding-right: 5px;
    cursor: pointer;
  }
  .active-filters .filter-item span {
    ${font({ weight: RegularFontWeight })}
    margin-right: 3px;
  }
  .active-filters .filter-item span:hover {
    color: ${ActiveLinkText};
  }
  .active-filters .more-filters {
    width: 94px;
  }
  .active-filters .more-filters:hover span:last-of-type {
    color: ${ActiveLinkText};
  }
  .relative-date-condition .relative-date-value {
    width: 100px;
  }
  .line-label rect {
    fill: transparent;
  }
  .line-label.focused rect {
    /* background for the label*/
    fill: ${Gray20};
  }
  .line-label text {
    ${font({ size: '10px', weight: LightFontWeight })}
    fill: ${ChartLabelTextColor};
    visibility: hidden;
  }
  .line-label.focused text {
    visibility: visible;
  }
  .viz-settings-panel .viz-settings-header {
    ${font({ size: '14px', weight: RegularFontWeight })}
  }
  .viz-settings-panel .viz-settings-form-group {
    background-color: ${ContentBackground};
    border: 1px solid ${Strokes};
    border-radius: 4px;
    padding: 8px;
  }
  .viz-settings-panel .viz-settings-form-group label {
    ${font({ size: '12px', weight: LightFontWeight })}
  }
  .viz-settings-panel .viz-settings-form-group input {
    margin-top: 2px;
  }
  .viz-settings-panel .dropdown.btn-group {
    display: flex;
    flex-direction: row;
  }
  .viz-settings-panel .dropdown.btn-group .dropdown-toggle.btn {
    text-align: left;
    flex: 1;
  }
  .viz-settings-panel .dropdown.btn-group .dropdown-toggle.btn .caret {
    position: absolute;
    right: 8px;
    top: 12px;
  }
  .viz-settings-panel .target-settings .dropdownStyle {
    min-width: 200px;
  }
  .viz-settings-panel .target-settings .dropdown-menu {
    width: 100%;
  }
  .viz-settings-panel .target-settings .dropdown-toggle {
    padding: 6px 8px;
    box-shadow: none;
    background-color: ${ContentBackground};
  }
  .viz-settings-panel .target-group {
    ${font({ size: '12px', weight: LightFontWeight })}
    margin-top: 8px;
  }
  .viz-settings-panel .target-group label {
    ${font({ size: '12px', weight: RegularFontWeight })}
  }
  .viz-settings-panel .target-group .target-group-options {
    padding-left: 10px;
  }
  .viz-settings-panel .target-group .target-group-options .checkbox-label {
    ${font({ size: '12px', weight: LightFontWeight })}
    line-height: 20px;
  }
  .viz-config-panel .dataset {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .viz-config-panel .dataset span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .viz-config-panel .dataset .edit-icon {
    margin-left: 8px;
    align-items: center;
    display: flex;
  }
  ${svgColorizePartial({
    fromColor: CorvanaLightText,
    toColor: PanelIconColor,
  })('.viz-config-panel .dataset .edit-icon')}

  .viz-config-panel .dataset .edit-icon:hover > svg {
    cursor: pointer;
  }
  ${svgColorizePartial({
    fromColor: CorvanaLightText,
    toColor: PanelIconHoverColor,
  })('.viz-config-panel .dataset .edit-icon:hover > svg')}

  ${svgColorizePartial({
    fromColor: CorvanaLightText,
    toColor: PanelIconActiveColor,
  })('.viz-config-panel .dataset .edit-icon:active > svg')}

.discovery-tab-text {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .viz-chart-error {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .viz-chart-error-message {
    ${font({ size: '24px', weight: LightFontWeight })}
    text-align: center;
    width: 600px;
    color: ${ContentText} !important;
  }

  ${svgColorizePartial({
    fromColor: PanelIconColor,
    toColor: DefaultButtonTextColor,
  })('.discover-tab-panel > .add-discovery svg')}

  .mixed-area {
    mix-blend-mode: color;
  }
  .mixed-area-line path {
    stroke: ${anomalyColor};
    fill: none;
    stroke-width: 2;
  }
  .filter-menu-search {
    margin-right: 20px;
    margin-left: 10px;
  }
  .filter-menu-checkbox {
    margin: 0;
  }
  .filter-menu-checkbox label {
    font-size: 16px;
  }
  .filter-menu-checkbox input {
    margin-top: 12px;
  }
  .viz-chart-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
  }
  #chartArea {
    fill: none;
    font-family: ${DefaultFontName};
  }
  svg.viz-chart {
    font-family: ${DefaultFontName};
  }
  .discovery-dirty-indicator {
    position: absolute;
    left: 8px;
  }
  .no-wrap-or-trunate {
    white-space: nowrap;
    text-overflow: initial;
  }
`;
