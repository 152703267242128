import { DefaultFontName } from '../../../components/ui/fonts';

import * as SugarColorPalette from '@sugarcrm/maple-syrup/build/color/js/sugarColorPalette';

// All colors defined here are provided as the Discover Theme

// Sugar Palettes
export const SugarPrimaryPalette = {
  LightBlue: '#D6EEFF',
  MediumBlue: SugarColorPalette.ColorBaseBlue600,
  DarkBlue: '#005D9C',
};
export const SugarSecondaryPalette = {
  LightOcean: SugarColorPalette.ColorBaseIndigo200,
  LightPacific: SugarColorPalette.ColorBaseCyan200,
  LightTeal: SugarColorPalette.ColorBaseTeal200,
  LightGreen: SugarColorPalette.ColorBaseEmerald200,
  LightArmy: SugarColorPalette.ColorBaseLime200,
  LightYellow: SugarColorPalette.ColorBaseYellow200,
  LightOrange: SugarColorPalette.ColorBaseOrange200,
  LightRed: SugarColorPalette.ColorBaseRed200,
  LightCoral: SugarColorPalette.ColorBaseFuschia200,
  LightPink: SugarColorPalette.ColorBasePink200,
  LightPurple: SugarColorPalette.ColorBaseViolet200,
  LightIndigo: SugarColorPalette.ColorBaseIndigo200,
  MediumOcean: SugarColorPalette.ColorBaseIndigo500,
  MediumPacific: SugarColorPalette.ColorBaseCyan500,
  MediumTeal: SugarColorPalette.ColorBaseTeal500,
  MediumGreen: SugarColorPalette.ColorBaseEmerald500,
  MediumArmy: SugarColorPalette.ColorBaseLime500,
  MediumYellow: SugarColorPalette.ColorBaseYellow400,
  MediumOrange: SugarColorPalette.ColorBaseOrange500,
  MediumRed: SugarColorPalette.ColorBaseRed500,
  MediumCoral: SugarColorPalette.ColorBaseFuschia500,
  MediumPink: SugarColorPalette.ColorBasePink500,
  MediumPurple: SugarColorPalette.ColorBaseViolet500,
  MediumIndigo: SugarColorPalette.ColorBaseIndigo400,
  DarkOcean: SugarColorPalette.ColorBaseIndigo800,
  DarkPacific: SugarColorPalette.ColorBaseCyan800,
  DarkTeal: SugarColorPalette.ColorBaseTeal800,
  DarkGreen: SugarColorPalette.ColorBaseEmerald800,
  DarkArmy: SugarColorPalette.ColorBaseLime800,
  DarkYellow: SugarColorPalette.ColorBaseYellow700,
  DarkOrange: SugarColorPalette.ColorBaseOrange800,
  DarkRed: SugarColorPalette.ColorBaseRed800,
  DarkCoral: SugarColorPalette.ColorBaseFuschia800,
  DarkPink: SugarColorPalette.ColorBasePink800,
  DarkPurple: SugarColorPalette.ColorBaseViolet800,
  DarkIndigo: SugarColorPalette.ColorBaseIndigo600,
};
export const SugarGrayPalette = {
  Black: SugarColorPalette.ColorBaseBlack,
  Gray95: SugarColorPalette.ColorBaseGray950,
  Gray90: SugarColorPalette.ColorBaseGray900,
  Gray80: SugarColorPalette.ColorBaseGray800,
  Gray70: SugarColorPalette.ColorBaseGray700,
  Gray60: SugarColorPalette.ColorBaseGray600,
  Gray50: SugarColorPalette.ColorBaseGray500,
  Gray40: SugarColorPalette.ColorBaseGray400,
  Gray30: SugarColorPalette.ColorBaseGray300,
  Gray20: SugarColorPalette.ColorBaseGray200,
  Gray10: SugarColorPalette.ColorBaseGray100,
  White: SugarColorPalette.ColorBaseWhite,
};

/* Exporting flattened constants for Discover Theme */
export const {
  Gray90,
  Gray80,
  Gray70,
  Gray60,
  Gray50,
  Gray40,
  Gray30,
  Gray20,
  Gray10,
  White,
} = SugarGrayPalette;
export const { LightBlue, MediumBlue, DarkBlue } = SugarPrimaryPalette;
export const {
  MediumGreen,
  MediumOcean,
  MediumRed,
  MediumYellow,
  LightRed,
  DarkRed,
} = SugarSecondaryPalette;

export const SuccessColor = SugarColorPalette.ColorBaseGreen500;
export const DangerColor = SugarSecondaryPalette.MediumRed;
export const WarningColor = SugarSecondaryPalette.MediumYellow;
export const InfoColor = SugarSecondaryPalette.MediumPacific;
export const PrimaryColor = SugarPrimaryPalette.MediumBlue;
export const GrayIconColor = SugarColorPalette.ColorBaseGray500;
export const DisabledIconColor = SugarColorPalette.ColorBaseGray300;

export const AccentColor = SugarColorPalette.ColorBaseBlue800;
export const DimText = SugarColorPalette.ColorBaseGray200;

/* Main Menu */
export const ContentText = SugarColorPalette.ColorBaseGray800;
export const ContentTextFocus = SugarColorPalette.ColorBaseBlue950;
export const ContentBackground = SugarColorPalette.ColorBaseWhite;
export const ActiveLinkText = PrimaryColor;
export const Strokes = SugarColorPalette.ColorBaseGray300;
export const ContentBlack = SugarColorPalette.ColorBaseBlack;

/* Strokes, Xs, and Dividers*/
export const TabHighlight = PrimaryColor;
export const TabBackground = SugarColorPalette.ColorBaseWhite;
export const TabHighlightHover = SugarColorPalette.ColorBaseBlue100;
export const TabHighlightDisabled = SugarColorPalette.ColorBaseGray400;
export const PanelBackground = SugarColorPalette.ColorBaseGray100;
export const MediumBorder = SugarColorPalette.ColorBaseGray300;
export const PanelBorder = SugarColorPalette.ColorBaseGray300;
export const ErrorColor = SugarColorPalette.ColorBaseRed500;
export const SubtotalBackground = SugarColorPalette.ColorBaseGray200;
export const FieldPillBackgroundHover = SugarColorPalette.ColorBaseBlue100;
export const FieldPillBackgroundHoverBorder =
  SugarColorPalette.ColorBaseBlue100;
export const FieldPillBackgroundDrag = SugarColorPalette.ColorBaseBlue100;
export const FieldPillBackgroundActive = SugarColorPalette.ColorBaseBlue900;
export const FieldPillBackgroundActiveBorder =
  SugarColorPalette.ColorBaseBlue900;

/* Icons */
export const ToolbarIconFill = SugarColorPalette.ColorBaseGray400;

/* Toolbar Icons and Close*/
export const ToolIconColor = SugarColorPalette.ColorBaseGray400;
export const ToolIconColorSecondary = SugarColorPalette.ColorBaseGray500;
export const ToolIconHoverColor = ActiveLinkText;
export const ToolIconSecondaryHoverColor = SugarColorPalette.ColorBaseGray500;
export const PanelIconColor = SugarColorPalette.ColorBaseGray400;
export const PanelIconHoverColor = ActiveLinkText;
export const PanelIconActiveColor = SugarColorPalette.ColorBaseGray500;
export const PanelIconDisabledColor = SugarColorPalette.ColorBaseGray300;
export const IconGreenColor = SugarColorPalette.ColorBaseGreen500;
export const IconBlueColor = SugarColorPalette.ColorBaseBlue700;

export const IconActiveLightColor = SugarColorPalette.ColorBaseGray500;
export const IconActiveDarkColor = SugarColorPalette.ColorBaseBlue900;
export const PanelIconSecondaryColor = SugarColorPalette.ColorBaseGray400;
export const PanelNavIconColor = SugarColorPalette.ColorBaseGray500;
export const PanelNavBackground = SugarColorPalette.ColorBaseGray100;
export const DefaultButtonTextColor = PrimaryColor;
export const DefaultButtonBorderColor = SugarColorPalette.ColorBaseGray300;
export const DefaultButtonTextColorHover = PrimaryColor;
export const DefaultButtonBorderColorHover = SugarColorPalette.ColorBaseGray300;
export const DefaultButtonTextColorActive = DefaultButtonTextColorHover;
export const DefaultButtonBorderColorActive = DefaultButtonBorderColorHover;
export const DefaultButtonTextSecondaryColorActive =
  SugarColorPalette.ColorBaseGray300;
export const DefaultButtonFillColor = SugarColorPalette.ColorBaseGray50;
export const DefaultButtonFillColorHover = SugarColorPalette.ColorBaseGray100;
export const ChartLabelTextColor = SugarColorPalette.ColorBaseGray600;
export const IconTextHoverColor = PrimaryColor;
export const IconPrimaryHoverColor = SugarColorPalette.ColorBaseGray500;
export const IconSecondaryColorHover = SugarColorPalette.ColorBaseCobalt300;
export const ButtonColorPrimary = PrimaryColor;
export const DisabledPrimaryButtonColor = SugarColorPalette.ColorBaseBlue500;
export const ButtonColorPrimaryHover = AccentColor;
export const ButtonBorderColorPrimaryHover = ButtonColorPrimaryHover;
export const RequiredTextColor = SugarColorPalette.ColorBaseRed500;
export const ErrorStatusTextColor = SugarColorPalette.ColorBaseRed500;
export const ErrorStatusBackground = SugarColorPalette.ColorBaseRed100;
export const ValidStatusTextColor = SugarColorPalette.ColorBaseGreen500;
export const PhoneNumberTextColor = SugarColorPalette.ColorBaseBlue600;
export const TableHeaderBackground = PanelBackground;
export const TableHeaderBackgroundDark = SugarColorPalette.ColorBaseGray300;
export const TableHeaderModalBackground = SugarColorPalette.ColorBaseGray50;
export const TableBodyBackground = SugarColorPalette.ColorBaseWhite;
export const TableRowHover = SugarColorPalette.ColorBaseGray100;
export const TableBorderColor = SugarColorPalette.ColorBaseGray300;
export const PivotHeaderBackground = SugarColorPalette.ColorBaseWhite;
export const PivotCell = SugarColorPalette.ColorBaseWhite;
export const PivotSortHover = SugarColorPalette.ColorBaseGray100;
export const PivotCellHighlight = SugarColorPalette.ColorBaseBlue100;
export const PivotCellHighlightHover = SugarColorPalette.ColorBaseBlue100;
export const PivotCellHighlightSecondary = SugarColorPalette.ColorBaseOrange100;
export const PivotCellHighlightSecondaryHover =
  SugarColorPalette.ColorBaseOrange100;
export const PivotFlatHeaderBackground = TableHeaderBackgroundDark;
export const PivotFlatCell = SugarColorPalette.ColorBaseWhite;
export const PivotTotals = TableHeaderBackgroundDark;
export const TooltipColor = SugarColorPalette.ColorBaseGray700;
export const SugarToastBackground = ContentBackground;

/* Font */
export const BaseFont = DefaultFontName;
export const baseFontColor = SugarColorPalette.ColorBaseGray800;
export const StrongFont = DefaultFontName;

/* LEGACY - TODO: Convert to new branding... */
export const CorvanaFaintBlue = SugarColorPalette.ColorBaseBlue200;
export const CorvanaBrightBlue = PrimaryColor;
export const hoverHighlight = SugarColorPalette.ColorBaseGray100;
export const positiveColor = SugarColorPalette.ColorBaseGreen500;
export const warningColor = SugarColorPalette.ColorBaseYellow400;
export const negativeColor = SugarColorPalette.ColorBaseRed500;
export const negativeBackgroundColor = SugarColorPalette.ColorBaseRed100;
export const CorvanaMediumBorder = SugarColorPalette.ColorBaseGray300;
export const CorvanaLightText = SugarColorPalette.ColorBaseGray400;
export const CorvanaBackgroundLight = PanelBackground;
export const CorvanaBackgroundMedium = SugarColorPalette.ColorBaseGray100;
export const CorvanaBackgroundDark = SugarColorPalette.ColorBaseGray600;
export const CorvanaMediumText = SugarColorPalette.ColorBaseGray500;
export const errorBackground = SugarColorPalette.ColorBaseRed100;
export const CorvanaBrandOrangeHighlight = SugarColorPalette.ColorBaseOrange400;
export const LoginButtonColor = PrimaryColor;
export const LoginHoverColor = AccentColor;
export const LoginDisabledColor = DisabledPrimaryButtonColor;

/* Font Weights */
export const LightFontWeight = 300;
export const RegularFontWeight = 400;
export const MediumFontWeight = 500;
export const SemiboldFontWeight = 600;
export const StrongFontWeight = 700;
export const CorvanaButtonIconColor = SugarColorPalette.ColorBaseGray500;
export const anomalyColor = negativeColor;
export const DropdownTextPrimary = SugarColorPalette.ColorBaseGray800;
export const DropdownBackgroundHover = SugarColorPalette.ColorBaseBlue100;
export const DropdownTextHover = PrimaryColor;
export const InputTextColor = SugarColorPalette.ColorBaseGray800;
export const DropdownButtonBorderColor = SugarColorPalette.ColorBaseGray300;

export const StandardButtonColor = SugarColorPalette.ColorBaseBlue600;
export const BorderColor = SugarColorPalette.ColorBaseGray300;
export const TableInputDisabledBackgroundColor =
  SugarColorPalette.ColorBaseGray100;
export const TableHeaderColor = SugarColorPalette.ColorBaseGray100;
export const TextInputBorderColor = SugarColorPalette.ColorBaseGray300;
export const TableHeaderBackgroundColor = SugarColorPalette.ColorBaseGray100;
export const TableHeaderTextColor = SugarColorPalette.ColorBaseGray500;
export const TableRowSecondaryTextColor = SugarColorPalette.ColorBaseGray500;
export const TableRowHoverColor = SugarColorPalette.ColorBaseGray100;
export const TableTextErrorColor = SugarColorPalette.ColorBaseRed500;
export const SVGIconColor = SugarColorPalette.ColorBaseGray500;
export const SVGIconHoverColor = SugarColorPalette.ColorBaseGray500;
export const TabHighlightColor = SugarColorPalette.ColorBaseGray800;
export const TabDefaultColor = SugarColorPalette.ColorBaseGray500;
export const TabActiveColor = SugarColorPalette.ColorBaseGray950;
export const TabDisabledColor = SugarColorPalette.ColorBaseGray300;
export const MenuHoverBackgroundColor = SugarColorPalette.ColorBaseGray100;
export const VizBarColorLight = SugarColorPalette.ColorBaseBlue100;

export const InfoColorHeaderText = SugarColorPalette.ColorBaseBlue900;

export const HeaderGrey = SugarColorPalette.ColorBaseGray100;
export const ErrorTextColor = SugarColorPalette.ColorBaseRed700;
export const ContentTextColor = SugarColorPalette.ColorBaseGray800;

export const CorbotoLoadingColor = SugarColorPalette.ColorBaseBlue600;
export const TooltipBackgroundVColor = SugarColorPalette.ColorBaseGray700;
export const TooltipBackgroundVColorText = SugarColorPalette.ColorBaseGray300;

export const ModalButtonHoverFontColor = SugarColorPalette.ColorBaseGray600;

export const ActivityFeedChartBackground = SugarColorPalette.ColorBaseGray100;
export const ActivityFeedChartBorder = SugarColorPalette.ColorBaseGray300;

export const DisabledFormControlBackgroundColor =
  SugarColorPalette.ColorBaseGray100;

export const DarkBorderColor = SugarColorPalette.ColorBaseGray300;
export const TooltipBackgroundColor = SugarColorPalette.ColorBaseGray700;

export const ValLineStroke = SugarColorPalette.ColorBaseBlue600;
export const ValAreaFill = SugarColorPalette.ColorBaseBlue100;
export const BrushGrabberFill = SugarColorPalette.ColorBaseGray300;
export const ChartTooltipBackground = SugarColorPalette.ColorBaseGray700;
export const ChartTooltipHoverBackground = SugarColorPalette.ColorBaseGray700;
export const ScoreRectFill = SugarColorPalette.ColorBaseGray500;
export const LineChartDotStroke = SugarColorPalette.ColorBaseGray600;
export const PipelineChangesForecastColor = MediumOcean;
export const PipelineChangesIncreasesColor =
  SugarColorPalette.ColorBaseGreen600;
export const PipelineChangesDecreasesColor = SugarColorPalette.ColorBaseRed600;
export const PipelineChangesClosedWonColor =
  SugarColorPalette.ColorBaseYellow600;

export const NavDropdownBackground = SugarColorPalette.ColorBaseGray100;
export const Black = SugarColorPalette.ColorBaseBlack;

export const ModalHeaderBackground = SugarColorPalette.ColorBaseGray100;
export const SensitivityHeaderText = SugarColorPalette.ColorBaseGray800;
export const DisabledText = SugarColorPalette.ColorBaseGray400;

export const GlyphIconColor = SugarColorPalette.ColorBaseGray500;
export const MobileIconButton = SugarColorPalette.ColorBaseGray400;
export const MobileIconButtonHover = SugarColorPalette.ColorBaseGray500;

export const MainNavHover = SugarColorPalette.ColorBaseGray100;
export const ShareDropdownColor = SugarColorPalette.ColorBaseGray400;
export const FormControlShadowColor = 'rgba(0, 0, 0, 0.075)';
export const SlicerShadowColor = 'rgba(0, 0, 0, 0.35)';
export const PanelShadowColor = 'rgba(0, 0, 0, 0.15)';

export const SettingsProfileDropzoneActive = SugarColorPalette.ColorBaseGray500;

export const SelectRowPropColor = SugarColorPalette.ColorBaseGray100;

export const PivotGreen = MediumGreen;
export const PivotYellow = MediumYellow;
export const PivotRed = MediumRed;
export const PivotWhite = SugarColorPalette.ColorBaseWhite;
export const PivotBlue = MediumBlue;

export const AnomalyColor = SugarColorPalette.ColorBaseRed600;
export const FixedErrorModalShadowColor = SugarColorPalette.ColorBaseGray900;

export const InvalidBullet = SugarColorPalette.ColorBaseGray100;
export const PivotCellBorder = SugarColorPalette.ColorBaseGray300;
export const TextCellColor = SugarColorPalette.ColorBaseGray800;

export const Clear = SugarColorPalette.ColorBaseBlack;
export const MainHeaderNavActive = SugarColorPalette.ColorBaseTeal200;

export const AnomolyPrimaryPlotLine = SugarColorPalette.ColorBaseBlue700;

export const BiggestMoverTopBullet = SugarColorPalette.ColorBaseGreen600;
export const BiggestMoverBottomBullet = SugarColorPalette.ColorBaseRed600;
export const Green = SugarColorPalette.ColorBaseGreen500;

export const FocusedOuterShadow = 'rgba(15, 113, 220, 0.6)';

export const ColorBoxBorderColor = SugarColorPalette.ColorBaseGray300;
export const TextInputBorderColorFocus = SugarColorPalette.ColorBaseBlue500;
export const TextInputBorderColorError = SugarColorPalette.ColorBaseRed500;
export const TextInputBorderColorErrorFocused =
  SugarColorPalette.ColorBaseRed500;
export const TextInputAdornmentErrorColor = SugarColorPalette.ColorBaseRed500;
export const TextInputAdornmentErrorBackground =
  SugarColorPalette.ColorBaseRed100;
export const TextInputErrorGlow = SugarColorPalette.ColorBaseRed300;
export const TextInputBoxShadow = 'rgb(0 0 0 / 8%)';
export const DatasetModuleJoinsAreaBorder = SugarColorPalette.ColorBaseGray300;
export const DatasetIngestionTableHeader = SugarColorPalette.ColorBaseGray100;
export const DatasetIngestionJoinsTableInputLabel =
  SugarColorPalette.ColorBaseGray500;
export const DatePreviewBox = SugarColorPalette.ColorBaseGray100;
export const DatePreviewBoxBorder = 'rgba(0, 0, 0, 0.3)';
export const ClearWhite = SugarColorPalette.ColorBaseWhite;
export const TooltipTextColor = ContentBackground;

export const DashletLibraryHighlightRowBackground = CorvanaLightText;
export const DropdownOptionHoverBackground = SugarColorPalette.ColorBaseGray100;
export const ContrastColorDark = DropdownTextPrimary;
export const ContrastColorLight = ContentBackground;
export const TabTextColor = SugarColorPalette.ColorBaseGray600;
export const ActiveTabTextColor = SugarColorPalette.ColorBaseGray900;
export const TableRowBackgroundHover = SugarColorPalette.ColorBaseGray100;

export const TimePickerHeaderBackground = Strokes;
export const SelectedDateBackgroundColor = DefaultButtonTextColorActive;
export const SelectedDateRangeBackgroundColor =
  SugarColorPalette.ColorBaseGray100;
export const SelectedDateTextColor = ContentBackground;
export const TimePickerHoverBackground = ActiveLinkText;

export const TimePickerBorder = MediumBorder;
export const TimePickerHeaderBorder = TimePickerBorder;
export const TimePickerHeaderText = SugarColorPalette.ColorBaseGray700;
export const SlicerTimePickerHeaderBackground =
  SugarColorPalette.ColorBaseWhite;

export const DropdownButtonBackgroundColor = ContentBackground;
export const ModalBackdropColor = TooltipBackgroundColor;
export const ModalFontColor = ContentText;

export const ActiveFilterTypeTextColor = DefaultButtonTextColorActive;

export const ProfileContentBackground = SugarColorPalette.ColorBaseBlack;

export const PrimaryButtonColor = SugarColorPalette.ColorBaseBlue600;
export const PrimaryButtonTextColor = SugarColorPalette.ColorBaseWhite;
export const PrimaryButtonColorDisabled = SugarColorPalette.ColorBaseBlue500;
export const PrimaryButtonColorHover = SugarColorPalette.ColorBaseBlue700;
export const PrimaryButtonColorActive = SugarColorPalette.ColorBaseBlue800;
export const PrimaryButtonColorTextDisabled = SugarColorPalette.ColorBaseWhite;

export const SecondaryButtonColor = SugarColorPalette.ColorBaseWhite;
export const SecondaryButtonColorHover = SecondaryButtonColor;
export const SecondaryButtonColorDisabled = SugarColorPalette.ColorBaseWhite;
export const SecondaryButtonTextColor = SugarColorPalette.ColorBaseGray500;
export const SecondaryButtonTextColorHover = SugarColorPalette.ColorBaseGray900;
export const SecondaryButtonTextColorActive = SecondaryButtonTextColor;
export const SecondaryButtonTextColorDisabled =
  SugarColorPalette.ColorBaseGray400;
export const SecondaryButtonBorderColor = SugarColorPalette.ColorBaseGray300;
export const SecondaryButtonBorderColorHover = SugarGrayPalette.Gray50;
export const SecondaryButtonBorderColorActive =
  SugarColorPalette.ColorBaseGray100;
export const SecondaryButtonBorderColorDisabled =
  SugarColorPalette.ColorBaseGray100;

export const SuccessButtonColor = positiveColor;
export const SuccessButtonBorderColor = SugarColorPalette.ColorBaseGreen500;
export const SuccessButtonColorHover = SugarColorPalette.ColorBaseGreen500;
export const SuccessButtonBorderColorHover =
  SugarColorPalette.ColorBaseGreen500;

export const InfoButtonColor = SugarColorPalette.ColorBaseBlue600;
export const InfoButtonBorderColor = SugarColorPalette.ColorBaseBlue600;
export const InfoButtonColorHover = SugarColorPalette.ColorBaseBlue600;
export const InfoButtonBorderColorHover = SugarColorPalette.ColorBaseBlue600;

export const WarningButtonColor = SugarColorPalette.ColorBaseYellow400;
export const WarningButtonBorderColor = SugarColorPalette.ColorBaseYellow400;
export const WarningButtonColorHover = SugarColorPalette.ColorBaseYellow400;
export const WarningButtonBorderColorHover =
  SugarColorPalette.ColorBaseYellow400;

export const DangerButtonColor = SugarColorPalette.ColorBaseRed500;
export const DangerButtonBorderColor = SugarColorPalette.ColorBaseRed500;
export const DangerButtonColorHover = SugarColorPalette.ColorBaseRed500;
export const DangerButtonBorderColorHover = SugarColorPalette.ColorBaseRed500;

export const LinkButtonTextColor = SugarColorPalette.ColorBaseBlue600;
export const LinkButtonTextColorHover = SugarColorPalette.ColorBaseBlue700;
export const LinkButtonTextColorActive = SugarColorPalette.ColorBaseBlue800;
export const LinkButtonTextColorDisabled = SugarColorPalette.ColorBaseBlue500;

export const NavIconSelected = SugarColorPalette.ColorBaseWhite;
export const PlaceholderText = SugarColorPalette.ColorBaseGray500;
export const DefaultIconColor = SugarColorPalette.ColorBaseGray400;
export const ActiveTabIndicator = SugarColorPalette.ColorBaseBlue600;
export const BaseWhite = SugarColorPalette.ColorBaseWhite;
export const ModalBackground = SugarColorPalette.ColorBaseWhite;
export const ModalBackgroundBorder = SugarColorPalette.ColorBaseWhite;
export const DropdownBorderColor = MediumBlue;
export const FilterSelectItemCheckbox = MediumBorder;
export const FilterToolBarIconStringHover = DarkBlue;

export const SidebarNavBackground = SugarColorPalette.ColorBaseWhite;
export const SidebarNavNotch = SugarColorPalette.ColorBaseBlue500;
export const SidebarNavIcon = SugarColorPalette.ColorBaseGray400;
export const SidebarNavIconHover = SugarColorPalette.ColorBaseBlue200;
export const SidebarNavIconDisabled = SugarColorPalette.ColorBaseGray200;
export const SidebarNavNotchDisabled = SugarColorPalette.ColorBaseBlue200;
export const SidebarSubMenuBtnHover = SugarColorPalette.ColorBaseBlue300;
export const SidebarNavKebabHover = SugarColorPalette.ColorBaseBlue600;
export const SubMenuBorder = SugarColorPalette.ColorBaseBlue500;
export const SubMenuBackground = SugarColorPalette.ColorBaseWhite;
export const SubMenuItemHover = SugarColorPalette.ColorBaseGray100;
export const SubMenuItemText = SugarColorPalette.ColorBaseGray800;
export const SidebarNavOverlay = SugarColorPalette.ColorBaseBlack;
export const SidebarNavItemBackgroundHover = SugarColorPalette.ColorBaseGray100;
export const SidebarNavText = SugarColorPalette.ColorBaseGray500;
export const SidebarNavItemTextHover = SugarColorPalette.ColorBaseGray600;
export const SidebarNavSugar = SugarColorPalette.ColorBaseGray400;
export const SidebarNavSugarHover = SugarColorPalette.ColorBaseWhite;
export const AccountProfileSectionBackground =
  SugarColorPalette.ColorBaseGray100;
export const FromBackgroundGradientColor = SugarColorPalette.ColorBaseBlue700;
export const ToBackgroundGradientColor = SugarColorPalette.ColorBaseBlue400;
export const HeaderIconColor = White;
export const ProfileHoverColor = SugarColorPalette.ColorBaseBlue300;
export const MainContentBoxShadowColor = SugarColorPalette.ColorBaseBlue600;
export const IconExpandField = MediumBlue;

export const SwitchThumb = SugarColorPalette.ColorBaseWhite;
export const SwitchTrackChecked = SugarColorPalette.ColorBaseBlue500;
export const SwitchTrackUnchecked = SugarColorPalette.ColorBaseGray200;
export const FilterWidgetHoverBackground = SugarColorPalette.ColorBaseGray100;
export const FilterNavLabel = MediumBlue;
export const SVGFillColor = '#81848F';
export const SVGFillMediumColor = '#D1D3D4';
