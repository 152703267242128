import { Component } from 'react';
import { find, findIndex, isEmpty, isNil, reject, pick } from 'lodash';
import styled from '@emotion/styled';
import { max } from 'd3';
import Util from '../../common/Util';
import { messages } from '../../i18n';
import Discover from '../../common/redux/actions/DiscoverActions';
import { compose, pure } from 'react-recompose';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getAllLinksInPlay } from './utils';
import { Viz } from '../../discovery/VizUtil';
import { Box } from '@mui/system';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
  CheckboxStyles,
  FormControlLabelStyles,
} from '../../discovery/viz-formatting';

const Header = styled.div`
  font-size: 14px;
  margin-bottom: 8px;
`;

const TextWrapper = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

class UnconnectedPivotDrillLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxTextWidth: max(
        this.props.metrics.map(m => Util.calcTextWidth(m.name)),
      ),
      menuOpen: false,
    };
  }

  onChange(checked, link) {
    const data = checked
      ? [
          ...this.props.drillLinking,
          {
            attribute: link.attribute.name,
            formula: link.formula,
          },
        ]
      : reject(this.props.drillLinking, { attribute: link.attribute.name });

    this.props.setDrillLinking(data);
  }

  onColorScaleChange(metric, colorScale) {
    const data = [...this.props.conditionalFormatting];
    const index = findIndex(data, pick(metric, 'name'));
    if (index !== -1) {
      data[index].colorScale = colorScale;
    }
    this.props.setConditionalFormatting(data);
  }

  render() {
    if (isEmpty(this.props.links)) {
      return [];
    }
    const formControlLabelSx = FormControlLabelStyles();
    const checkboxSx = CheckboxStyles();
    return [
      <Header key='pivot-drill-link-header'>
        {messages.formatting.drillLinking}
      </Header>,
      <Box key='pivot-drill-link-form' className='pivot-conditional-formatting'>
        <Box className='pivot-conditional-formatting__form-group'>
          {Object.values(this.props.links).map(link => {
            const formatting = find(this.props.drillLinking, {
              attribute: link.attribute.name,
            });
            const checked = !isNil(formatting);
            return (
              <FormControlLabel
                sx={formControlLabelSx}
                key={`pivot-link-setting-${link.settingLabel}`}
                control={
                  <Checkbox
                    sx={checkboxSx}
                    disabled={this.props.disabled}
                    checked={checked}
                    onChange={event =>
                      this.onChange(event.target.checked, link)
                    }
                  />
                }
                label={
                  <TextWrapper>
                    <span
                      title={link.settingTooltip || link.settingLabel}
                      style={{ minWidth: this.state.maxTextWidth }}
                    >
                      {link.settingLabel}
                    </span>
                  </TextWrapper>
                }
              />
            );
          })}
        </Box>
      </Box>,
    ];
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setDrillLinking: data => {
      dispatch(
        Discover.setSettingForViz({
          id: ownProps.vizId,
          setting: 'drillLinking',
          value: data,
        }),
      );
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  const open = state.discover.openDiscoveries[ownProps.vizId].present;

  const links = getAllLinksInPlay(
    open.viz,
    Viz.getAllFieldsInPlay(open.viz.layout),
  );

  return {
    viz: open.viz,
    metrics: open.viz.layout.VALUES,
    links,
    drillLinking: JSON.parse(_.get(open, 'viz.options.drillLinking', '[]')),
  };
};

export const PivotDrillLinks = compose(
  pure,
  connect(mapStateToProps, mapDispatchToProps),
)(UnconnectedPivotDrillLinks);
