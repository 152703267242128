import { css } from '@emotion/react';
import { useCallback, useState } from 'react';
import { messages } from '../../../i18n';
import { SugarIcon } from '../../../icons/sugar-icon/sugar-icon.component';
import { MobileSlicers } from '../mobile-slicers/mobile-slicers.component';

export function SlicerButton({
  hideLabel,
  isMobile = false,
  isDashletMode = false,
  vizId = null,
}) {
  const [show, setShow] = useState(false);
  const setShowCallback = useCallback(
    s => {
      setShow(s);
    },
    [setShow],
  );

  const slicerIcon = isDashletMode || !isMobile ? 'slicers-lg' : 'slicers-xl';
  return (
    <div
      className={`mobile-icon-button${!isDashletMode ? ' mobile' : ''}`}
      onClick={() => {
        setShow(true);
      }}
      role='button'
    >
      <SugarIcon
        icon={slicerIcon}
        css={css`
          ${isDashletMode
            ? `
            position: relative;
            top: -2px;
            padding-top: 4px;
            padding-bottom: 2px;
          `
            : ''}
        `}
        hover
      />
      <MobileSlicers
        show={show}
        setShow={s => {
          setShowCallback(s);
        }}
        vizId={vizId}
      />
      {!hideLabel && <div>{messages.slicer.buttonLabel}</div>}
    </div>
  );
}
