import Const from '../actions/ActionConst';
import {
  OpenDiscoveryInternalReducer,
  OpenDiscoveryReducer,
} from './OpenDiscoveryReducer';
import _ from 'lodash';
import { composeResetReducer } from 'redux-reset-store';
import { Viz } from '../../../discovery/VizUtil';
import ServiceLocator from '../../ServiceLocator';
import { ComponentTypes, Labels } from '../../Constants';
import InListFilter from '../../../discovery/filter/exports/InListFilter';
import { ChartSpecs } from '../../../discovery/ChartSpecs';
import store from '../index';
import * as shortid from 'shortid';
import {
  IDiscovery,
  IDiscoveryTracked,
  IDiscoveryType,
  StringBool,
} from '../../../discovery';
import { ILegendDatum } from '../../../discovery/charts/viz-legend';
import { IReportDetailInfo } from '../../../discovery/viz-redirect';
import { ITimeCalcAttribute } from '../../../datasets/interfaces';

interface IMonitor {
  [key: string]: any;
  payload: any;
  discoveryType: IDiscoveryType;
  description: string;
}

export interface IDiscoverReducerState {
  monitors: IMonitor[];
  fetching: boolean;
  fetched: boolean;
  error: any;
  deleting: boolean;
  deleted: boolean;
  showAddMonitorDialog: boolean;
  showSaveDialog: boolean;
  showSaveAsDialog?: boolean;
  saveActive: boolean;
  openDiscoveries: {
    [key: string]: IDiscoveryTracked;
  };
  openDiscoveriesTabList: string[];
  displayDiscovery: string;
  prevDisplayDiscovery: null; // doesn't seem to change from null
  queryResults: any;
  rangeSelection: '30d';
  pinnedDiscoveriesLoading: boolean;
  toBeDeleted?: any;
  toBeAdded?: any;
  reportDetailInfo?: IReportDetailInfo;
  reportDetailInfoUpdating?: boolean;
  linkStrategy?: string;
}

export const initialState: IDiscoverReducerState = {
  monitors: [],
  fetching: false,
  fetched: false,
  error: null,
  deleting: false,
  deleted: false,
  showAddMonitorDialog: false,
  showSaveDialog: false,
  saveActive: false,
  openDiscoveries: {},
  openDiscoveriesTabList: [],
  displayDiscovery: null,
  prevDisplayDiscovery: null,
  queryResults: null,
  rangeSelection: '30d',
  pinnedDiscoveriesLoading: false,
};

const appendPropsToDiscoveryOptions = (id, state, props, options) => {
  const newState = { ...state };
  const d = newState.openDiscoveries[state.displayDiscovery];
  const newD = { ...d };
  const current = { ...newD.present };
  const opts = { ...current.viz.options };
  Object.assign(opts, props);
  newState.openDiscoveries[state.displayDiscovery].present.viz.options = opts;
  const { present } = newState.openDiscoveries[state.displayDiscovery];
  newState.openDiscoveries[state.displayDiscovery].present = {
    ...present,
    ...options,
  };

  return newState;
};

const insightComponents = () =>
  ServiceLocator.getAll('COMPONENT', {
    type: ComponentTypes.INSIGHTS,
  }).filter(c => _.isFunction(c.supports));

const DiscoveryReducers = {};
export default composeResetReducer(
  (state = initialState, action) => {
    const currentOpenDiscoveries = state.openDiscoveries ?? {};
    const currentOpenDiscovery = currentOpenDiscoveries[action.discoveryId]
      ? currentOpenDiscoveries[action.discoveryId].present
      : null;

    const enforcePresentState = () => {
      if (_.isNil(currentOpenDiscovery)) {
        throw new Error(
          `Failed to process ${action.type}. state.openDiscoveries[${action.discoveryId}].present is not known.`,
        );
      }
    };

    try {
      switch (action.type) {
        case Const.Discover.SET_SHOW_ADD_MONITOR_DIALOG:
          return {
            ...state,
            showAddMonitorDialog: action.show,
          };
        case Const.Discover.SET_SHOW_ADD_COMP_MONITOR:
          return {
            ...state,
            showAddComparisonPeriodMonitorDialog: action.show,
          };
        case Const.Discover.POST_MONITORS_FULFILLED:
          return {
            ...state,
            showAddMonitorDialog: false,
          };
        case Const.Discover.CLOSE_OPEN_DISCOVERY:
          // Find removed discoveries and de-register reducer

          _.forEach(
            _.difference(
              _.keys(state.openDiscoveries),
              _.keys(action.openDiscoveries),
            ),
            (discoveryId: any) => delete DiscoveryReducers[discoveryId],
          );

          return {
            ...state,
            openDiscoveries: action.openDiscoveries,
            openDiscoveriesTabList: action.openDiscoveriesTabList,
            prevDisplayDiscovery: action.prevDisplayDiscovery,
            displayDiscovery: action.displayDiscovery,
          };
        case Const.Discover.CLOSE_OPEN_DELETED_DISCOVERY:
          // de-register reducer
          delete DiscoveryReducers[action.discoveryId];

          return {
            ...state,
            openDiscoveries: action.openDiscoveries,
            openDiscoveriesTabList: action.openDiscoveriesTabList,
            prevDisplayDiscovery: action.prevDisplayDiscovery,
            displayDiscovery: action.displayDiscovery,
          };
        case Const.Discover.ADD_OPEN_DISCOVERY: {
          const newDiscoveryMap = {
            ...state.openDiscoveries,
            [action.discoveryId]: {
              options: {
                configPanelDetail: 'layout',
                panelDetail: null,
                showPanel: true,
              },
              present: {
                isPrivate: true,
              },
            },
          };
          DiscoveryReducers[action.discoveryId] = OpenDiscoveryReducer(
            action.discoveryId,
          );

          let openDiscoveriesTabList = [
            ...state.openDiscoveriesTabList,
            action.discoveryId,
          ];

          if (_.isInteger(action.tabIndex) && action.tabIndex > -1) {
            // if maintaining an order
            openDiscoveriesTabList = [
              ..._.slice(state.openDiscoveriesTabList, 0, action.tabIndex),
              action.discoveryId,
              ..._.slice(state.openDiscoveriesTabList, action.tabIndex),
            ];
          }

          return {
            ...state,
            openDiscoveries: newDiscoveryMap,
            openDiscoveriesTabList: _.uniq(openDiscoveriesTabList),
          };
        }
        case Const.Discover.OPEN_PINNED_DISCOVERIES: {
          const pinnedOpenMap = action.discoveryIds.reduce((accum, id) => {
            accum[id] = {
              options: {
                configPanelDetail: 'layout',
                panelDetail: null,
                showPanel: true,
              },
              present: {
                id,
                discoveryType: 'VISUALIZATION',
              },
            };
            return accum;
          }, {});
          const newDiscoveryMap = {
            ...state.openDiscoveries,
            ...pinnedOpenMap,
          };
          action.discoveryIds.forEach(id => {
            DiscoveryReducers[id] = OpenDiscoveryReducer(id);
          });

          return {
            ...state,
            openDiscoveries: newDiscoveryMap,
            displayDiscovery: _.isEmpty(action.discoveryIds)
              ? undefined
              : action.discoveryIds[0],
            openDiscoveriesTabList: _.uniq([
              ...state.openDiscoveriesTabList,
              ...action.discoveryIds,
            ]),
          };
        }
        case Const.Discover.SET_PINNED_DISCOVERIES_LOADING: {
          return {
            ...state,
            pinnedDiscoveriesLoading: action.loading,
          };
        }
        case Const.Discover.SET_VIZ_IS_EMPTY:
          return {
            ...state,
            vizIsEmpty: action.isEmpty,
          };
        case Const.Discover.SET_DISPLAY_DISCOVERY:
          return {
            ...state,
            displayDiscovery: action.displayDiscovery,
            prevDisplayDiscovery: action.prevDisplayDiscovery,
            discoveryAlreadyRendered: action.discoveryAlreadyRendered,
          };
        case Const.Discover.POST_QUERY_FULFILLED:
          return {
            ...state,
            queryResults: action.queryResults,
          };
        case Const.Discover.SET_SHOW_NEW_VIZ_DIALOG:
          return {
            ...state,
            showNewVizDialog: action.show,
          };
        case Const.Discover.SET_SHOW_SAVE_DIALOG:
          return {
            ...state,
            showSaveDialog: action.show,
            closeDialogAfterSave: action.closeDialogAfterSave ?? false,
          };
        case Const.Discover.SET_SHOW_VERSION_DIALOG:
          return {
            ...state,
            showVersionDialog: action.show,
          };
        case Const.Discover.SET_SHOW_SAVE_AS_DIALOG:
          return {
            ...state,
            showSaveAsDialog: action.show,
          };
        case Const.Discover.SET_SAVE_ACTIVE:
          return {
            ...state,
            saveActive: action.value,
          };
        case Const.Discover.SET_SAVE_ERROR:
          return {
            ...state,
            saveError: action.msg,
          };
        case Const.Discover.SET_VIZ_TRASH_DROP_TARGET: {
          return {
            ...state,
            dndTrashTargetId: action.dndTrashTargetId,
          };
        }

        // The following actions should not be added to the undo/redo stacks
        case Const.Discover.MOBILE_INSIGHTS_CHECKED:
          return appendPropsToDiscoveryOptions(
            action.discoveryId,
            { ...state },
            {},
            { mobileInsightsChecked: true },
          );
        case Const.Discover.BEGIN_NEW_VIZ_OPENING:
          return {
            ...state,
            newVizOpening: true,
          };
        case Const.Discover.SET_NEW_VIZ:
          return {
            ...state,
            newViz: action.newViz,
            newVizOpening: false,
          };
        case Const.Discover.BEGIN_REPORT_DETAIL_UPDATE:
          return {
            ...state,
            reportDetailInfoUpdating: true,
          };
        case Const.Discover.SET_REPORT_DETAIL_INFO:
          return {
            ...state,
            reportDetailInfo: action.reportDetailInfo,
            linkStrategy: action.linkStrategy,
            reportDetailInfoUpdating: false,
          };
        case Const.Discover.SET_VIZ_ID: {
          enforcePresentState();
          let { openDiscoveries } = state;
          const dis = openDiscoveries[action.discoveryId].present;

          // Set ID in the viz member
          const viz = { ...dis.viz, id: action.id };
          if (!_.isNil(action.creatorName)) {
            dis.creatorName = action.creatorName;
          }
          if (!_.isNil(action.creator)) {
            dis.creator = action.creator;
          }

          // swap open discovery to new ID
          openDiscoveries[action.discoveryId].present = {
            ...dis,
            viz,
            id: action.id,
          };
          openDiscoveries[action.id] = openDiscoveries[action.discoveryId];

          const replaceState = discState => {
            discState.id = action.id;
            discState.viz.id = action.id;
            discState.viz.name = openDiscoveries[action.id].present.name;
            discState.name = openDiscoveries[action.id].present.name;
          };

          _.forEach(openDiscoveries[action.id].past, replaceState);
          _.forEach(openDiscoveries[action.id].future, replaceState);

          DiscoveryReducers[action.id] = OpenDiscoveryReducer(action.id);

          // Replace old entry in tab list with new id, preserves position
          const tabList = state.openDiscoveriesTabList;
          tabList.splice(_.indexOf(tabList, action.discoveryId), 1, action.id);

          // Remove the old entry, creates a new object to poke state subscribers
          openDiscoveries = _.omit(openDiscoveries, action.discoveryId);

          // Update Display Discovery if was pointing to previous, otherwise preserve
          const displayDiscovery =
            state.displayDiscovery === action.discoveryId
              ? action.id
              : state.displayDiscovery;
          return {
            ...state,
            openDiscoveries,
            openDiscoveriesTabList: tabList,
            displayDiscovery,
          };
        }
        case Const.Discover.SET_DISCOVERY_DIRTY: {
          enforcePresentState();
          const discoveries = state.openDiscoveries;
          const discovery = discoveries[action.discoveryId].present;
          discoveries[action.discoveryId].present = {
            ...discovery,
            dirty: action.dirty,
          };
          return {
            ...state,
            openDiscoveries: discoveries,
          };
        }
        case Const.Discover.SET_SAVE_CHECKPOINT: {
          const { discoveryId, index, updatedOn, revisions } = action;
          const discoveries = { ...state.openDiscoveries };

          if (!discoveries[discoveryId]) {
            return state;
          }

          const discovery = { ...discoveries[action.discoveryId] };
          const pastLength = discovery.past ? discovery.past.length : 0;
          discovery.saveCheckpoint = _.isNil(index)
            ? _.isEmpty(discovery.past)
              ? 0
              : pastLength - 1
            : index;
          discovery.updatedOn = updatedOn;
          discovery.present.updatedOn = updatedOn;
          discovery.present.viz.revisions = revisions;
          discoveries[discoveryId] = _.omit(
            discovery,
            'present.viz.revisionSelected',
          );

          return {
            ...state,
            openDiscoveries: discoveries,
          };
        }
        case Const.Discover.SET_VIZ_LEGEND_DATA: {
          enforcePresentState();
          const openViz = state.openDiscoveries;
          const disc = openViz[action.discoveryId].present;
          disc.legendData = [];
          const ld: ILegendDatum[] = [...action.legendData];
          openViz[action.discoveryId].present = {
            ...disc,
            legendData: ld,
          } as IDiscovery;
          return {
            ...state,
            openDiscoveries: openViz,
          };
        }
        case Const.Discover.SET_SHOW_VIZ_FILTER_DIALOG: {
          let { field } = action;
          if (_.isString(field)) {
            const vizID = state.displayDiscovery;
            const { viz } = state.openDiscoveries[vizID].present;
            const { goodFields } = viz;
            const calcFields = Viz.getCalcsFromViz(viz);
            field =
              _.find([...goodFields, ...calcFields], {
                name: field,
              }) || field;
          }
          return {
            ...state,
            activeFilterField: field,
            showFieldFilterDialog: action.show,
          };
        }
        case Const.Discover.SET_ACTIVE_VIZ_FIELD_FILTER: {
          return {
            ...state,
            activeFilter: { ...action.filter },
          };
        }
        case Const.Discover.CLEAR_VIZ_SORTING: {
          const open = state.openDiscoveries;
          const disc = open[action.discoveryId].present;
          const viz = { ...disc.viz };
          const options = { ...viz.options };
          let querySort;
          try {
            querySort = JSON.parse(options.querySort);
          } catch (e) {
            querySort = {};
          }
          if (action.field) {
            delete querySort[action.field.name];
          }
          options.querySort = JSON.stringify(querySort);
          viz.options = options;
          open[action.discoveryId].present.viz = viz;
          return {
            ...state,
            openDiscoveries: open,
          };
        }
        case Const.Discover.SET_SHOW_VIZ_CALC_DIALOG: {
          return {
            ...state,
            showFieldCalcDialog: action.show,
            activeCalcField: action.field,
          };
        }
        case Const.Discover.SET_TOOLTIP_DATA: {
          enforcePresentState();
          const open = state.openDiscoveries;
          const disc = open[action.discoveryId].present;
          if (
            _.isEqual(disc.tooltipData, action.tooltipData) ||
            (disc.tooltipData &&
              action.tooltipData &&
              _.isEqual(disc.tooltipData.hoverX, action.tooltipData.hoverX) &&
              _.isEqual(disc.tooltipData.hoverY, action.tooltipData.hoverY))
          ) {
            // Equivalent to current state, return without modification
            return state;
          }
          open[action.discoveryId].present = {
            ...disc,
            tooltipData: action.tooltipData,
          };
          return {
            ...state,
            openDiscoveries: open,
          };
        }
        case Const.Discover.SET_SCROLL_PCT: {
          enforcePresentState();
          const open = state.openDiscoveries;
          const disc = open[action.discoveryId].present;
          open[action.discoveryId].present = {
            ...disc,
            scrollLeftPct: action.pctLeft,
            scrollTopPct: action.pctTop,
          };
          return {
            ...state,
            openDiscoveries: open,
          };
        }
        case Const.Discover.SET_HOVER_OVER_VIZ_DATA: {
          enforcePresentState();
          const open = state.openDiscoveries;
          const disc = open[action.discoveryId].present;
          open[action.discoveryId].present = {
            ...disc,
            hoverData: action.data,
            hoverEvent: action.event,
          };
          return {
            ...state,
            openDiscoveries: open,
          };
        }
        case Const.Discover.AUTO_HYDRATE_VIZ_FIELD_FILTER: {
          const open = state.openDiscoveries;
          const disc = open[state.displayDiscovery].present;
          const { viz } = disc;
          const filters = Viz.getFiltersFromViz(viz);
          const filterTarget =
            filters[action.filterField.name] ||
            InListFilter(action.filterField, action.filterList);
          filterTarget.expression.left.operands = action.filterList;
          _.set(filterTarget, 'info.selected', action.filterList.length);
          filters[action.filterField.name] = filterTarget;
          const stringFilters = JSON.stringify(filters);
          viz.options.filters = stringFilters;
          return {
            ...state,
            openDiscoveries: open,
          };
        }
        case Const.Discover.CLEAR_VIZ_QUERY: {
          enforcePresentState();
          const open = state.openDiscoveries;
          const disc = open[action.discoveryId].present;
          open[action.discoveryId].present = {
            ...disc,
            vizQueryResults: null,
          };
          return {
            ...state,
            openDiscoveries: open,
          };
        }
        case Const.Discover.VIZ_QUERY_LOADING: {
          enforcePresentState();
          const open = state.openDiscoveries;
          const disc = open[action.discoveryId].present;
          const openDiscovery = open[action.discoveryId];

          return {
            ...state,
            openDiscoveries: {
              ...open,
              [action.discoveryId]: {
                ...openDiscovery,
                present: {
                  ...disc,
                  vizLoading: action.loading,
                  queryId: action.queryId,
                  vizQueryResults: null,
                  vizQueryError: null,
                },
              },
            },
          };
        }
        case Const.Discover.VIZ_QUERY_FINISHED: {
          enforcePresentState();
          const open = state.openDiscoveries;
          const disc = open[action.discoveryId].present;
          const openDiscovery = open[action.discoveryId];

          return {
            ...state,
            openDiscoveries: {
              ...open,
              [action.discoveryId]: {
                ...openDiscovery,
                present: {
                  ...disc,
                  vizLoading: action.loading,
                  vizQueryResults: action.data,
                  vizQuerySecondaryResults: action.secondaryData,
                  vizQueryError: null,
                  vizQueryWarning: null,
                  focusedData: [],
                  focusedDataPoints: [],
                  collectDetailInfoArgs: null,
                },
              },
            },
          };
        }
        case Const.Discover.VIZ_QUERY_WARNING: {
          enforcePresentState();
          const open = state.openDiscoveries;
          const disc = open[action.discoveryId].present;
          if (
            !_.isEmpty(open[action.discoveryId].present?.vizQueryWarning) ||
            action.queryId !== disc.viz.queryId
          ) {
            // discarding out-of-date query result
            return state;
          }
          open[action.discoveryId].present = {
            ...disc,
            vizLoading: action.loading,
            vizQueryResults: null,
            vizQueryWarning: {
              warnings: action.warnings,
              queryVariables: action.queryVariables,
            },
            vizQueryError: null,
          };
          return {
            ...state,
            openDiscoveries: open,
          };
        }
        case Const.Discover.VIZ_QUERY_ERROR: {
          enforcePresentState();
          const open = state.openDiscoveries;
          const disc = open[action.discoveryId].present;
          if (action.queryId !== disc.viz.queryId) {
            // discarding out-of-date query result
            return state;
          }
          open[action.discoveryId].present = {
            ...disc,
            vizLoading: action.loading,
            vizQueryResults: null,
            vizQueryError: {
              error: action.error,
              queryOptions: action.queryOptions,
            },
            vizQueryWarning: null,
          };
          return {
            ...state,
            openDiscoveries: open,
          };
        }
        case Const.Discover.IGNORE_VIZ_QUERY_ERROR: {
          enforcePresentState();
          const open = state.openDiscoveries;
          const disc = open[action.discoveryId].present;

          open[action.discoveryId].present = {
            ...disc,
            vizQueryError: undefined,
          };
          return {
            ...state,
            openDiscoveries: open,
          };
        }
        case Const.Discover.SHOW_CONFIRM_REMOVE_FIELD: {
          if (action.show) {
            return {
              ...state,
              showConfirmRemoveField: action.show,
              toBeDeleted: {
                field: action.field,
                discoveryId: action.discoveryId,
                shelf: action.shelf,
              },
            };
          } else {
            const newState = { ...state };
            if (newState.toBeDeleted) {
              delete newState.toBeDeleted;
            }
            return {
              ...newState,
              showConfirmRemoveField: action.show,
            };
          }
        }
        case Const.Discover.SHOW_CONFIRM_ADD_FIELD: {
          if (action.show) {
            return {
              ...state,
              showConfirmAddField: action.show,
              toBeAdded: {
                field: action.field,
                discoveryId: action.discoveryId,
                shelf: action.shelf,
                breakingFields: action.breakingFields,
                insertionPosition: action.insertionPosition,
              },
            };
          } else {
            const newState = { ...state };
            if (newState.toBeAdded) {
              delete newState.toBeAdded;
            }
            return {
              ...newState,
              showConfirmAddField: action.show,
            };
          }
        }

        case Const.Discover.SET_LIVE_QUERY: {
          enforcePresentState();
          const { openDiscoveries } = state;
          const val: StringBool = _.isString(action.value)
            ? action.value
            : action.value
            ? 'true'
            : 'false';
          const dis = openDiscoveries[action.discoveryId].present;
          const options = { ...dis.viz.options, useLiveQuery: val };
          const viz = {
            ...dis.viz,
            options,
          };
          openDiscoveries[action.discoveryId].present = {
            ...dis,
            viz,
          };
          return {
            ...state,
            openDiscoveries: { ...openDiscoveries },
          };
        }

        case Const.Discover.UPDATE_VIZ_NAME: {
          enforcePresentState();
          const { openDiscoveries } = state;
          const dis = openDiscoveries[action.discoveryId].present;
          // Set ID in the viz member
          const viz = { ...dis.viz, name: action.newName };
          openDiscoveries[action.discoveryId].present = {
            ...dis,
            name: action.newName,
            viz,
          };

          return {
            ...state,
            openDiscoveries: { ...openDiscoveries },
          };
        }

        case Const.Discover.COPY_VIZ_STATE: {
          const { openDiscoveries } = state;
          const from = openDiscoveries[action.fromId];
          const to = openDiscoveries[action.toId];
          Object.assign(
            to ?? {},
            _.omit(from, ['past', 'future', 'present', 'history']),
          );
          return {
            ...state,
            openDiscoveries: { ...openDiscoveries },
          };
        }

        case Const.Discover.COPY_UNDO_STACK: {
          const { openDiscoveries } = state;
          const from = openDiscoveries[action.fromId];
          const to = openDiscoveries[action.toId];
          const toPast = from.past.map(past => {
            const t = {
              ...past,
              id: action.toId,
              name: action.toName,
              viz: { ...past.viz, id: action.toId, name: action.toName },
            };
            return t;
          });
          const toFuture = from.future.map(future => {
            const t = {
              ...future,
              id: action.toId,
              name: action.toName,
              viz: { ...future.viz, id: action.toId, name: action.toName },
            };
            return t;
          });
          to.past = [...toPast];
          to.future = [...toFuture];
          to.history.past = [...toPast];
          to.history.future = [...toFuture];
          to.present = { ...to.present, id: action.toId, name: action.toName };

          return {
            ...state,
            openDiscoveries: { ...openDiscoveries },
          };
        }
        case Const.Discover.UPDATE_DATASET_FOR_DISCOVERY: {
          const { openDiscoveries } = state;
          const open = openDiscoveries[action.discoveryId];
          if (!_.isNil(open)) {
            const current = { ...open.present };
            current.dataset = { ...action.dataset };
            if (!_.isNil(current.viz)) {
              current.viz.dataset = { ...action.dataset };
            }

            // replace layout attributes
            Object.entries(current.viz.layout).forEach(([key, shelf]) => {
              current.viz.layout[key] = shelf
                .map(attr => {
                  let newAttr;
                  const datasetAttr = action.dataset.attributes.find(a => {
                    return a.name === attr.name;
                  });
                  if (!_.isNil(datasetAttr)) {
                    newAttr = datasetAttr;
                  } else if (
                    _.isNil(datasetAttr) &&
                    !_.isEmpty(attr.parentField)
                  ) {
                    // this is a hierarchy calc, make sure it is preserved in the layout
                    const parentAttr = action.dataset.attributes.find(
                      a => a.name === attr.parentField.name,
                    );
                    if (!_.isNil(parentAttr)) {
                      newAttr = { ...attr, parentField: { ...parentAttr } };
                    } else {
                      // the parent field has disappeared, the time calc isn't valid anymore
                    }
                  } else if (!_.isEmpty((attr as ITimeCalcAttribute).formula)) {
                    // viz calc. it needs to remain
                    newAttr = { ...attr };
                  }
                  return newAttr;
                })
                .filter(f => !_.isNil(f));
            });

            openDiscoveries[action.discoveryId].present = current;
          }
          return {
            ...state,
            openDiscoveries: { ...openDiscoveries },
          };
        }
        case Const.Discover.REMOVE_MISSING_FIELDS: {
          const { openDiscoveries } = state;
          const open = openDiscoveries[action.discoveryId];
          if (!_.isNil(open)) {
            const viz = { ...open.present.viz };
            const layout = { ...viz.layout };

            action.fieldsToRemove.forEach(remove => {
              const shelf = Viz.findShelfContainingField(layout, remove.name);
              if (!_.isNil(shelf)) {
                const shelfFields = layout[shelf];
                const removed = shelfFields.filter(f => {
                  return f.name !== remove.name;
                });
                layout[shelf] = [...removed];
              }
            });

            // is it a saved calc or time-calc?
            const calcs = Viz.getCalcsFromViz(viz);
            if (!_.isEmpty(calcs)) {
              const updatedCalcs = calcs.filter(c => {
                return !_.some(action.fieldsToRemove, _.pick(c, 'name'));
              });
              viz.options.calcFields = JSON.stringify(updatedCalcs);
            }

            const timeCalcs = Viz.getTimeCalcsFromViz(viz);
            if (!_.isEmpty(timeCalcs)) {
              const finalTimeCalcs = Object.entries(timeCalcs).reduce(
                (accum, [key, c]) => {
                  // remove invalid fields
                  const updated = c.filter(tc => {
                    return !_.some(action.fieldsToRemove, _.pick(tc, 'name'));
                  });
                  if (!_.isEmpty(updated)) {
                    accum[key] = updated;
                  }
                  return accum;
                },
                {},
              );
              viz.options.timeHierarchies = JSON.stringify(finalTimeCalcs);
            }
            const filters = Viz.getFiltersFromViz(viz);
            const goodFilters = Object.values(filters).reduce((accum, f) => {
              if (!_.some(action.filtersToRemove, _.pick(f, 'field'))) {
                accum[f.field] = f;
              }
              return accum;
            }, {});
            const updatedOptions = {
              ...viz.options,
              filters: JSON.stringify(goodFilters),
            };

            delete viz.missingFields;
            delete viz.missingDependantFields;
            delete viz.goodFields;
            delete viz.missingFilters;

            openDiscoveries[action.discoveryId].present.viz = {
              ...viz,
              layout: { ...layout },
              options: { ...updatedOptions },
              requiresAutoSave: true,
            };
          }
          return {
            ...state,
            openDiscoveries: { ...openDiscoveries },
          };
        }
        case Const.Discover.INSIGHT_ERROR: {
          const open = state.openDiscoveries;
          const disc = open[action.discoveryId].present;

          if (action.subscriptionId !== disc.subscriptionId) {
            return state;
          }

          open[action.discoveryId].present = {
            ...disc,
            insightsLoading: false,
          };
          return {
            ...state,
            openDiscoveries: { ...open },
          };
        }
        case Const.Discover.INSIGHTS_LOADING: {
          const open = state.openDiscoveries;
          const disc = open[action.discoveryId].present;
          open[action.discoveryId].present = {
            ...disc,
            dynamicInsights: [],
            insightsLoading: true,
            insightsChecked: false,
            subscriptionId: action.subscriptionId,
          };
          return {
            ...state,
            openDiscoveries: { ...open },
          };
        }
        case Const.Discover.INSIGHTS_FINISHED: {
          const open = state.openDiscoveries;
          const disc = open[action.discoveryId].present;
          if (action.subscriptionId !== disc.subscriptionId) {
            return state;
          }
          open[action.discoveryId].present = {
            ...disc,
            insightsLoading: false,
          };
          return {
            ...state,
            openDiscoveries: { ...open },
          };
        }
        case Const.Discover.INSIGHT_RETURNED: {
          const open = state.openDiscoveries;
          const disc = open[action.discoveryId].present;
          const { options } = disc.viz;
          const insightsChecked =
            options.panelDetail === Labels.DISCOVERY_INSIGHTS
              ? true
              : disc.insightsChecked;

          if (action.subscriptionId !== disc.subscriptionId) {
            return state;
          }

          // either add or update the insight
          let { dynamicInsights } = disc;
          let found = false;
          for (let i = 0; i < dynamicInsights.length; i++) {
            if (dynamicInsights[i].id === action.insight.id) {
              dynamicInsights[i] = action.insight;
              found = true;
              break;
            }
          }
          if (!found) {
            dynamicInsights = dynamicInsights.concat(action.insight);
          }

          open[action.discoveryId].present.dynamicInsights = dynamicInsights;
          open[action.discoveryId].present.insightsChecked = insightsChecked;

          // Check to see that at least one event will be rendered
          let hasAdvancedInsights = false;
          const hasInsights = !!dynamicInsights.find(event => {
            const isAdvancedOnly = _.matches({ insightType: 'ERROR' })(event);
            const components = insightComponents();
            const componentFound = !!components.find(comp =>
              comp.supports({
                referenceType: 'MonitorEvent',
                monitorEvent: event,
              }),
            );
            hasAdvancedInsights =
              hasAdvancedInsights || (isAdvancedOnly && componentFound);
            return !isAdvancedOnly && componentFound;
          });

          if (hasInsights && !insightsChecked && options.showPanel === 'true') {
            options.panelDetail = Labels.DISCOVERY_INSIGHTS;
            open[action.discoveryId].present.viz.options = { ...options };
          }

          Object.assign(open[action.discoveryId].present, {
            hasInsights,
            hasAdvancedInsights,
          });
          if (action.skipUpdate) {
            return state;
          }
          return {
            ...state,
            openDiscoveries: open,
          };
        }

        case Const.Discover.APPLY_MONITOR_EVENT_ID: {
          enforcePresentState();
          const open = state.openDiscoveries;
          const disc = open[action.discoveryId].present;
          open[action.discoveryId].present = {
            ...disc,
            monitorEventId: action.monitorEventId,
          };
          return {
            ...state,
            openDiscoveries: open,
          };
        }

        case Const.Discover.SET_FOCUSED_VIZ_DATA: {
          enforcePresentState();
          const openDiscoveries = _.cloneDeep(state.openDiscoveries);
          const activeDiscovery = openDiscoveries[action.discoveryId].present;
          let { focusedData = [], focusedDataPoints = [] } = activeDiscovery;
          const index = _.findIndex(focusedData, action.data);
          if (_.isNil(action.data)) {
            focusedData = [];
          } else if (action.multiSelectEnabled) {
            if (index === -1) {
              focusedData.push(action.data);
            } else {
              focusedData.splice(index, 1);
            }
          } else {
            focusedData =
              index === -1 || focusedData.length > 1 ? [action.data] : [];
          }

          if (!_.isEmpty(focusedData)) {
            // remove data points not in focusedData
            focusedDataPoints = _.filter(
              focusedDataPoints,
              _focusedDataPoint => {
                return _.some(focusedData, _pathInfo =>
                  _.isEqual(_focusedDataPoint.pathInfo, _pathInfo),
                );
              },
            );
          }

          openDiscoveries[action.discoveryId].present = {
            ...activeDiscovery,
            focusedData,
            focusedDataPoints,
            hoverEvent: action.event,
          };

          return {
            ...state,
            openDiscoveries,
          };
        }

        case Const.Discover.TOGGLE_FOCUSED_VIZ_DATA_POINTS: {
          enforcePresentState();
          const openDiscoveries = _.cloneDeep(state.openDiscoveries);
          const activeDiscovery = openDiscoveries[action.discoveryId].present;

          const { pointData } = action;
          const { focusedDataPoints = [] } = activeDiscovery;

          // remove point if it exists in array and remove all that are not on same x value
          const pointDataSaved = _.pick(pointData, [
            'posX',
            'posY',
            'xAxis',
            'pathInfo',
          ]);
          const updatedFocusedDataPoints = _.reject(
            focusedDataPoints,
            _point => {
              return (
                _.matches(pointDataSaved)(_point) ||
                !_.isEqual(_point.posX, pointDataSaved.posX)
              );
            },
          );

          // add point if did not exist in array
          if (_.size(updatedFocusedDataPoints) === _.size(focusedDataPoints)) {
            updatedFocusedDataPoints.push(pointDataSaved);
          }

          openDiscoveries[action.discoveryId].present = {
            ...activeDiscovery,
            collectDetailInfoArgs: _.isEmpty(updatedFocusedDataPoints)
              ? null
              : action.collectDetailInfoArgs,
            focusedDataPoints: updatedFocusedDataPoints,
          };

          return {
            ...state,
            openDiscoveries,
          };
        }

        case Const.Discover.SET_FOCUSED_VIZ_DATA_POINTS: {
          enforcePresentState();
          const openDiscoveries = _.cloneDeep(state.openDiscoveries);
          const activeDiscovery = openDiscoveries[action.discoveryId].present;

          let { pointData: focusedDataPoints } = action;
          const { focusedData = [] } = activeDiscovery;

          if (!_.isEmpty(focusedData)) {
            // remove data points not in focusedData
            focusedDataPoints = _.filter(
              focusedDataPoints,
              _focusedDataPoint => {
                return _.some(focusedData, _pathInfo =>
                  _.isEqual(_focusedDataPoint.pathInfo, _pathInfo),
                );
              },
            );
          }

          openDiscoveries[action.discoveryId].present = {
            ...activeDiscovery,
            collectDetailInfoArgs: _.isEmpty(focusedDataPoints)
              ? null
              : action.collectDetailInfoArgs,
            focusedDataPoints,
          };

          return {
            ...state,
            openDiscoveries,
          };
        }

        default:
          // @NOTE: this if statement has side effects with other reducers see DSC-3794
          // Also @NOTE, at this point, no reducers have been 'triggered'
          if (action.discoveryId) {
            const discoveries = state.openDiscoveries;
            if (
              action.reduxTransactionId &&
              action.reduxTransactionId ===
                discoveries[action.discoveryId].activeReduxTransactionId
            ) {
              enforcePresentState();
              // do not add to the current stack
              discoveries[
                action.discoveryId
              ].present = OpenDiscoveryInternalReducer(
                state.openDiscoveries[action.discoveryId].present,
                action,
              );
            } else {
              const changedDiscovery = DiscoveryReducers[action.discoveryId](
                state.openDiscoveries[action.discoveryId],
                action,
              );

              // shallow equate
              if (changedDiscovery === discoveries[action.discoveryId]) {
                return state;
              }

              discoveries[action.discoveryId] = changedDiscovery;
            }

            // Notify ChartSpec of action. They have an opportunity to cancel the current action
            const chartType = _.get(
              discoveries[action.discoveryId].present,
              'viz.chartType',
            );
            if (chartType) {
              let { reduxTransactionId } = action;

              // Create a transaction id if not present so all action triggered from notifying ChartSpec are grouped
              if (!reduxTransactionId) {
                reduxTransactionId = shortid.generate();
                action = { ...action, reduxTransactionId };
              }

              // Put it back on the execution stack to avoid Redux errors from dispatching events in a reducer
              setTimeout(() =>
                ChartSpecs[chartType].onAction(
                  store.dispatch,
                  discoveries[action.discoveryId].present,
                  action,
                ),
              );
            }

            // Store current groupBy
            discoveries[action.discoveryId].activeReduxTransactionId =
              action.reduxTransactionId;

            return {
              ...state,
              openDiscoveries: { ...discoveries },
            };
          }
      }
    } catch (e) {
      console.warn(
        `Failed to execute ${action.type}. Returning current state.`,
        e,
      );
    }
    return state;
  },
  {
    monitors: [],
    fetching: false,
    fetched: false,
    error: null,
    deleting: false,
    deleted: false,
    showAddMonitorDialog: false,
    showSaveDialog: false,
    saveActive: false,
    openDiscoveries: {},
    openDiscoveriesTabList: [],
    displayDiscovery: null,
    queryResults: null,
    rangeSelection: '30d',
  },
  undefined,
);
