import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Column } from 'react-virtualized';
import { svgColorizePartial, font } from '../../common/emotion/mixins';
import * as UI from './ui';

export const DatasetPreviewTableColumn = styled(Column)({
  width: '100%',
  height: '100%',
});

export const datasetPreviewTableStyles = ({
  theme: {
    darkMode,
    colors: {
      PanelNavBackground,
      IconGreenColor,
      PanelNavIconColor,
      IconBlueColor,
      ChartLabelTextColor,
      HeaderGrey,
      ToolIconHoverColor,
      PanelIconActiveColor,
      TableBorderColor,
      TableBodyBackground,
      TableHeaderBackgroundDark,
      LightFontWeight,
      ToolbarIconFill,
      CorvanaMediumBorder,
      TableHeaderBackground,
      TableRowHover,
      TableHeaderTextColor,
      ContentText,
      SVGFillColor,
    } = {} as any,
  } = {} as any,
}) => css`
  .dataset-preview-table.ReactVirtualized__Table {
    color: ${ContentText};
  }
  .dataset-edit-dialog
    .field-settings-form
    .field-settings-form-group
    label.disabled-control-label {
    color: ${ToolbarIconFill} !important;
  }
  .dataset-preview-table__toolbar .nav-pills,
  .dataset-preview-table .nav-pills {
    display: flex;
    align-items: center;
  }
  .dataset-preview-table__toolbar .nav-pills {
    margin-right: 8px;
  }
  .dataset-preview-table__toolbar .nav-pills > li:not(:first-of-type),
  .dataset-preview-table .nav-pills > li:not(:first-of-type) {
    margin-left: 4px;
  }
  .dataset-preview-table__toolbar .nav-pills > li > a,
  .dataset-preview-table .nav-pills > li > a {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
  }
  .dataset-preview-table__toolbar .nav-pills > li > a {
    padding: 0;
  }
  .dataset-preview-table__toolbar .nav-pills > li > a:hover,
  .dataset-preview-table .nav-pills > li > a:hover,
  .dataset-preview-table__toolbar .nav-pills > li > a:focus,
  .dataset-preview-table .nav-pills > li > a:focus {
    background-color: ${PanelNavBackground};
  }
  .dataset-preview-table__toolbar .nav-pills > li > a:active,
  .dataset-preview-table .nav-pills > li > a:active,
  .dataset-preview-table__toolbar .nav-pills > li > a:visited,
  .dataset-preview-table .nav-pills > li > a:visited {
    background-color: transparent;
  }
  .dataset-preview-table__toolbar .nav-pills > li > a svg,
  .dataset-preview-table .nav-pills > li > a svg {
    pointer-events: none;
  }
  ${svgColorizePartial({
    fromColor: IconGreenColor,
    toColor: PanelNavIconColor,
  })('.dataset-preview-table .nav-pills > li > a svg')}
  ${svgColorizePartial({
    fromColor: IconBlueColor,
    toColor: PanelNavIconColor,
  })('.dataset-preview-table .nav-pills > li > a svg')}
${svgColorizePartial({ fromColor: SVGFillColor, toColor: PanelNavIconColor })(
  '.dataset-preview-table .nav-pills > li > a svg',
)}
${svgColorizePartial({
  fromColor: ChartLabelTextColor,
  toColor: PanelNavIconColor,
})('.dataset-preview-table .nav-pills > li > a svg')}

${svgColorizePartial({
  fromColor: IconGreenColor,
  toColor: ToolIconHoverColor,
})(`
  .dataset-preview-table__toolbar .nav-pills > li > a:hover svg,
  .dataset-preview-table .nav-pills > li > a:hover svg,
  .dataset-preview-table__toolbar .nav-pills > li > a:focus svg,
  .dataset-preview-table .nav-pills > li > a:focus svg
  `)}
${svgColorizePartial({
  fromColor: IconBlueColor,
  toColor: ToolIconHoverColor,
})(`
  .dataset-preview-table__toolbar .nav-pills > li > a:hover svg,
  .dataset-preview-table .nav-pills > li > a:hover svg,
  .dataset-preview-table__toolbar .nav-pills > li > a:focus svg,
  .dataset-preview-table .nav-pills > li > a:focus svg
`)}
${svgColorizePartial({
  fromColor: SVGFillColor,
  toColor: ToolIconHoverColor,
})(`
  .dataset-preview-table__toolbar .nav-pills > li > a:hover svg,
  .dataset-preview-table .nav-pills > li > a:hover svg,
  .dataset-preview-table__toolbar .nav-pills > li > a:focus svg,
  .dataset-preview-table .nav-pills > li > a:focus svg
  `)}
${svgColorizePartial({
  fromColor: PanelNavIconColor,
  toColor: ToolIconHoverColor,
})(`
  .dataset-preview-table__toolbar .nav-pills > li > a:hover svg,
  .dataset-preview-table .nav-pills > li > a:hover svg,
  .dataset-preview-table__toolbar .nav-pills > li > a:focus svg,
  .dataset-preview-table .nav-pills > li > a:focus svg
`)}
${svgColorizePartial({
  fromColor: ChartLabelTextColor,
  toColor: ToolIconHoverColor,
})(`
  .dataset-preview-table__toolbar .nav-pills > li > a:hover svg,
  .dataset-preview-table .nav-pills > li > a:hover svg,
  .dataset-preview-table__toolbar .nav-pills > li > a:focus svg,
  .dataset-preview-table .nav-pills > li > a:focus svg
`)}
.dataset-preview-table__toolbar .nav-pills > li.active > a,
.dataset-preview-table .nav-pills > li.active > a {
    background-color: ${PanelNavBackground};
  }

  ${svgColorizePartial({
    fromColor: IconGreenColor,
    toColor: PanelIconActiveColor,
  })(`
  .dataset-preview-table__toolbar .nav-pills > li.active > a svg,
  .dataset-preview-table .nav-pills > li.active > a svg
`)}
  ${svgColorizePartial({
    fromColor: IconBlueColor,
    toColor: PanelIconActiveColor,
  })(`
  .dataset-preview-table__toolbar .nav-pills > li.active > a svg,
  .dataset-preview-table .nav-pills > li.active > a svg
`)}
${svgColorizePartial({
  fromColor: SVGFillColor,
  toColor: PanelIconActiveColor,
})(`
  .dataset-preview-table__toolbar .nav-pills > li.active > a svg,
  .dataset-preview-table .nav-pills > li.active > a svg
`)}
${svgColorizePartial({
  fromColor: PanelNavIconColor,
  toColor: PanelIconActiveColor,
})(`
  .dataset-preview-table__toolbar .nav-pills > li.active > a svg,
  .dataset-preview-table .nav-pills > li.active > a svg
`)}
${svgColorizePartial({
  fromColor: ChartLabelTextColor,
  toColor: PanelIconActiveColor,
})(`
  .dataset-preview-table__toolbar .nav-pills > li.active > a svg,
  .dataset-preview-table .nav-pills > li.active > a svg
`)}

.ReactVirtualized__Table__headerRow {
    background: ${TableHeaderBackground};
    color: ${TableHeaderTextColor};
    border-top: 1px solid ${
      darkMode ? TableHeaderBackground : TableBorderColor
    };
    border-bottom: 1px solid ${
      darkMode ? TableHeaderBackground : TableBorderColor
    };
  }
  .ReactVirtualized__Table__row {
    background: ${TableBodyBackground};
    border-bottom: 1px solid ${TableBorderColor};
    display: flex;
  }
  .dataset-preview-table .ReactVirtualized__Table__row:not(.selected):hover {
    background-color: ${TableRowHover};
  }
  .ReactVirtualized__Table__row.selected {
    background: ${darkMode ? PanelNavBackground : TableHeaderBackgroundDark};
    overflow: visible !important;
  }
  .ReactVirtualized__Table__headerColumn:first-of-type,
  .ReactVirtualized__Table__rowColumn:first-of-type,
  .ReactVirtualized__Table__headerColumn,
  .ReactVirtualized__Table__rowColumn {
    margin: 0px;
  }
  .dataset-edit-dialog
    .ReactVirtualized__Table__row:not(:hover)
    .btn.dropdown-toggle:not(:focus)
    .caret,
  .dataset-edit-dialog
    .ReactVirtualized__Table__row:not(.selected):not(:hover)
    .actions-cell {
    display: none;
  }
  .dataset-edit-dialog .ReactVirtualized__Table__row.selected input:not(:hover),
  .dataset-edit-dialog .ReactVirtualized__Table__row:hover input:not(:hover) {
    background: transparent;
  }
  .dataset-edit-dialog
    .ReactVirtualized__Table__row:not(:hover)
    input:not(:focus) {
    box-shadow: none;
    border: 1px solid transparent;
  }
  .dataset-edit-dialog .btn.dropdown-toggle:not(:hover):not(:focus) {
    box-shadow: none;
    background: transparent;
    border: 1px solid transparent;
  }
  .dataset-edit-dialog input,
  .dataset-edit-dialog .dropdown {
    ${font({ size: '12px', weight: LightFontWeight })}
    width: 100%;
    height: 32px;
  }
  .dataset-edit-dialog .btn.dropdown-toggle {
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 8px !important;
    border: 1px solid ${CorvanaMediumBorder};
    box-shadow: none;
  }
  .dataset-edit-dialog .btn.dropdown-toggle:focus {
    background: transparent;
  }
  /* Preview List animation */
  @keyframes slide-in {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(218px);
    }
  }
  .dataset-preview-table__preview-list {
    animation: slide-in 0.2s reverse;
  }
  .dataset-preview-table__preview-list .field-settings-form {
    padding: 0 0.5em;
    overflow-y: scroll;
  }
  /* DataTypeDropdown overrides */
  .dataset-preview-table .data-type-dropdown__label,
  .dataset-preview-table__data-type-dropdown .data-type-dropdown__label,
  .dataset-preview-table__data-type-dropdown
    .data-type-dropdown__menuItem
    > div {
    ${font({ size: '12px', weight: LightFontWeight })}
  }
  .dataset-preview-table__data-type-dropdown
    .dropdown-menu.data-type-dropdown__menu
    li
    > a {
    padding: 4px 8px;
  }
  .data-type-dropdown__label.single {
    margin-left: 2px;
  }
  .dataset-preview-table__data-type-dropdown .btn.dropdown-toggle {
    padding-left: 2px !important;
  }
  .dataset-edit-dialog .annotation-grid .annotation_checkbox {
    padding: 0px;
    margin: 0px;
  }
  .dataset-edit-dialog .annotation-grid .form-control {
    height: 28px;
    padding: 2px;
  }
  .dataset-edit-dialog .annotation-grid .control-label,
  .dataset-edit-dialog .annotation-grid labelz {
    font-size: 14px;
  }
  .dataset-edit-dialog .annotation-grid .ReactVirtualized__Table__headerColumn,
  .dataset-edit-dialog .annotation-grid .ReactVirtualized__Table__rowColumn {
    border-left: 1px solid ${TableBorderColor};
  }
  .dataset-edit-dialog
    .annotation-grid
    .ReactVirtualized__Table__headerColumn:first-of-type,
  .dataset-edit-dialog
    .annotation-grid
    .ReactVirtualized__Table__rowColumn:first-of-type {
    border-left: none;
  }
  .dataset-edit-dialog .annotation-grid .ReactVirtualized__Table__headerRow {
    background-color: ${HeaderGrey};
  }
  .dataset-edit-dialog .dataset-editor-form-field {
    margin: 0;
    width: 100%;
    margin-top: 0.5rem;
  }
  .dataset-edit-dialog .dataset-editor-form-field label {
    margin-bottom: 0.2rem;
  }
  .dataset-edit-dialog .dataset-editor-form-field .checkbox {
    margin: 0;
  }
  .dataset-edit-dialog
    .dataset-editor-form-field
    .checkbox
    input[type='checkbox'] {
    width: auto;
    height: auto;
  }
  .dataset-edit-dialog .field-settings-form .field-settings-form-group {
    margin: 0;
    width: 100%;
    margin-top: 0.5rem;
  }
  .dataset-edit-dialog .field-settings-form .field-settings-form-group label {
    margin-bottom: 0.2rem;
  }
  .dataset-edit-dialog
    .field-settings-form
    .field-settings-form-group
    .checkbox {
    margin: 0;
  }
  .dataset-edit-dialog
    .field-settings-form
    .field-settings-form-group
    .checkbox
    input[type='checkbox'] {
    width: auto;
    height: auto;
  }
  .dataset-edit-dialog
    .field-settings-form
    .field-settings-form-group
    .input-group {
    margin: 0;
    width: 100%;
    margin-top: 0.5rem;
  }
  .dataset-edit-dialog
    .field-settings-form
    .field-settings-form-group
    .input-group
    label {
    margin-bottom: 0.2rem;
  }
  .dataset-edit-dialog
    .field-settings-form
    .field-settings-form-group
    .input-group
    .checkbox {
    margin: 0;
  }
  .dataset-edit-dialog
    .field-settings-form
    .field-settings-form-group
    .input-group
    .checkbox
    input[type='checkbox'] {
    width: auto;
    height: auto;
  }
`;

export const ToolBar = styled(UI.ActionToolBar)`
  flex-flow: row nowrap;
  width: 40%;

  ${UI.Label} {
    white-space: nowrap;
  }
`;
