import { useContext, useEffect, useMemo } from 'react';
import { Theme, ThemeProvider, useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import * as Colors from './colors.styles';
import * as DarkColors from './dark-colors.styles';
import { invertLuminance } from '../mixins';
import { useViewportDimensions } from '../../utilities/dimensions.hook';
import { isDashletUser as isDashletUserSelector } from '../../redux/selectors/AccountSelectors';
import { useIsDarkModeEnabled } from '../../redux/selectors/main-selector-hooks';
import { DiscoverModalContainerContext } from '../../discover-modal';

export interface IDiscoverEmotionTheme {
  mobileMaxWidth: number;
  appUrl: string;
  isDashletMode: boolean;
  isDashletUser: boolean;
  isMobile: boolean;
  darkMode: boolean;
  viewWidth: number;
  viewHeight: number;
  colors: typeof Colors;
}

export function useDiscoverThemeColors() {
  const { colors = {} as any } = useDiscoverTheme();
  return colors;
}

export function useDiscoverTheme() {
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const theme: IDiscoverEmotionTheme = useTheme() as any;
    return theme;
  } catch {
    return { colors: {} } as IDiscoverEmotionTheme;
  }
}

const invertColor = color => {
  if (
    (_.isString(color) && _.startsWith(color, '#')) ||
    _.startsWith(color, 'rgb')
  ) {
    return invertLuminance(color);
  } else if (_.isObject(color)) {
    return _.mapValues(color, c => invertColor(c));
  }
  return color;
};

export function EmotionThemeProviderWithoutDarkMode({ children }) {
  const { width: viewWidth, height: viewHeight } = useViewportDimensions();
  const container = useContext(DiscoverModalContainerContext);
  useEffect(() => {
    container?.style?.setProperty('--viewport-width', `${viewWidth}px`);
    container?.style?.setProperty('--viewport-height', `${viewHeight}px`);
  }, [container, viewHeight, viewWidth]);

  const {
    mobileMaxWidth,
    appUrl,
    isDashletMode,
    isMobile,
    isDashletUser,
  } = useSelector((state: any) => {
    const {
      main: { mobileMaxWidth, appUrl, isMobile },
      dashlet: { isDashletMode },
    } = state;

    return {
      isDashletUser: isDashletUserSelector(state.account),
      mobileMaxWidth,
      appUrl,
      isMobile,
      isDashletMode,
    };
  });

  const theme = useMemo(
    () => ({
      mobileMaxWidth,
      darkMode: false,
      appUrl,
      isDashletMode,
      viewWidth: 'var(--viewport-width)',
      isDashletUser,
      viewHeight: 'var(--viewport-height)',
      colors: Colors,
      isMobile,
    }),
    [appUrl, isDashletUser, isDashletMode, isMobile, mobileMaxWidth],
  );

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
export const DarkModeProvider = ({ children }) => {
  const isDarkModeEnabled = useIsDarkModeEnabled();
  const theme = useDiscoverTheme();

  const colors = useMemo(() => {
    if (!isDarkModeEnabled) {
      return Colors;
    }
    const merged = _.merge({}, invertColor(Colors), DarkColors);
    return merged;
  }, [isDarkModeEnabled]);

  return (
    <ThemeProvider
      theme={
        {
          ...theme,
          colors,
          darkMode: isDarkModeEnabled,
        } as Partial<Theme>
      }
    >
      {children}
    </ThemeProvider>
  );
};

export const DiscoverEmotionThemeProvider = ({ children }) => {
  return (
    <EmotionThemeProviderWithoutDarkMode>
      <DarkModeProvider>{children}</DarkModeProvider>
    </EmotionThemeProviderWithoutDarkMode>
  );
};

export const DiscoverThemeInjector = ({ children }) => {
  const theme = useDiscoverTheme();
  return <>{children(theme || ({ colors: {} } as any))}</>;
};
