import { Component } from 'react';
import Discover from '../redux/actions/DiscoverActions';
import { compose, onlyUpdateForKeys, pure } from 'react-recompose';
import { connect } from 'react-redux';
import palette from './ColorPalette';
import shortid from 'shortid';
import * as d3 from 'd3';
import { event as currentEvent } from 'd3-selection';
import _, {
  isFunction,
  isEqual,
  pick,
  round,
  get,
  isEmpty,
  isNil,
  map,
  some,
  find,
  omit,
  noop,
  forEach,
  every,
} from 'lodash';
import { Viz } from '../../discovery/VizUtil';
import Util from '../../common/Util';
import StackChartUtils from '../../discovery/charts/StackChartUtils';
import {
  DATA_FORMATTER,
  TRENDLINE_TOGGLE_SELECTOR,
  TRENDLINE_STRATEGY,
  TRENDLINE_OPTION_SELECTOR,
  whiteStripesFill,
} from '../../common/Constants';
import ColorManager from './ColorManager';
import classnames from 'classnames';
import { DefaultFontName } from '../../components/ui/fonts';
import { withTheme } from '@emotion/react';
import { withDiscoverRouter } from '../utilities/router.hoc';
import { LabelManagerConsumerHOC } from './label-manager-provider';
import {
  ReportLinkMaybeSideDrawerHOC,
  ScrollContext,
} from '../../discovery/charts/base-cartesian-chart';
import { getLinearRegression, getTrendlineIsVisible } from './utils/trendline';
import { VIZ_SELECTORS } from '../redux/selectors/viz-selectors';
import { withDiscoverOption } from '../../discovery/discovery-context/discovery.context'; // adds registerLabel prop
import { MediumRed } from '../../common/emotion/theme/colors.styles'; // color for trend line is not affected by system appearance

export const OUTSIDE_LABEL_WIDTH = 60;

class Stacks extends Component {
  static contextType = ScrollContext;

  labelClass = 'stackbar';

  componentDidMount() {
    this.handleStackEvents();
    this.handleFullStackEvents();
    this.addStripedBlock();
  }

  componentDidUpdate() {
    // we shouldn't be setting listeners on every update
    this.handleStackEvents();
    this.handleFullStackEvents();
    this.addStripedBlock();
  }
  getDataForD3() {
    const { data } = this.props;

    const stacks = data.reduce((accum, series) => {
      const {
        AVG_DAYS_IN_STAGE,
        NAME,
        TOTAL_RECORDS = 0,
        IS_COLOR_STACK = false,
      } = series;

      const stackTotals = this.getStackTotals(series.VALUES);

      series.VALUES.forEach(stack => {
        const dimensions = this.getStackDimensions(stack, stackTotals);
        accum.push({
          ...stack,
          dimensions,
          AVG_DAYS_IN_STAGE,
          NAME,
          TOTAL_RECORDS,
          IS_COLOR_STACK,
        });
      });
      return accum;
    }, []);
    return stacks;
  }
  handleStackEvents() {
    this.container = this._container.parentNode;
    const { onHover, showGlobalTooltip, handleStackEvents } = this.props;
    const data = this.getDataForD3();
    const hoverFunction = isFunction(handleStackEvents)
      ? handleStackEvents
      : onHover;
    d3.select(this.container)
      .selectAll(this.stackRectSelector)
      .data(data)
      .on('mouseout', () => {
        if (isFunction(hoverFunction)) {
          currentEvent.stopPropagation();
          const [xRelToContainer, yRelToContainer] = d3.mouse(this.container);

          const [x, y] = d3.mouse(currentEvent.target);
          const bounds = currentEvent.target.getBoundingClientRect();
          if (x > 0 && x < bounds.width && y > 0 && y < bounds.height) {
            // Were still over ignore
          } else {
            hoverFunction(null, xRelToContainer, yRelToContainer);
          }
        }
        d3.selectAll(this.stackRectSelector).classed('hover', false);
      })
      .on('mousemove', d => {
        if (isFunction(hoverFunction)) {
          currentEvent.stopPropagation();
          if (showGlobalTooltip) {
            return;
          }
          const [xRelToContainer, yRelToContainer] = d3.mouse(this.container);
          hoverFunction(d, xRelToContainer + 10, yRelToContainer + 10);
        }

        // lets 'hover' all stacks that represent the same data
        d3.selectAll(this.stackRectSelector).each(function(selectorData) {
          if (isEqual(pick(d, 'stackName'), pick(selectorData, 'stackName'))) {
            d3.select(this).classed('hover', true);
          }
        });
      })
      .on('click', d => {
        this.handleStackClick(d);
      });
  }

  stackRectSelector = `rect.${this.props.stackClassName}`;

  stripeClassName = 'stripeFill';

  deleteStripedElements(d3Elem) {
    const domNode = d3Elem.node();
    const stackDatum = d3Elem.datum();
    const sibling = domNode?.nextElementSibling;

    const getElemProp = (elem, prop) => {
      const attributes = elem?.attributes;
      return isNil(attributes) ? null : attributes?.getNamedItem(prop)?.value;
    };

    const needsUpdate = !every(['x', 'y', 'height', 'width'], prop =>
      isEqual(
        toString(get(stackDatum?.dimensions, prop)),
        getElemProp(sibling, prop),
      ),
    );

    if (!needsUpdate) {
      return false;
    }

    const parentSvg = domNode?.closest('svg');
    const stripeElements = parentSvg
      ? parentSvg.querySelectorAll(`.${this.stripeClassName}`)
      : [];
    forEach(stripeElements, elem => elem?.remove());
    return needsUpdate;
  }

  addStripedBlock() {
    d3.select(this.container)
      .selectAll(this.stackRectSelector)
      .filter(stackDatum => {
        const isSideDrawerHighlight =
          this.props.isInSideDrawer &&
          !isEmpty(this.props.reportDetailInfo) &&
          isEqual(
            this.selectReportDetailInfoProps(this.props.reportDetailInfo),
            this.selectReportDetailInfoProps(stackDatum?.reportDetailInfo),
          );

        return isSideDrawerHighlight;
      })
      .each((d, idx, selectionList) => {
        const currItem = d3.select(selectionList[idx]);

        // remove remaining stripes
        const needsStripe = this.deleteStripedElements(currItem);

        if (!needsStripe) {
          return;
        }

        const stripedElem = currItem.clone();
        stripedElem.attr('class', this.stripeClassName);
        stripedElem.attr('fill', whiteStripesFill);
      });
  }

  selectReportDetailInfoProps(reportDetailInfo) {
    const rAttributes = _.get(reportDetailInfo, 'attributes', []);
    const attrs = _.map(rAttributes, ({ attribute, value }) => [
      attribute?.name,
      value,
    ]);
    return attrs;
  }

  handleStackClick(item) {
    currentEvent.stopPropagation();
    // Toggle selection
    if (this.props.enableReportLink) {
      this.props.openReportLink(item.reportDetailInfo);
    } else if (this.props.hasSideDrawerDrill) {
      this.props.setReportDetailInfo(item.reportDetailInfo);
    } else {
      this.props.setFocusedData(pick(item, 'stackName'));
    }
  }

  handleFullStackEvents() {
    this.container = this._container.parentNode;
    const {
      onHover,
      showGlobalTooltip,
      data,
      handleFullStackEvents,
    } = this.props;
    const hoverFunction = isFunction(handleFullStackEvents)
      ? handleFullStackEvents
      : onHover;
    d3.select(this.container)
      .selectAll('path.aside')
      .data(data)
      .on('mouseout', () => {
        if (isFunction(hoverFunction)) {
          currentEvent.stopPropagation();
          const [xRelToContainer, yRelToContainer] = d3.mouse(this.container);

          const [x, y] = d3.mouse(currentEvent.target);
          const bounds = currentEvent.target.getBoundingClientRect();
          if (x > 0 && x < bounds.width && y > 0 && y < bounds.height) {
            // Were still over ignore
          } else {
            hoverFunction(null, xRelToContainer, yRelToContainer);
          }
        }
      })
      .on('mousemove', d => {
        if (isFunction(hoverFunction)) {
          currentEvent.stopPropagation();
          if (showGlobalTooltip) {
            return;
          }
          // get a tooltip for one of the slices of the stack and remove the specifics
          const [xRelToContainer, yRelToContainer] = d3.mouse(this.container);
          if (!isNil(d)) {
            hoverFunction(d, xRelToContainer + 10, yRelToContainer + 10);
          }
        }
      });
  }

  getChartDisplayWidth() {
    const {
      stackXOffset,
      width: chartWidth,
      totalsLabelPosition,
      isHorizontal,
      isMobile,
      showLabels,
    } = this.props;
    let width = chartWidth - stackXOffset;
    if (
      showLabels &&
      isHorizontal &&
      totalsLabelPosition === TOTALS_LABEL_POSITION.OUTSIDE &&
      !isMobile
    ) {
      width -= OUTSIDE_LABEL_WIDTH;
    }
    return width;
  }

  getIsPercentage() {
    const { customFormatToggles } = this.props;
    const percentageToggle = customFormatToggles
      ? customFormatToggles.find(t => t.key === 'asPercentage')
      : null;
    return percentageToggle?.on ?? false;
  }

  getStackDimensions(stackInfo, stackTotals) {
    const {
      isHorizontal,
      xScale,
      yScale,
      isCenter,
      width: chartWidth,
    } = this.props;
    let width;
    let height;
    let x;
    let y;
    let bandwidth;
    let { top } = stackInfo;
    let { bottom } = stackInfo;

    const isPercentage = this.getIsPercentage();

    if (isPercentage) {
      top = StackChartUtils.getStackPercentage(stackInfo.top, stackTotals);
      bottom = StackChartUtils.getStackPercentage(
        stackInfo.bottom,
        stackTotals,
      );
    }
    const widthAdjust = this.props.stackXOffset;
    if (isHorizontal) {
      bandwidth = yScale.bandwidth();
      height = Math.min(bandwidth, 200);
      width = Math.min(
        this.getChartDisplayWidth(),
        Math.abs(xScale(top) - xScale(bottom)),
      );
      y = bandwidth > height ? (bandwidth - height) / 2 : 0;
      x = xScale(bottom);
      if (isCenter) {
        const remainingSpace = this.getChartDisplayWidth();
        const fullStackWidth = Math.min(
          xScale(stackTotals.positive),
          remainingSpace,
        );
        x += remainingSpace / 2 - fullStackWidth / 2;
      }
    } else {
      bandwidth = xScale.bandwidth();
      width = Math.min(bandwidth, 200);
      height = Math.abs(yScale(bottom) - yScale(top));
      x = bandwidth > width ? (bandwidth - width) / 2 : 0;
      y = yScale(top);
    }
    const roundPrecision = 2;
    return {
      height: round(height, roundPrecision),
      width: round(width, roundPrecision),
      chartWidth: round(chartWidth, roundPrecision),
      x: round(x + widthAdjust, roundPrecision),
      y: round(y, roundPrecision),
      bandwidth: round(bandwidth, roundPrecision),
    };
  }

  getBestValueToDisplay(value, stackWidth, formatter, customFormat) {
    return Viz.formatNumberToFit(
      value,
      stackWidth,
      formatter,
      this.props?.i18nPrefs,
      customFormat,
    );
  }

  renderTotalLabelsOutside(stacks) {
    const {
      showLabels,
      isHorizontal,
      yScale,
      totalsLabelPosition,
      isMobile,
      width: chartWidth,
      stackXOffset,
      groupScale,
    } = this.props;
    const width = chartWidth - stackXOffset;
    if (
      showLabels &&
      !isMobile &&
      isHorizontal &&
      totalsLabelPosition === TOTALS_LABEL_POSITION.OUTSIDE
    ) {
      const labels = stacks.map((stack, i) => {
        const seriesData = isHorizontal ? this.getY(stack) : this.getX(stack);
        const stackTotals = this.getStackTotals(stack.VALUES);
        const focused = true;
        const formatter = get(
          stack.VALUES[0],
          'formatter',
          DATA_FORMATTER.WHOLE_NUMBER,
        );

        return (
          <text
            key={`stack-total-text${i}`}
            className={classnames(
              { dim: !focused },
              'stack-total',
              'stack-total-outside',
            )}
            x={0}
            y={groupScale(seriesData) + ((yScale.bandwidth() ?? 0) / 2 + 7)}
          >
            {formatter.formatSmall(
              stackTotals.positive + stackTotals.negative,
              this.props?.i18nPrefs,
            )}
          </text>
        );
      });
      return (
        <g
          key={`stack-totals-outside-${shortid.generate()}`}
          className={'stack-totals-outside'}
          transform={`translate(${width + 8}, 0)`}
        >
          {labels}
        </g>
      );
    }
  }

  getStackTotals(stackData) {
    return StackChartUtils.getStackTotals(stackData);
  }

  registerStackTotalLabels(series) {
    const {
      isHorizontal,
      xScale,
      yScale,
      height,
      totalsLabelPosition,
    } = this.props;
    const { VALUES: stackData, XAXIS } = series;
    const width = this.getChartDisplayWidth();
    const stackCount = stackData.length;
    const stackTotals = this.getStackTotals(stackData);
    if (stackTotals.positive > 0) {
      // determine how much space we have to display the label outside of the stack
      const data = {
        ...stackData[stackData.length - 1],
        total: stackTotals.positive,
        ...addAdditional(
          () => {
            return (
              isHorizontal &&
              totalsLabelPosition === TOTALS_LABEL_POSITION.INSIDE
            );
          },
          {},
          {
            bottom: stackTotals.positive,
            top: isHorizontal ? xScale.invert(width) : yScale.invert(0),
          },
        ),
      };
      this.registerStackLabels(
        { VALUES: [data], XAXIS },
        stackTotals,
        stackCount,
        true,
      );
    }
    if (stackTotals.negative < 0) {
      const data = {
        ...stackData[stackData.length - 1],
        total: stackTotals.negative,
        ...addAdditional(
          () => {
            return (
              isHorizontal &&
              totalsLabelPosition === TOTALS_LABEL_POSITION.INSIDE
            );
          },
          {},
          {
            bottom: stackTotals.negative,
            top: isHorizontal ? xScale.invert(0) : yScale.invert(height),
          },
        ),
      };
      this.registerStackLabels(
        { VALUES: [data], XAXIS },
        stackTotals,
        stackCount,
        true,
      );
    }
  }

  getFormattedValueFromLabelData(registeredLabelData) {
    const { isMobile, i18nPrefs } = this.props;

    const {
      value,
      formatter,
      meta: { stackDatum, stackTotals, isTotal, customFormatProps },
    } = registeredLabelData;

    const dims = this.getStackDimensions(stackDatum, stackTotals);
    const isPercentage = this.getIsPercentage();

    let formattedValue = value;
    if (isTotal && isMobile) {
      formattedValue = formatter?.formatSmall(
        value,
        i18nPrefs,
        customFormatProps,
      );
    } else {
      formattedValue = this.getBestValueToDisplay(
        value,
        dims.width - 32,
        isPercentage ? DATA_FORMATTER.PERCENT : formatter,
        customFormatProps,
      );
    }
    return formattedValue;
  }

  getPositionedLabelInfo(registeredLabelData) {
    const {
      isHorizontal,
      yScale: y,
      getStackColor,
      height = 0,
      theme,
      colorPalette,
      getStack,
      groupScale,
    } = this.props;

    const {
      meta: { stackDatum, stackTotals, isTotal, series },
    } = registeredLabelData;

    const formattedValue = this.getFormattedValueFromLabelData(
      registeredLabelData,
    );

    const { top, bottom } = stackDatum;
    const stackName = getStack(stackDatum);
    const isPercentage = this.getIsPercentage();
    const { positive = 0, negative = 0 } = stackTotals;
    const chartTotalsRange = Math.abs(positive - negative);
    const yScaleMeasure = isPercentage ? top / chartTotalsRange : top;

    const dims = this.getStackDimensions(stackDatum, stackTotals);

    const { height: containerHeight, width: containerWidth } = dims;
    let xPos = 0,
      yPos = 0,
      hideLabel = false;
    const textWidth = Util.calcTextWidth(
      formattedValue,
      `300 10px ${DefaultFontName}`,
    );

    const cp = isEmpty(colorPalette) ? [...palette] : [...colorPalette];
    const colors = d3.scaleOrdinal().range(cp);
    const stackColor = isFunction(getStackColor)
      ? getStackColor(stackDatum, colors(stackName))
      : colors(stackName);
    let labelTextColor = Viz.getContrastColor(stackColor, theme);

    if (
      containerHeight > 14 &&
      !isNil(formattedValue) &&
      containerWidth >= 22
    ) {
      // center the label in the stack by default
      xPos = dims.x + containerWidth / 2;
      yPos = isHorizontal
        ? dims.bandwidth / 2 + 3
        : y(yScaleMeasure) + containerHeight / 2 + 4;
      if (isTotal) {
        // place the label a few pixels away
        if (isHorizontal) {
          if (textWidth + 32 > containerWidth) {
            hideLabel = true;
          }
          if (this.props.totalsLabelPosition === TOTALS_LABEL_POSITION.INSIDE) {
            labelTextColor = stackColor;
            xPos = dims.x + containerWidth - textWidth / 2 - 16;
          } else {
            labelTextColor = theme?.colors?.Black;
            xPos =
              bottom > 0
                ? dims.x + textWidth / 2 + 8
                : dims.x - textWidth / 2 - 8;
          }
        } else {
          labelTextColor = theme?.colors?.Black;
          yPos =
            bottom > 0 ? containerHeight - 8 : height - containerHeight + 16;
        }
      }

      if (textWidth + 2 > containerWidth) {
        hideLabel = true;
      }
    } else {
      hideLabel = true;
    }

    if (isHorizontal) {
      yPos += groupScale(this.getY(series)) ?? 0;
    } else {
      xPos += groupScale(this.getX(series)) ?? 0;
    }

    const { leftPct = 1, topPct = 0, offscreenWidth = 0, offscreenHeight = 0 } =
      this.context ?? {};
    const widthScrollOffset = leftPct * offscreenWidth;
    const heightScrollOffset = topPct * offscreenHeight;

    yPos -= heightScrollOffset;
    xPos -= widthScrollOffset;

    return {
      xPos,
      yPos,
      labelTextColor,
      hideLabel,
    };
  }

  renderLabel(registeredLabelData, d3Selection) {
    const { focused } = registeredLabelData;

    if (isNil(d3Selection)) {
      return;
    }

    const { labelTextColor, hideLabel } = this.getPositionedLabelInfo(
      registeredLabelData,
    );

    const formattedValue = this.getFormattedValueFromLabelData(
      registeredLabelData,
    );

    if (hideLabel || isNil(formattedValue)) {
      return;
    }

    d3Selection
      .append('text')
      .attr('fill', labelTextColor)
      .attr('text-anchor', 'middle')
      .style('fill', labelTextColor)
      .style('font-weight', '300')
      .style('font-style', 'inherit')
      .style('font-size', '10px')
      .style('fill-opacity', focused ? '1' : '0.16')
      .text(formattedValue)
      .on('click', ({ meta: { stackDatum } }) => {
        this.handleStackClick(stackDatum);
      });
  }

  registerStackLabels(series, stackTotals, stackCount, isTotal = false) {
    const {
      customFormatProps,
      registerLabel,
      height: renderingContainerHeight,
      focusedData,
    } = this.props;
    const stackData = series?.VALUES;

    const registerLabelInfo = map(stackData, stackDatum => {
      const { top, bottom, total, formatter } = stackDatum;
      const customFormat = customFormatProps[stackDatum?.valueColName];
      const value = isTotal ? total : top - bottom;

      const isSecondaryPlot = false;

      const renderLabel = (registeredLabelData, d3Selection) =>
        this.renderLabel(registeredLabelData, d3Selection);

      let focused =
        isEmpty(focusedData) ||
        some(focusedData, pick(stackDatum, 'stackName'));
      if (isTotal && stackCount > 1 && !isEmpty(focusedData)) {
        focused = false;
      }
      const meta = {
        customFormatProps: customFormat,
        isTotal,
        series,
        stackDatum,
        stackTotals,
        stackCount,
      };

      const isPercentage = this.getIsPercentage();
      const percentFormatter = DATA_FORMATTER.PERCENT;

      const labelValue = !isPercentage
        ? value
        : StackChartUtils.getStackPercentage(
            stackDatum.top - stackDatum.bottom,
            stackTotals,
          );

      const registeringLabelData = {
        value: labelValue,
        anchor: {},
        isSecondaryPlot,
        formatter: isPercentage ? percentFormatter : formatter,
        renderLabel,
        focused,
        renderingContainerHeight,
        labelClass: this.labelClass,
        meta,
      };

      const { xPos, yPos } = this.getPositionedLabelInfo(registeringLabelData);

      return {
        ...registeringLabelData,
        anchor: {
          x: xPos,
          y: yPos,
        },
      };
    });

    isFunction(registerLabel) && registerLabel(registerLabelInfo);
  }

  getY(d) {
    const { getY } = this.props;
    return isFunction(getY) ? getY(d) : StackChartUtils.getY(d);
  }

  getX(d) {
    const { getX } = this.props;
    return isFunction(getX) ? getX(d) : StackChartUtils.getX(d);
  }

  layoutLabels() {
    const {
      showLabels,
      resetLabelClass,
      isCenter,
      isMobile,
      totalsLabelPosition,
      data = [],
    } = this.props;
    const isPercentage = this.getIsPercentage();
    isFunction(resetLabelClass) && resetLabelClass(this.labelClass);

    if (showLabels) {
      const isFunnel = isCenter;
      // funnel sets the isCenter property. in that case, we will want the stack labels to be inside the box

      data.forEach(series => {
        const stackTotals = this.getStackTotals(series.VALUES);
        const stackCount = series.VALUES.length;
        if (
          !isFunnel &&
          !isPercentage &&
          totalsLabelPosition !== TOTALS_LABEL_POSITION.OUTSIDE
        ) {
          this.registerStackTotalLabels(series);
        }
        if (
          series.VALUES.length > 1 ||
          isMobile ||
          (isFunnel &&
            series.VALUES.length === 1 &&
            totalsLabelPosition !== TOTALS_LABEL_POSITION.OUTSIDE)
        ) {
          this.registerStackLabels(series, stackTotals, stackCount, false);
        }
      });
    }
  }

  render() {
    const {
      className,
      data,
      groupScale,
      stackClassName,
      isHorizontal,
      height,
      vizId,
      getStackColor,
      focusedData,
      totalsLabelPosition,
      theme,
      stackXOffset,
      yScale,
      xScale,
      hasTrendline,
      trendlineStrategy,
    } = this.props;
    this.layoutLabels();
    const isPercentage = this.getIsPercentage();

    const trendLines = [];

    if (
      !isPercentage &&
      hasTrendline &&
      trendlineStrategy === TRENDLINE_STRATEGY.LINEAR &&
      !isHorizontal &&
      isEmpty(focusedData)
    ) {
      const pointData = map(data, series => {
        const stackTotal = this.getStackTotals(series.VALUES);
        const total = stackTotal.positive + stackTotal.negative;
        const xData = this.getX(series);
        const yData = this.getY(series);
        const seriesData = isHorizontal ? yData : xData;
        const groupScalePosition = groupScale(seriesData) ?? 0;

        const groupPosition = groupScalePosition + groupScale.bandwidth() / 2;
        const totalScale = isHorizontal ? xScale : yScale;
        const metricPosition = totalScale(total);

        return {
          x: isHorizontal ? metricPosition : groupPosition,
          y: isHorizontal ? groupPosition : metricPosition,
        };
      });

      const lineEnds = getLinearRegression({
        pointData,
        endMargin: groupScale.bandwidth(),
      });

      const isTrendlineVisible = getTrendlineIsVisible({
        boundaryPoints: lineEnds,
        chartHeight: height,
      });

      if (isTrendlineVisible) {
        trendLines.push(
          <path
            className={'trendline'}
            d={d3.line()(lineEnds)}
            stroke={MediumRed}
            strokeWidth={2}
          />,
        );
      }
    }

    const stackedBars = data.map((series, i) => {
      const stackTotals = this.getStackTotals(series.VALUES);

      const stacks = series.VALUES.reduce((seriesStacks, s, idx) => {
        const { stackName } = s;
        const stackColor = ColorManager.getColor(vizId, stackName, theme);
        const dims = this.getStackDimensions(s, stackTotals);
        const focused =
          isEmpty(focusedData) || some(focusedData, pick(s, 'stackName'));
        seriesStacks.push(
          <rect
            key={`stack-unit-${i}-${idx}`}
            {...omit(dims, ['chartWidth', 'bandwidth'])}
            fill={
              isFunction(getStackColor)
                ? getStackColor(s, stackColor)
                : stackColor
            }
            className={classnames(stackClassName, { dim: !focused })}
          />,
        );
        if (idx === series.VALUES.length - 1) {
          const remainderDims = {
            x: isHorizontal ? stackXOffset : dims.x,
            y: isHorizontal ? dims.y : 0,
            width: isHorizontal ? this.getChartDisplayWidth() : dims.width,
            height: isHorizontal ? dims.height : height,
            bandwidth: dims.bandwidth,
          };
          const background = (
            <rect
              key={`background-${i}-${idx}`}
              {...remainderDims}
              className={'stack-bg'}
            />
          );
          seriesStacks = [background, ...seriesStacks];
        }
        return seriesStacks;
      }, []);

      const seriesData = isHorizontal ? this.getY(series) : this.getX(series);
      const groupScalePosition = groupScale(seriesData) ?? 0;
      const groupTranslation = isHorizontal
        ? `translate(0, ${groupScalePosition})`
        : `translate(${groupScalePosition}, 0)`;
      return (
        <g transform={groupTranslation} key={`stacks-totals-${i}`}>
          {stacks}
        </g>
      );
    });
    return (
      <g
        key={`stacks-container}`}
        className={`${className} captureMouseEvents`}
        ref={el => {
          this._container = el;
        }}
      >
        {stackedBars}
        {isHorizontal &&
          totalsLabelPosition === TOTALS_LABEL_POSITION.OUTSIDE &&
          this.renderTotalLabelsOutside(data)}
        {trendLines}
      </g>
    );
  }
}

export const TOTALS_LABEL_POSITION = {
  // For vertical stack, this is above/below the whole stack. For horizontal, this is to the right/left of the whole stack.
  DEFAULT: 'default',

  // Applies only to horizontal stacks. Displays the label inside the last stack item on the right side.
  INSIDE: 'inside',

  // Applies only to horizontal stacks. Displays the totals aligned to the right of the chart area.
  OUTSIDE: 'outside',
};

Stacks.defaultProps = {
  stackClassName: 'stack',
  showLabels: false,
  colorPalette: palette,
  focus: noop,
  focusedData: [],
  isHorizontal: false,
  stackXOffset: 0,
  totalsLabelPosition: TOTALS_LABEL_POSITION.DEFAULT,
  i18nPrefs: {},
};

export default compose(
  pure,
  withDiscoverRouter,
  withTheme,
  LabelManagerConsumerHOC,
  ReportLinkMaybeSideDrawerHOC,
  withDiscoverOption({
    option: TRENDLINE_OPTION_SELECTOR,
    defaultValue: TRENDLINE_STRATEGY.LINEAR,
  }),
  connect(
    (state, ownProps) => {
      const vizFormatToggles = VIZ_SELECTORS.getCustomFormatToggles(state, {
        discoveryId: ownProps.vizId,
      });
      const trendlineOption = find(vizFormatToggles, {
        key: TRENDLINE_TOGGLE_SELECTOR,
      });
      const hasTrendline = !!trendlineOption?.on;

      const { i18nPrefs = {} } = state?.account?.currentUser;

      return {
        showGlobalTooltip: state.main.controlDown,
        i18nPrefs,
        hasTrendline,
      };
    },
    (dispatch, ownProps) => ({
      openReportLink(reportDetailInfo) {
        dispatch(Discover.openReportLink(reportDetailInfo, ownProps.history));
      },
      setFocusedData(dataItem) {
        if (isFunction(ownProps.focus)) {
          ownProps.focus();
        }
        dispatch(Discover.setFocusedVizData(ownProps.vizId, dataItem));
      },
      setReportDetailInfo(reportDetailInfo) {
        dispatch(Discover.setReportDetailInfo(reportDetailInfo));
      },
    }),
  ),
  onlyUpdateForKeys([
    'focusedData',
    'data',
    'showGlobalTooltip',
    'height',
    'width',
  ]),
)(Stacks);

const addAdditional = (testObjs, trueObj = {}, falseObj = {}) => {
  const result = testObjs(trueObj, falseObj);
  if (result === true) {
    return trueObj;
  } else if (result === false) {
    return falseObj;
  }
  return result;
};
