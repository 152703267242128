import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useDiscoverTheme } from '../../../emotion/theme';
const maxDialogHeight = '750px';

export const ModalPanel = styled.div<{ zIndex?: number }>(
  ({ zIndex = 1050 }) => {
    const { darkMode } = useDiscoverTheme();

    return css`
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: ${zIndex};
      display: flex;
      flex-direction: column;

      .dialog-backdrop {
        z-index: ${zIndex};
      }
      .modal-backdrop.in {
        opacity: ${darkMode && 0.8};
      }

      .user-input-dialog .scrollable {
        max-height: 650px;
        overflow: auto;
        padding: 0 15px;
      }
      @media (max-width: 767px) {
        .user-input-dialog {
          width: 100%;
          max-height: 100%;
        }
      }
    `;
  },
);

export const StyledModalPanel = styled(ModalPanel)<{ zIndex?: number }>(
  ({ zIndex = 1050 }) => {
    const {
      colors: { ModalBackground },
    } = useDiscoverTheme();

    return css`
      background-color: ${ModalBackground};
      z-index: ${zIndex};
      display: flex;
      overflow-y: scroll;
    `;
  },
);

export const ModalTitle = styled.h4(() => {
  const {
    colors: { SemiboldFontWeight },
  } = useDiscoverTheme();

  return css`
    margin: 0;
    font-weight: ${SemiboldFontWeight};
    font-style: inherit;
    font-size: 18px;
    line-height: 1;
    padding: 0.8rem 1rem 0.6rem;
  `;
});

export const ModalContent = styled.div<{ zIndex?: number }>(
  ({ zIndex = 1050 }) => {
    const {
      colors: { ModalBackground, ModalBackgroundBorder },
    } = useDiscoverTheme();

    return css`
      display: flex;
      flex-direction: column;
      background-color: ${ModalBackground};
      border: 1px solid ${ModalBackgroundBorder};
      width: 850px;
      max-height: ${maxDialogHeight};
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) !important;
      z-index: ${zIndex};
      border-radius: 8px;
    `;
  },
);

export const ModalBody = styled.div`
  max-height: calc(750px - 90px);
`;
