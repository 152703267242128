import { Component } from 'react';
import SelectItemsSvg from '../../../images/sdd/icon_select_items.svg';
import SetConditionSvg from '../../../images/sdd/icon_set_condition.svg';
import SelectStringItems from './SelectStringItems';
import { createFilterForField, StringFilterSubTypes } from './Filter';
import { isEqual, isNil } from 'lodash';
import StringCondition from './StringCondition';
import StringConditionPreview from './StringConditionPreview';
import { TextSearchField } from '../../components/ui/form';
import CorvanaCheckbox from '../../common/widgets/CorvanaCheckbox';
import { messages } from '../../i18n';
import { IFilter } from '../../datasets/interfaces/filter.interface';
import { IAnyAttribute } from '../../datasets';
import { useFilterDialogContext } from './filter-dialog/filter-dialog.context';
import { TransitionGroup } from 'react-transition-group';
import Fade from '@mui/material/Fade';
import { FilterTypeNav, FilterTypeNavItem } from './filter-type-nav';

interface IPropTypes {
  activeFilter: IFilter;
  field: IAnyAttribute;
  changeFilter: (filter: IFilter) => void;
  filter: IFilter;
  vizId: string;
  vizCalcs: any[];
}

class StringFilterUnconnected extends Component<
  IPropTypes,
  // Note: state can contain any of the filter subtypes, we should look at refactoring.
  any // { search?: any; applyCurrentVizFilters: any }
> {
  constructor(props) {
    super(props);
    const filter = { ...props.filter };
    const subType =
      isNil(filter) || isNil(filter.subType) ? '' : filter.subType;
    this.state = {
      [StringFilterSubTypes.SELECT_ITEMS]:
        subType === StringFilterSubTypes.SELECT_ITEMS ? filter : null,
      [StringFilterSubTypes.SET_CONDITION]:
        subType === StringFilterSubTypes.SET_CONDITION ? filter : null,
      [StringFilterSubTypes.TOP_BOTTOM]:
        subType === StringFilterSubTypes.TOP_BOTTOM ? filter : null,
      applyCurrentVizFilters: true,
      selectedTab: subType ? subType : StringFilterSubTypes.SET_CONDITION,
    };
  }
  componentDidUpdate(prevProps) {
    if (
      !isNil(this.props.activeFilter) &&
      !isEqual(
        prevProps?.activeFilter?.subType,
        this.props.activeFilter?.subType,
      )
    ) {
      // keep track of the previous filter
      this.setState({
        [this.props.activeFilter.subType]: { ...this.props.activeFilter },
      });
    }
  }

  componentDidMount() {
    this._allowVerticalFlexFillContentArea(true);
  }

  onTabChange(key) {
    let currentFilter = this.state[key];
    if (isNil(currentFilter)) {
      const f = createFilterForField(this.props.field);
      f.subType = key;
      currentFilter = f;
      this.setState({ [key]: { ...f } });
    }
    this.props.changeFilter(currentFilter);
    this._allowVerticalFlexFillContentArea(
      key !== StringFilterSubTypes.TOP_BOTTOM,
    );
  }

  _allowVerticalFlexFillContentArea(allow = false) {
    if (allow) {
      document.querySelector('.string-filter').classList.add('grow');
    } else {
      document.querySelector('.string-filter').classList.remove('grow');
    }
  }

  handleChange(name, value) {
    const obj = {};
    obj[name] = value;
    this.setState(() => {
      return obj;
    });
  }

  onClearSearch() {
    this.setState(() => {
      return { search: '' };
    });
  }

  handleCheck(e) {
    const { name } = e.target;
    const obj = {};
    obj[name] = !this.state[name];
    this.setState(obj);
  }
  renderPanel(activeKey) {
    const { filter, field, vizId, vizCalcs } = this.props;
    let info = { total: 0, selected: 0 };

    if (this.props.activeFilter && this.props.activeFilter.info) {
      info = this.props.activeFilter.info;
    }

    switch (activeKey) {
      case StringFilterSubTypes.SELECT_ITEMS:
        return (
          <Fade key={StringFilterSubTypes.SELECT_ITEMS} timeout={500}>
            <div className='tab-content'>
              <div className='section-label'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ whiteSpace: 'nowrap' }}>
                    {messages.filters.itemList}
                  </div>
                  <TextSearchField
                    style={{ margin: '0px 16px 0px 20px' }}
                    placeholder={messages.filters.searchFieldsPlaceholder}
                    value={this.state.search}
                    onChange={this.handleChange.bind(this, 'search')}
                    large
                  />
                  <CorvanaCheckbox
                    checked={this.state.applyCurrentVizFilters}
                    onChange={e =>
                      this.setState({
                        applyCurrentVizFilters: e.currentTarget.checked,
                      })
                    }
                  >
                    {messages.filters.applyReportFiltersToList}
                  </CorvanaCheckbox>
                </div>
                {this.props.activeFilter && (
                  <div className='info'>
                    {messages.formatString(
                      messages.filters.selectionInfo,
                      <strong>{info.selected}</strong>,
                      <strong>{info.total}</strong>,
                    )}
                  </div>
                )}
              </div>
              <div className='filter-panel item-list'>
                {isEqual(activeKey, StringFilterSubTypes.SELECT_ITEMS) &&
                this.state.applyCurrentVizFilters ? (
                  <SelectStringItems
                    key={'withFilteredSelection'}
                    search={this.state.search}
                    filter={filter}
                    field={field}
                    vizId={vizId}
                    vizCalcs={vizCalcs}
                  />
                ) : (
                  <SelectStringItems
                    key={'withUnfilteredSelection'}
                    search={this.state.search}
                    filter={filter}
                    field={field}
                    vizId={vizId}
                    vizCalcs={vizCalcs}
                    applyCurrentVizFilters={false}
                  />
                )}
              </div>
            </div>
          </Fade>
        );
      case StringFilterSubTypes.SET_CONDITION:
        return (
          <Fade key={StringFilterSubTypes.SET_CONDITION} timeout={500}>
            <div className='tab-content'>
              <div className='section-label'>
                {messages.filters.setCondition}
              </div>
              <div className='filter-panel filter-condition'>
                {isEqual(activeKey, StringFilterSubTypes.SET_CONDITION) && (
                  <StringCondition
                    filter={filter}
                    field={field}
                    vizId={vizId}
                  />
                )}
              </div>
              <div className='section-label'>
                {messages.filters.previewSelections}
                <CorvanaCheckbox
                  checked={this.state.applyCurrentVizFilters}
                  onChange={e =>
                    this.setState({
                      applyCurrentVizFilters: e.currentTarget.checked,
                    })
                  }
                >
                  {messages.filters.applyReportFiltersToPreview}
                </CorvanaCheckbox>
              </div>
              <div className='filter-panel filter-preview'>
                {isEqual(activeKey, StringFilterSubTypes.SET_CONDITION) && (
                  <StringConditionPreview
                    vizId={vizId}
                    field={field}
                    filter={filter}
                    vizCalcs={vizCalcs}
                    applyCurrentVizFilters={this.state.applyCurrentVizFilters}
                  />
                )}
              </div>
            </div>
          </Fade>
        );

      default:
        return null;
    }
  }

  render() {
    const { filter } = this.props;
    const activeKey = filter?.subType
      ? filter.subType
      : StringFilterSubTypes.SELECT_ITEMS;

    return (
      <div
        id='string-filter-tab-container'
        className='filter-content string-filter'
      >
        <div className='section-label'>{messages.filters.selectFilterType}</div>
        <FilterTypeNav
          value={activeKey}
          onChange={(e, key) => this.onTabChange(key)}
          className='aggregate-filter-tabs'
        >
          <FilterTypeNavItem
            value={StringFilterSubTypes.SELECT_ITEMS}
            icon={<SelectItemsSvg />}
            label={messages.filters.selectItems}
          />
          <FilterTypeNavItem
            value={StringFilterSubTypes.SET_CONDITION}
            icon={<SetConditionSvg />}
            label={messages.filters.setACondition}
          />
        </FilterTypeNav>
        <TransitionGroup
          exit={false}
          style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
        >
          {this.renderPanel(activeKey)}
        </TransitionGroup>
      </div>
    );
  }
}

export const StringFilter = ({ vizId }) => {
  const { field, filter, changeFilter, vizCalcs } = useFilterDialogContext();

  return (
    <StringFilterUnconnected
      vizId={vizId}
      field={field}
      filter={filter}
      activeFilter={filter}
      changeFilter={changeFilter}
      vizCalcs={vizCalcs}
    />
  );
};
