import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useDiscoverTheme } from '../../../common/emotion/theme';

export const useTabStyles = () => {
  const {
    colors: { Gray50, Gray40 },
  } = useDiscoverTheme();

  return {
    padding: 0,
    minWidth: '40px',
    opacity: 1,
    '&.Mui-disabled': {
      opacity: 1,
    },
    '.sicon': {
      color: Gray40,
      transition: 'color 0.3s ease',
    },
    '&.Mui-selected .sicon': {
      color: Gray50,
    },
    '&:hover .sicon': {
      color: Gray50,
    },
  };
};

export const TabWrapper = styled.div<{ disabled: boolean }>(
  ({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `,
);
