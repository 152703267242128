import _ from 'lodash';
import { useCallback, useMemo, memo } from 'react';
import Axis from '../../../common/d3/Axis';
import { AxesLabel } from './axes-label.component';
import { useVerticalAxisWrapper } from './base-cartesian-chart.hook';
import { LabelToolTip } from './base-cartesian-chart.styles';
import { usePortalRef } from '../../../common/utilities/portal';

const measureTick = _.memoize((fullText, container = document.body) => {
  try {
    const div = document.createElement('div');
    div.style.width = 'fit-content';
    div.style.fontWeight = '300';
    div.style.fontFamily = 'sans-serif';
    div.style.fontSize = '12px';

    container.appendChild(div);
    div.innerText = fullText;
    const { clientWidth } = div;
    container.removeChild(div);
    return clientWidth;
  } catch {
    return 0;
  }
});

export const PrimaryVerticalAxis = memo<{
  primaryFormatter;
  label;
  customFormatProps;
  isMobile;
  scale;
  yTicks;
  tickSizeInner;
  yAxisWidth;
  offsetForTopAxis;
  showAxisBaseline;
  domain;
  scrolling;
  chartWidth;
  chartHeight;
  onMouseOver;
  isVertical;
  i18nPrefs;
  onScroll;
  showAxesToolTipPrimary;
  hideAxesToolTipPrimary;
  queryId;
  querySort;
  maxChars;
  axesLabelYPos;
  scrollPctTop;
  showAxisToolTipPrimary;
  getOffscreenDimensionsAsync;
}>(
  ({
    primaryFormatter,
    label,
    queryId,
    querySort,
    customFormatProps,
    isMobile,
    scale,
    yTicks,
    scrollPctTop: scrollPct,
    tickSizeInner,
    yAxisWidth,
    offsetForTopAxis,
    showAxisBaseline,
    domain,
    scrolling,
    chartWidth,
    chartHeight,
    onMouseOver,
    isVertical,
    i18nPrefs,
    onScroll,
    showAxesToolTipPrimary,
    hideAxesToolTipPrimary,
    maxChars,
    axesLabelYPos,
    showAxisToolTipPrimary,
    getOffscreenDimensionsAsync,
  }) => {
    const { getPortalContainer } = usePortalRef();
    const container = getPortalContainer();
    const { scrollTop } = useVerticalAxisWrapper({
      scrollPct,
      getOffscreenDimensionsAsync,
    });
    const scrollCb = useCallback(
      (scrollPctLeft, scrollPctTop) => {
        onScroll(0, scrollPctTop);
      },
      [onScroll],
    );
    const tickFormat = useMemo(
      () =>
        isVertical
          ? d => {
              const barWidth = 65;
              const padding = 4;

              // purposefully hiding custom formatting
              const fullText = primaryFormatter.formatSmall(d, i18nPrefs);
              const textLength = measureTick(fullText, container);

              if (textLength > barWidth - 2 * padding) {
                return primaryFormatter.formatSmall(
                  d,
                  i18nPrefs,
                  _.omit(customFormatProps, 'prefixValue', 'suffixValue'),
                );
              }
              return fullText;
            }
          : d => {
              const tickVal = _.truncate(d, {
                length: maxChars,
              });
              return tickVal;
            },
      [
        container,
        customFormatProps,
        i18nPrefs,
        isVertical,
        maxChars,
        primaryFormatter,
      ],
    );
    return (
      <Axis
        key='primary-axis'
        queryId={queryId}
        querySort={querySort}
        scale={scale}
        ticks={yTicks}
        tickSizeInner={tickSizeInner}
        orient={'left'}
        transform={`translate(${yAxisWidth}, ${offsetForTopAxis})`}
        showAxisBaseline={showAxisBaseline}
        mutedLine={domain[0] < 0 || domain[1] < 0}
        scrolling={scrolling}
        scrollable={'true'}
        scrollPct={scrollPct}
        scrollTop={scrollTop}
        chartWidth={chartWidth}
        chartHeight={chartHeight}
        width={yAxisWidth}
        onScroll={scrollCb}
        onMouseOver={onMouseOver}
        tickFormat={tickFormat}
      >
        <AxesLabel
          onMouseEnter={showAxesToolTipPrimary}
          onMouseLeave={hideAxesToolTipPrimary}
          width={chartHeight}
          rotate={270}
          x={-chartHeight}
          y={axesLabelYPos}
          label={label}
          isMobile={isMobile}
        />

        <LabelToolTip
          show={showAxisToolTipPrimary}
          height={44}
          width={500}
          arrow='left'
          xPos={-70}
          y={chartHeight / 2 + 33}
        >
          {label}
        </LabelToolTip>
      </Axis>
    );
  },
);
