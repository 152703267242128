import { messages } from '../../../i18n';
import { DisableableSpan } from '../../../common/emotion';
import { CheckboxStyles, FormControlLabelStyles } from '../../viz-formatting';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Box } from '@mui/system';
import Checkbox from '@mui/material/Checkbox';

export const ComboChartSettings = ({
  chartSpec,
  alignYAxesAtZero,
  setAlignYAxesAtZero,
}) => {
  const formControlLabelSx = FormControlLabelStyles();
  const checkboxSx = CheckboxStyles();
  if (chartSpec.id === 'column_line') {
    return (
      <div>
        <DisableableSpan className='viz-settings-header'>
          {messages.formatting.axis}
        </DisableableSpan>
        <Box>
          <Box className='viz-settings-form-group'>
            <FormControlLabel
              sx={formControlLabelSx}
              control={
                <Checkbox
                  sx={checkboxSx}
                  checked={alignYAxesAtZero}
                  onChange={(event: any) =>
                    setAlignYAxesAtZero(event.target.checked)
                  }
                />
              }
              label={<span>{messages.formatting.lock0lineForXandYaxis}</span>}
            />
          </Box>
        </Box>
      </div>
    );
  } else {
    return null;
  }
};
