import {
  filter,
  get,
  isEmpty,
  isNil,
  isObject,
  some,
  find,
  isFinite,
} from 'lodash';
import { useEffect, useMemo } from 'react';
import { Viz } from '../../VizUtil';
import { ChartSpecs } from '../../ChartSpecs';
import { useQuery } from '@apollo/client';
import { VizQueries } from '../../../common/graphql';
import {
  useOpenVizSelector,
  useReportLinkMaybeSideDrawer,
} from '../../../common/redux/selectors/viz-selector.hook';
import { ILinkToReport } from '../../viz-redirect';
import { IDehydratedViz, IToggle, IViz } from '../../interfaces';
import { useDispatch, useSelector } from 'react-redux';
import Discover from '../../../common/redux/actions/DiscoverActions';
import { useHasValueChanged } from '../../../common/utilities/state-helpers.hook';
import { useAccount } from '../../../common/utilities/account';
import { hasDashletSuffix } from '../../../common';

const isEnableReportLink = (_customFormatToggles: IToggle[]) => {
  const enableReportLinkToggle: IToggle = find(_customFormatToggles, {
    key: 'enableReportLink',
  });
  return !!enableReportLinkToggle?.on;
};

export const ReportLinkMaybeSideDrawerHOC = HOCComponent => props => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const linkToReport = useReportLinkMaybeSideDrawer({
    discoveryId: props.discoveryId,
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { reportDetailInfo } = useSelector(
    (state: any) => state.discover ?? {},
  );

  const isInSideDrawer = hasDashletSuffix(props?.discoveryId ?? props.vizId);
  const hasSideDrawerDrill =
    !isNil(linkToReport?.id) && hasDashletSuffix(linkToReport?.id);

  return (
    <HOCComponent
      {...props}
      isInSideDrawer={isInSideDrawer}
      hasSideDrawerDrill={hasSideDrawerDrill}
      reportDetailInfo={reportDetailInfo}
    />
  );
};

export const useReportLinkEnabled = (vizId: string) => {
  const linkToReport: ILinkToReport = useReportLinkMaybeSideDrawer({
    discoveryId: vizId,
  });
  const { data: { visualization: reportLinkViz } = {} } = useQuery<{
    visualization: IDehydratedViz;
  }>(VizQueries.GetVisualization, {
    skip: isNil((linkToReport as ILinkToReport)?.id),
    variables: {
      id: (linkToReport as ILinkToReport)?.id ?? '',
    },
  });

  const viz = useOpenVizSelector({ discoveryId: vizId });
  const isTargetVizOpen = !!(
    useOpenVizSelector({ discoveryId: reportLinkViz?.id }) && reportLinkViz?.id
  );

  const { isDashletMode = false, hasSideDrawerDrill = false } = useSelector(
    (state: any) => state.dashlet ?? {},
  );
  const { isDashletUser } = useAccount();
  const userAllowed = hasSideDrawerDrill || !isDashletUser;
  const dispatch = useDispatch();
  const hasLinkedVizIdChanged = useHasValueChanged({
    value: reportLinkViz?.id,
  });

  const shouldEnableReportLink = useShouldEnableReportLink(viz, [
    (reportLinkViz as unknown) as IViz,
  ]);

  const enabled =
    !hasDashletSuffix(vizId) && userAllowed && shouldEnableReportLink;

  useEffect(() => {
    // preload target report when in dashlet mode
    if (
      !isTargetVizOpen &&
      enabled &&
      isDashletMode &&
      isObject(reportLinkViz) &&
      !isEmpty(reportLinkViz) &&
      hasLinkedVizIdChanged
    ) {
      dispatch(Discover.openVisualization({ id: reportLinkViz?.id }));
    }
  }, [
    dispatch,
    enabled,
    isDashletMode,
    isTargetVizOpen,
    reportLinkViz,
    hasLinkedVizIdChanged,
  ]);

  return {
    enabled,
    reportLinkViz,
    isTargetVizOpen,
  };
};
export const useShouldEnableReportLink = (
  viz: IViz,
  visualizations: IViz[],
) => {
  const linkToReport: ILinkToReport = useReportLinkMaybeSideDrawer({
    discoveryId: viz?.id,
  });

  const customFormatToggles: IToggle[] = Viz.getCustomFormatTogglesFromViz(viz);

  const possibleLinkedReports = filter(visualizations ?? [], {
    chartType: ChartSpecs.pivot.id,
  });
  const linkedReportExists = some(possibleLinkedReports, {
    id: (linkToReport as ILinkToReport)?.id,
  });

  return (
    isEnableReportLink(customFormatToggles) &&
    !isEmpty(linkToReport) &&
    get(linkToReport, 'isValid', true) &&
    linkedReportExists
  );
};
/**
 * ShouldEnableReportLinkHOC is an HOC to use a custom React hook - useReportLinkEnabled
 * @param Component
 * @param vizId
 * @constructor
 */
export const ShouldEnableReportLinkHOC = (Component: any, vizId: string) => {
  return (props: any) => {
    const _vizId = vizId ?? props.vizId;
    const {
      enabled: enableReportLink,
      reportLinkViz,
      isTargetVizOpen,
      // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useReportLinkEnabled(_vizId);

    return (
      <Component
        enableReportLink={enableReportLink}
        targetViz={reportLinkViz}
        isTargetVizOpen={isTargetVizOpen}
        {...props}
      />
    );
  };
};

export const useOffscreenDimensions = ({ getOffscreenDimensionsAsync }) => {
  const { offscreenWidth, offscreenHeight } = getOffscreenDimensionsAsync();
  const dimensions = useMemo(() => ({ offscreenWidth, offscreenHeight }), [
    offscreenHeight,
    offscreenWidth,
  ]);
  return dimensions;
};

export const useOffscreenHeight = ({ getOffscreenDimensionsAsync }) => {
  const { offscreenHeight } = useOffscreenDimensions({
    getOffscreenDimensionsAsync,
  });
  return offscreenHeight;
};

export const useOffscreenWidth = ({ getOffscreenDimensionsAsync }) => {
  const { offscreenWidth } = useOffscreenDimensions({
    getOffscreenDimensionsAsync,
  });
  return offscreenWidth;
};

export const useVerticalAxisWrapper = ({
  getOffscreenDimensionsAsync,
  scrollPct,
}) => {
  const offscreenHeight = useOffscreenHeight({ getOffscreenDimensionsAsync });
  let scrollTop = Math.min(
    0,
    Math.max(-(offscreenHeight * scrollPct), -offscreenHeight),
  );
  scrollTop = isFinite(scrollTop) ? scrollTop : 0;

  const retVal = useMemo(() => ({ scrollPct, scrollTop }), [
    scrollPct,
    scrollTop,
  ]);
  return retVal;
};
