import { Component } from 'react';
import SelectItemsSvg from '../../../images/sdd/icon_select_items.svg';
import SetConditionSvg from '../../../images/sdd/icon_set_condition.svg';
import TopBottomSvg from '../../../images/sdd/icon_top_bottom.svg';
import { createFilterForField, TimestampFilterSubTypes } from './Filter';
import { isEqual, isNil } from 'lodash';
import TimestampCondition from './TimestampCondition';
import { RelativeDateCondition } from './relative-date-condition';
import TimestampPeriod from './TimestampPeriod';
import { messages } from '../../i18n';
import { IFilter } from '../../datasets/interfaces/';
import { FilterTypeNav, FilterTypeNavItem } from './filter-type-nav';
import { TransitionGroup } from 'react-transition-group';
import Fade from '@mui/material/Fade';
import { useFilterDialogContext } from './filter-dialog/filter-dialog.context';

interface IPropTypes {
  activeFilter: IFilter;
  filter: IFilter;
  vizId: string;
  field: any;
  changeFilter: (filter: IFilter) => void;
}

class DateFilterUnconnected extends Component<IPropTypes, {}> {
  constructor(props) {
    super(props);
    const { filter } = props;
    const subType =
      isNil(filter) || isNil(filter.subType) ? '' : filter.subType;
    this.state = {
      [TimestampFilterSubTypes.SET_CONDITION]:
        subType === TimestampFilterSubTypes.SET_CONDITION ? props.filter : null,
      [TimestampFilterSubTypes.RELATIVE_DATES]:
        subType === TimestampFilterSubTypes.RELATIVE_DATES
          ? props.filter
          : null,
      [TimestampFilterSubTypes.PERIOD_TO_DATE]:
        subType === TimestampFilterSubTypes.PERIOD_TO_DATE
          ? props.filter
          : null,
      selectedTab: subType ? subType : TimestampFilterSubTypes.SET_CONDITION,
    };
  }
  componentDidUpdate(prevProps) {
    if (
      !isNil(this.props.activeFilter) &&
      !isEqual(
        prevProps.activeFilter?.subType,
        this.props.activeFilter?.subType,
      )
    ) {
      // keep track of the previous filter
      this.setState({
        [this.props.activeFilter?.subType]: this.props.activeFilter,
      });
    }
  }

  onTabChange(key) {
    let currentFilter = this.state[key];
    if (isNil(currentFilter)) {
      const f = createFilterForField(this.props.field);
      f.subType = key;
      currentFilter = f;
      this.setState({ [key]: f });
    }
    this.props.changeFilter(currentFilter);
  }

  renderPanel(activeKey) {
    const { filter, field, vizId } = this.props;
    switch (activeKey) {
      case TimestampFilterSubTypes.SET_CONDITION:
        return (
          <Fade key={TimestampFilterSubTypes.SET_CONDITION} timeout={500}>
            <div className='tab-content'>
              <div className='section-label'>
                {messages.filters.setCondition}
              </div>
              <div className='filter-panel filter-condition'>
                {isEqual(activeKey, TimestampFilterSubTypes.SET_CONDITION) && (
                  <TimestampCondition
                    filter={filter}
                    field={field}
                    vizId={vizId}
                  />
                )}
              </div>
            </div>
          </Fade>
        );
      case TimestampFilterSubTypes.RELATIVE_DATES:
        return (
          <Fade key={TimestampFilterSubTypes.RELATIVE_DATES} timeout={500}>
            <div className='tab-content'>
              <div className='section-label'>
                {messages.filters.setCondition}
              </div>
              <div className='filter-panel filter-condition'>
                {isEqual(activeKey, TimestampFilterSubTypes.RELATIVE_DATES) && (
                  <RelativeDateCondition
                    filter={filter}
                    field={field}
                    vizId={vizId}
                  />
                )}
              </div>
            </div>
          </Fade>
        );

      case TimestampFilterSubTypes.PERIOD_TO_DATE:
        return (
          <Fade key={TimestampFilterSubTypes.PERIOD_TO_DATE} timeout={500}>
            <div>
              <div className='section-label'>
                {messages.filters.setCondition}
              </div>
              <div className='filter-panel filter-condition'>
                {isEqual(activeKey, TimestampFilterSubTypes.PERIOD_TO_DATE) && (
                  <TimestampPeriod
                    filter={filter}
                    field={field}
                    vizId={vizId}
                  />
                )}
              </div>
            </div>
          </Fade>
        );
      default:
        return null;
    }
  }

  render() {
    const { filter } = this.props;
    const activeKey = filter?.subType
      ? filter.subType
      : TimestampFilterSubTypes.SET_CONDITION;

    return (
      <div
        id='timestamp-filter-tab-container'
        className='filter-content timestamp-filter'
      >
        <div className='section-label'>{messages.filters.selectFilterType}</div>
        <FilterTypeNav
          value={activeKey}
          onChange={(e, key) => this.onTabChange(key)}
          className='aggregate-filter-tabs'
        >
          <FilterTypeNavItem
            value={TimestampFilterSubTypes.SET_CONDITION}
            icon={<SetConditionSvg />}
            label={messages.filters.setACondition}
          />
          <FilterTypeNavItem
            value={TimestampFilterSubTypes.RELATIVE_DATES}
            icon={<SelectItemsSvg />}
            label={messages.filters.relativeDates}
          />
          <FilterTypeNavItem
            value={TimestampFilterSubTypes.PERIOD_TO_DATE}
            icon={<TopBottomSvg />}
            label={messages.filters.periodToDate}
          />
        </FilterTypeNav>
        <TransitionGroup
          exit={false}
          style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
        >
          {this.renderPanel(activeKey)}
        </TransitionGroup>
      </div>
    );
  }
}

export const DateFilter = ({ vizId }) => {
  const { field, filter, changeFilter } = useFilterDialogContext();

  return (
    <DateFilterUnconnected
      vizId={vizId}
      field={field}
      filter={filter}
      activeFilter={filter}
      changeFilter={changeFilter}
    />
  );
};
