import { font, LightFontWeight } from '../../../common/emotion';

export const useLabelStyles = providedLabelSx => {
  return {
    marginLeft: 0,
    '& .MuiFormControlLabel-label': {
      ...font({ size: '12px', weight: LightFontWeight }),
    },
    ...providedLabelSx,
  };
};

export const useRadioStyles = providedRadioSx => {
  return {
    '& .MuiSvgIcon-root': {
      fontSize: 15,
    },
    ...providedRadioSx,
  };
};
