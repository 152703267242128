import MuiTooltip from '@mui/material/Tooltip';
import { TooltipProps } from '@mui/material/Tooltip/Tooltip';
import withStyles from '@mui/styles/withStyles';
import { invertLuminance } from '../../common/emotion';
import { usePortalRef } from '../../common/utilities/portal';

export const tooltipOpacity = 96;

export const Tooltip = withStyles(theme => {
  const {
    colors: {
      TooltipBackgroundVColor = '',
      ContentText = '',
      RegularFontWeight = '',
      LightFontWeight = '',
      TooltipBackgroundVColorText = '',
    } = {},
  } = theme;
  return {
    tooltip: {
      borderRadius: '2px',
      backgroundColor: TooltipBackgroundVColor,
      opacity: tooltipOpacity,
      padding: '6px !important',
      fontWeight: '300',
      fontSize: '12px',
      color: TooltipBackgroundVColorText,
      maxWidth: '640px',
      maxHeight: '118px',
      '& .date-filter-tooltip hr': {
        marginTop: '5px',
        marginBottom: '5px',
      },
      '& .center-text': {
        textAlign: 'center',
      },
      '& .field-tooltip-body': {
        maxHeight: '68px',
        overflow: 'hidden',
        overflowWrap: 'break-word',
      },
      '& .field-tooltip-separator': {
        margin: '8px 0px',
        borderColor: invertLuminance(ContentText),
      },
      '& .field-tooltip-header': {
        fontWeight: LightFontWeight,
        maxHeight: '18px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&.with-calc': {
          fontWeight: RegularFontWeight,
        },
      },
      '& .field-tooltip-missing-field': {
        display: 'flex',
        alignItems: 'center',
        '& > .sicon': {
          margin: '5px',
        },
      },
    },
    arrow: {
      color: TooltipBackgroundVColor,
    },
  };
})((props: TooltipProps) => {
  const { getPortalContainer } = usePortalRef();
  const container = getPortalContainer();
  return (
    <MuiTooltip componentsProps={{ popper: { container } }} {...props}>
      {props.children}
    </MuiTooltip>
  );
});
