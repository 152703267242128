import Tab, { TabProps } from '@mui/material/Tab';
import { TabWrapper, useTabStyles } from './discovery-toolbar-button.styles';

export const DiscoveryToolbarButton = (props: TabProps) => {
  const sx = useTabStyles();
  return (
    <TabWrapper disabled={props.disabled}>
      <Tab
        {...props}
        label=''
        sx={sx}
        icon={
          <div>
            <span title={props.title}>{props.icon}</span>
          </div>
        }
      />
    </TabWrapper>
  );
};
