import { Component } from 'react';
import {
  find,
  findIndex,
  isEmpty,
  isNil,
  keys,
  reject,
  pick,
  get,
} from 'lodash';
import styled from '@emotion/styled';
import { max } from 'd3';
import Util from '../../common/Util';
import { messages } from '../../i18n';
import { Disableable } from '../../common/emotion';
import { SelectItem, SelectDropdown } from '../../ui/dropdowns/select-dropdown';
import map from 'lodash/map';
import { PIVOT_TABLE } from '../../common/Constants';
import { Box } from '@mui/system';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  CheckboxStyles,
  FormControlLabelStyles,
} from '../../discovery/viz-formatting';

const Header = Disableable(styled.div`
  font-size: 14px;
  margin-bottom: 8px;
`);

const SubHeader = Disableable(styled.div`
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
`);

const Swatch = Disableable(styled.div`
  width: 32px;
  height: 16px;
  margin: 6px 8px;
`);

const TextWrapper = Disableable(styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`);

export default class PivotConditionalFormatting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxTextWidth: max(
        this.props.metrics.map(m => Util.calcTextWidth(m.name)),
      ),
      menuOpen: false,
    };
  }

  onChange(checked, name) {
    const data = checked
      ? [
          ...this.props.conditionalFormatting,
          {
            name,
            colorScale: keys(PIVOT_TABLE.COLOR_SCALES)[0], // default color scale
          },
        ]
      : reject(this.props.conditionalFormatting, { name });

    this.props.setConditionalFormatting(data);
  }

  onColorScaleChange(metric, colorScale) {
    const data = [...this.props.conditionalFormatting];
    const index = findIndex(data, pick(metric, 'name'));
    if (index !== -1) {
      data[index].colorScale = colorScale;
    }
    this.props.setConditionalFormatting(data);
  }

  render() {
    if (isEmpty(this.props.metrics)) {
      return [];
    }
    return [
      <Header key='pivot-conditional-header'>
        {messages.formatting.conditionalFormatting}
      </Header>,
      <Box
        key='pivot-conditional-form'
        className='pivot-conditional-formatting'
      >
        <Box className='pivot-conditional-formatting__form-group'>
          <SubHeader>{messages.formatting.applyColorScaleTo}</SubHeader>
          {this.props.metrics.map(metric => {
            const formatting = find(
              this.props.conditionalFormatting,
              pick(metric, 'name'),
            );
            const checked = !isNil(formatting);
            const formControlLabelSx = {
              ...FormControlLabelStyles(),
              flexBasis: checked ? '35%' : '100%',
              maxWidth: checked ? '35%' : '100%',
            };
            const checkboxSx = CheckboxStyles();
            return (
              <div
                key={`metric-${metric.name}`}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <FormControlLabel
                  sx={formControlLabelSx}
                  componentsProps={{
                    typography: {
                      sx: { overflow: 'hidden' },
                    },
                  }}
                  control={
                    <Checkbox
                      sx={checkboxSx}
                      disabled={this.props.disabled}
                      checked={checked}
                      onChange={event =>
                        this.onChange(event.target.checked, metric.name)
                      }
                    />
                  }
                  label={
                    <TextWrapper>
                      <span
                        title={metric.name}
                        style={{ minWidth: this.state.maxTextWidth }}
                      >
                        {metric.name}
                      </span>
                    </TextWrapper>
                  }
                />
                {checked && (
                  <SelectDropdown
                    title={[
                      <Swatch
                        key={'color-swatch'}
                        style={{
                          background:
                            PIVOT_TABLE.COLOR_SCALES[formatting.colorScale]
                              .swatch,
                        }}
                      />,
                      <span key={'label'}>
                        {get(
                          messages,
                          PIVOT_TABLE.COLOR_SCALES[formatting.colorScale].name,
                          PIVOT_TABLE.COLOR_SCALES[formatting.colorScale].name,
                        )}
                      </span>,
                    ]}
                  >
                    {map(PIVOT_TABLE.COLOR_SCALES, (scale, key, idx) => {
                      return (
                        <SelectItem
                          onClick={() => this.onColorScaleChange(metric, key)}
                          key={idx}
                        >
                          <Swatch
                            key={'swatch'}
                            style={{ background: scale.swatch }}
                          />
                          <span key={'title'}>
                            {get(messages, scale.name, scale.name)}
                          </span>
                        </SelectItem>
                      );
                    })}
                  </SelectDropdown>
                )}
              </div>
            );
          })}
        </Box>
      </Box>,
    ];
  }
}
