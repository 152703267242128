import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { AutoSizer } from 'react-virtualized';
import {
  Disableable,
  DisableableDiv,
  font,
  LightFontWeight,
  useDiscoverTheme,
} from '../../common/emotion';

export const FormControlLabelStyles = () => ({
  height: '32px',
  display: 'flex',
  alignItems: 'center',
  padding: 0,
  marginRight: 0,
  marginBottom: 0,

  '& .MuiFormControlLabel-label': {
    ...font({ size: '12px', weight: LightFontWeight }),
  },
});

export const CheckboxStyles = () => ({
  '& .MuiSvgIcon-root': {
    fontSize: '16px',
  },
});

export const Header = Disableable(styled.div`
  font-size: 14px;
  margin-bottom: 8px;
`);

export const SubHeader = Disableable(styled.div`
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
`);

export const LinkFilterStrategyDiv = styled.div`
  margin: 16px 0 0 10px;
`;

export const LinkFilterStrategyLabelDiv = Disableable(
  styled.div(
    ({
      theme: { colors: { LightFontWeight } = {} as any } = {} as any,
    }: any) => css`
      font-size: 12px;
      font-weight: ${LightFontWeight};
      margin: 0 0 10px 10px;
    `,
  ),
);

export const ReportDropdownTitle = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 3px;
`;

export const FlexibleWrapper = styled.div`
  grid-column-start: endEnable;
  grid-column-end: endRow;
  min-width: 0;
  container-type: inline-size;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
`;

export const VizFormattingHeader = styled(DisableableDiv)({
  display: 'inline-block',
  verticalAlign: 'middle',
});

export const DrillLinkingDisabledTooltipMessage = styled.p({ margin: '1em' });

export const DrillLinkingDisabledToastIcon = styled.div(() => {
  const { colors: { DefaultIconColor } = {} as any } = useDiscoverTheme();
  return css({
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: '0.25em',
    '.sicon': {
      color: DefaultIconColor,
    },
  });
});

export const DrillLinkingRowWrapper = ({ children }) => (
  <AutoSizer disableHeight>
    {({ width }) => (
      <div
        css={{
          display: 'grid',
          gridTemplateColumns: '[beginRow] 4.5em [endEnable] 1fr [endRow]',
          gridTemplateRows: '1fr',
          width,
          maxWidth: width,
          overflow: 'hidden',
          '.checkbox': {
            marginBottom: '0',
          },
        }}
      >
        {children}
      </div>
    )}
  </AutoSizer>
);
