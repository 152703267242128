import { Component, ComponentClass } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import VizQueries from '../../common/graphql/VizGql';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  branch,
  compose,
  renderComponent,
  renderNothing,
} from 'react-recompose';
import { QueryError } from '../query-error';
import { StringFilterSubTypes } from './Filter';
import { validateFilter } from './FilterValidation';
import Util from '../../common/Util';
import { Viz } from '../VizUtil';
import { AutoSizer, List } from 'react-virtualized';
import { messages } from '../../i18n';
import { ACCOUNT_SELECTORS } from '../../common/redux/selectors/AccountSelectors';
import { IAnyAttribute, IFilter } from '../../datasets';
import { ICalc, IViz } from '../interfaces';
import { withSkeletonListLoadingPanel } from '../../common/hoc';

interface IStateToProps {
  dateFormat: string;
  viz: IViz;
  datasetId: string;
  filter: IFilter;
  isAdvancedMode: boolean;
}

interface IGivenProps {
  applyCurrentVizFilters: boolean;
  queryResults: any;
  vizId: string;
  field: IAnyAttribute;
  vizCalcs: ICalc[];
}

interface IProps extends IGivenProps, IStateToProps {}

class StringConditionPreview extends Component<IProps, {}> {
  props;

  static defaultProps = {
    applyCurrentVizFilters: true,
  };

  shouldComponentUpdate(nextProps: IProps) {
    return nextProps.filter.subType === StringFilterSubTypes.SET_CONDITION;
  }
  render() {
    const { results } = this.props.queryResults?.executeQuery ?? {};
    return _.isEmpty(results) ? (
      <div className='no-search-results'>{messages.filters.noMatches}</div>
    ) : (
      <AutoSizer>
        {({ height, width }) => (
          <List
            height={height}
            width={width}
            rowHeight={20}
            rowCount={results.length}
            rowRenderer={({ index, key, style }) => {
              const r = results[index];
              return (
                <div style={style} key={key}>
                  {Util.handleNullToken(r[0])}
                </div>
              );
            }}
          />
        )}
      </AutoSizer>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const discovery = state.discover.openDiscoveries[ownProps.vizId].present;
  const filter = state.discover.activeFilter;
  const { dateFormat } = ACCOUNT_SELECTORS.getDatetimeFormats(state);
  const { viz } = discovery ?? {};
  return {
    dateFormat,
    viz,
    datasetId: viz?.dataset?.id,
    filter: _.isEmpty(filter) ? ownProps.filter : { ...filter },
    isAdvancedMode: state.main.advanced,
  };
};
const mapDispatchToProps = _.constant({});

const GetValuesQuery = graphql(VizQueries.VizQuery, {
  skip: (ownProps: any) => {
    return _.isNil(ownProps.datasetId) || _.isEmpty(ownProps.filter);
  },
  options: (ownProps: IProps) => {
    const { viz, filter, field } = ownProps;
    let calcs = [];
    if (field?.attributeType === 'STRING_CALC') {
      calcs = ownProps.vizCalcs.map(c => {
        return {
          attributeName: c.name,
          expression: c.formula,
        };
      });
    }
    const filtersFromExpression = Viz.filterQueryAccumulator(
      viz,
      filter?.expression,
      {
        filter,
        field,
      },
    );
    let variables = {
      id: ownProps.datasetId,
      attributeNames: [field?.name],
      measures: [],
      calcs,
      filters: filtersFromExpression,
    };
    if (ownProps.applyCurrentVizFilters) {
      let filters = _.get(Viz.mapFiltersToQuery(viz), 'filters', []);
      // update this filter
      filters = _.reject(filters, { attributeName: field.name });
      filters = [...filters, ...filtersFromExpression];
      // apply current calcs
      const currentCalcs = _.get(Viz.mapCalcsToQuery(viz), 'calcs', []);

      variables = {
        ...variables,
        filters,
        calcs: _.uniqBy([...calcs, ...currentCalcs], 'attributeName'),
      };
    }
    return {
      variables,
      notifyOnNetworkStatusChange: true,
    };
  },
  props: ({ data }: any) => {
    if (data?.error) {
      console.error(data.error);
      return { queryResults: { ...data }, loading: false };
    } else if (data?.executeQuery) {
      return { queryResults: { ...data }, loading: data.loading };
    } else {
      return { loading: true };
    }
  },
});

const Nada = () => {
  return (
    <div className='text-light'>
      {messages.filters.conditionNotSetNoSelectionsToPreview}
    </div>
  );
};

export default compose<any, any>(
  branch((props: any) => {
    return (
      _.isNil(props.filter) ||
      props.filter.subType !== StringFilterSubTypes.SET_CONDITION
    );
  }, renderNothing),
  connect(mapStateToProps, mapDispatchToProps),
  branch((props: any) => {
    return !validateFilter(props.filter);
  }, renderComponent(Nada)),
  GetValuesQuery,
  withSkeletonListLoadingPanel(),
  branch((props: any) => {
    return !_.isNil(props.queryResults) && !_.isNil(props.queryResults.error);
  }, renderComponent(QueryError)),
)(StringConditionPreview as ComponentClass<IGivenProps>);
