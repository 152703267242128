import Tab, { TabProps } from '@mui/material/Tab';
import {
  FilterNavItem,
  FilterTypePanel,
  useTabStyles,
} from './filter-type-nav.styles';
import { Tabs, TabsProps } from '@mui/material';

export const FilterTypeNav = (props: TabsProps) => {
  return (
    <FilterTypePanel className='filter-panel filter-type'>
      <Tabs
        {...props}
        sx={{
          '& .MuiTabs-indicator': {
            display: 'none',
          },
        }}
      />
    </FilterTypePanel>
  );
};

export const FilterTypeNavItem = (props: TabProps) => {
  const sx = useTabStyles();
  return (
    <Tab
      {...props}
      label=''
      sx={sx}
      icon={
        <FilterNavItem className='filter-nav-item'>
          <div className='filter-nav-icon'>{props.icon}</div>
          <div className='filter-nav-content'>{props.label}</div>
        </FilterNavItem>
      }
    />
  );
};
