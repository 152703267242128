import { styled } from '@mui/material/styles';
import { isEmpty, merge } from 'lodash';
import {
  ModernLgSearchRadius,
  useDiscoverTheme,
} from '../../../common/emotion';
import { CheckIcon, CloseIconSmall } from '../../../icons/icons';
import { CSSProperties } from 'react';
import {
  BORDER_RADIUS,
  MAX_SLICER_WIDTH,
  SCROLL_ROW_HEIGHT,
} from '../../../discovery/slicer/slicer-widget/common/slicer-widget-consts.style';

export const ClearIcon = styled(CloseIconSmall)`
  align-self: center;
  margin-left: 5px;
`;

export const SelectedCheck = styled(CheckIcon)`
  align-self: center;
  margin-right: 5px;
`;

export const useListSx = ({
  disableSearch = false,
  providedListSx = {},
  disableScroll = false,
}) => {
  const { colors: { DropdownButtonBackgroundColor } = {} } = useDiscoverTheme();

  return merge(
    {
      backgroundColor: DropdownButtonBackgroundColor,
    },
    disableSearch
      ? {}
      : {
          maxHeight: '20rem',
          overflow: disableScroll ? 'hidden' : 'hidden scroll',
          zIndex: 0,
          '& li:first-of-type': {
            width: 'calc(100% - 2px)',
            position: 'sticky',
            zIndex: 2,
            top: 0,
            borderRadius: BORDER_RADIUS,
            paddingTop: '3px',
            paddingBottom: '3px',
          },
        },
    providedListSx,
  );
};

export const usePopperSx = ({ sx: providedSx }) => {
  const { colors: { DropdownBorderColor } = {} } = useDiscoverTheme();

  return merge(
    {
      '&:has(+:focus),&:has(+:focus-visible)': {
        borderColor: DropdownBorderColor,
      },
    },
    providedSx,
  );
};

export const useHeaderSx = ({ providedHeaderSx, errorMessage }) => {
  const { colors: { DarkRed } = {} } = useDiscoverTheme();

  const headerSx = merge(
    !isEmpty(errorMessage)
      ? {
          borderColor: DarkRed,
          color: DarkRed,
          'div > span': {
            color: DarkRed,
          },
        }
      : {
          width: `min(100%, ${MAX_SLICER_WIDTH}px)`,
          maxWidth: `min(100%, ${MAX_SLICER_WIDTH}px)`,
          '& span.title': {
            width: `min(100%, ${MAX_SLICER_WIDTH}px)`,
          },
        },
    providedHeaderSx,
  );

  return headerSx;
};

export const titleContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignContent: 'center',
  width: '100%',
};

export const titleSpanStyle = {
  display: 'inline-block',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  maxWidth: '100%',
};

export const useTitleStyles = () => ({
  titleContainerStyle,
  titleSpanStyle,
});

export const useSearchableDropdownSx = ({
  searchMenuItemSx: providedSearchMenuItemSx = {},
  searchInputSx: providedSearchInputSx = {},
}: {
  searchMenuItemSx?: CSSProperties;
  searchInputSx?: CSSProperties;
} = {}): {
  [key: string]: CSSProperties;
} => {
  const {
    colors: { ContentBackground, PrimaryColor } = {},
  } = useDiscoverTheme();

  const searchMenuItemSx = merge(
    {
      padding: '0 6px',
      backgroundColor: ContentBackground,
      '&:hover,&:focus': {
        backgroundColor: ContentBackground,
        cursor: 'default',
      },
    },
    providedSearchMenuItemSx,
  );

  const searchInputSx = merge(
    {
      backgroundColor: ContentBackground,
      border: `1px solid ${PrimaryColor}`,
      padding: '6px 10px',
      height: `${SCROLL_ROW_HEIGHT}px`,
      fontSize: '12px',
      borderRadius: ModernLgSearchRadius,
    },
    providedSearchInputSx,
  );

  return {
    searchMenuItemSx,
    searchInputSx,
    menuItemSx: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  };
};
