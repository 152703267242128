import {
  ComponentType,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { compose, withState } from 'react-recompose';
import { MobileVizFooter } from '../../components/MobileVizFooter';
import _ from 'lodash';
import { VizChart } from '../../discovery/viz-chart/viz-chart.component';
import { VizLegend } from '../../discovery/charts/viz-legend';
import { ActiveFilterPanel } from '../../discovery/filter/active-filter-panel';
import DiscoverActions from '../../common/redux/actions/DiscoverActions';
import LoginActions from '../../common/redux/actions/LoginActions';
import { VizQuery } from '../graphql';
import RemoveFieldFromVizDialog from '../../discovery/RemoveFieldFromVizDialog';
import AddFieldToVizWarningDialog from '../../discovery/AddFieldToVizWarningDialog';
import QueryWarningDialog from '../../discovery/QueryWarningDialog';
import { QueryErrorDialog } from '../../discovery/query-error';
import {
  isNoAccess,
  isNonDashletUser,
} from '../../common/redux/selectors/AccountSelectors.js';
import { messages } from '../../i18n';
import { css } from '@emotion/react';
import { IDiscoverEmotionTheme, useDiscoverTheme } from '../../common/emotion';
import { useResizeDetector } from 'react-resize-detector';
import styled from '@emotion/styled';
import { useViewportDimensions } from '../../common/utilities/dimensions.hook';
import {
  InvisibleButton,
  PrimaryButton,
  SmallModalButtonContainer,
} from '../../ui';
import { IDiscovery } from '../../discovery';
import {
  CenteredPanel,
  CenteredPanelTitle,
  ChartErrorBoundary,
} from './report-dashlet.styles';
import { withDiscoverOption } from '../../discovery/discovery-context/discovery.context';
import { ErrorBoundaryComponentProps } from '../../components/error-boundary';
import { SkeletonVizLoader } from '../../common/loaders/skeleton-viz-loader';
import { ModalPortal } from '../../common/widgets/dialogs/modal-portal';

const StyledModal = styled(ModalPortal)(() =>
  css({
    margin: 0,
    position: 'absolute',

    '.modal-content': {
      paddingRight: '5px',
      border: 0,
      boxShadow: 'none',
    },
  }),
);

const OuterWrapper = ({
  children,
}: {
  width?: number;
  height?: number;
  children;
}) => {
  return (
    <div className='discover' style={{ height: '100%' }}>
      <div className='discover-content-panel' style={{ height: '100%' }}>
        <div className='discover-open-panel' style={{ height: '100%' }}>
          <div className='discover-tab-panel' style={{ height: '100%' }}>
            <div className='viz-container' style={{ height: '100%' }}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface IPropTypes {
  loading?: boolean;
  vizId?: string;
  openMobileInsights?;
  hasInsights?;
  insightsChecked?;
  insightsLoading?;
  badId?: boolean;
  openDiscovery;
  dehydratedVisualization: Partial<IDiscovery>;
  discovery?;
  viz?;
  advanced?: boolean;
  showReAuthenticate?: boolean;
  discoveriesLoading?: boolean;
  isLoggedIn?: boolean;
  isForcedMobile?: boolean;
  isDashletMode?: boolean;
  theme?: IDiscoverEmotionTheme;
  showInsights?: boolean;
  setShowInsights?: (show: boolean) => void;
  logout?;
  children?;
  isNoAccess: boolean;
  fetching: boolean;
  showLegendPanel: boolean;
  applyVizState?: (vizState: any) => void;
}

const ContentDiv = styled.div`
  min-height: 0px;
  min-width: 0px;
  overflow: hidden;
`;
const CenterDiv = styled.div`
  margin: auto;
`;

const Content = ({ vizId }) => {
  const { width: resizeWidth, height, ref } = useResizeDetector({
    handleHeight: true,
    handleWidth: false,
  });
  const { width: viewportWidth } = useViewportDimensions();
  const width = _.min([resizeWidth, viewportWidth]);

  return (
    <ContentDiv ref={ref} className='content'>
      <div
        className='viz-chart'
        style={{ width: width === 0 ? '100%' : width, height }}
      >
        <VizChart
          width={width}
          height={Math.max(height, 0)}
          isIsolated={true}
          vizId={vizId}
        />
      </div>
    </ContentDiv>
  );
};

const mapStateToProps = (state, props: any) => {
  const { vizId } = props;
  const {
    main: { showReAuthenticate, advanced, isForcedMobile } = {} as any,
    dashlet: { isDashletMode } = {} as any,
    discover: { openDiscoveries } = {} as any,
    account,
  } = state;
  const discovery = _.get(openDiscoveries, [vizId, 'present']);
  const { viz } = discovery || {};
  const {
    hasInsights,
    mobileInsightsChecked: insightsChecked,
    insightsLoading,
  } = discovery || {};
  return {
    loading: !viz,
    viz,
    isLoggedIn: isNonDashletUser(account),
    isNoAccess: isNoAccess(account),
    showReAuthenticate,
    advanced,
    discovery,
    hasInsights,
    insightsChecked,
    insightsLoading,
    vizId,
    isForcedMobile,
    isDashletMode,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    logout: () => {
      LoginActions.logout(dispatch);
    },
    openDiscovery: discovery => {
      dispatch(DiscoverActions.openVisualization(discovery));
    },
    openMobileInsights: () => {
      dispatch(DiscoverActions.mobileInsightsChecked(ownProps.vizId));
      ownProps.setShowInsights(true);
    },
  };
};

export const ReportDashlet: ComponentType<{
  vizId?: string;
  isDrillLinking?: boolean;
}> = compose(
  withDiscoverOption({ option: 'showLegendPanel' }),
  withState('showInsights', 'setShowInsights', false),
  connect(mapStateToProps, mapDispatchToProps),
  VizQuery,
)(
  ({
    dehydratedVisualization,
    vizId,
    loading,
    openDiscovery,
    discoveriesLoading,
    insightsLoading,
    showReAuthenticate,
    viz,
    openMobileInsights,
    advanced,
    hasInsights,
    insightsChecked,
    logout,
    isNoAccess,
    fetching,
    showLegendPanel,
  }: IPropTypes) => {
    const {
      viewWidth,
      viewHeight,
      colors: { PanelBorder },
    } = useDiscoverTheme();
    const [suppressErrorDialog, setSuppressErrorDialog] = useState(false);
    const [badId, setBadId] = useState(false);
    const [updating, setUpdating] = useState(false);

    useEffect(() => {
      if (!isNoAccess && loading) {
        if (!dehydratedVisualization && !discoveriesLoading) {
          setBadId(true);
        } else if (dehydratedVisualization && !updating) {
          setUpdating(true);
          setBadId(false);
          openDiscovery(dehydratedVisualization);
        }
      } else {
        setUpdating(false);
        setBadId(false);
      }
    }, [
      loading,
      dehydratedVisualization,
      discoveriesLoading,
      openDiscovery,
      updating,
      isNoAccess,
    ]);
    const hideErrorDialog = useCallback(() => setSuppressErrorDialog(true), []);
    const errorMessage = useMemo(() => {
      if (isNoAccess) {
        return messages.formatString(
          messages.dashlet.noAccess,
          messages.nonTranslated.discover,
        );
      } else if (showReAuthenticate) {
        return messages.formatString(
          messages.dashlet.failedToAuthenticate,
          messages.nonTranslated.sugarDiscover,
        );
      } else if (!vizId) {
        return messages.formatString(
          messages.dashlet.dashletNotInitialized,
          messages.nonTranslated.dashlet,
        );
      } else if (badId) {
        return messages.formatString(
          messages.dashlet.reportNotFound,
          messages.nonTranslated.sugarDiscover,
          messages.nonTranslated.discover,
        );
      }
    }, [badId, showReAuthenticate, vizId, isNoAccess]);
    if (errorMessage) {
      if (isNoAccess) {
        return (
          <OuterWrapper width={viewWidth} height={viewHeight}>
            <CenterDiv>{errorMessage}</CenterDiv>
          </OuterWrapper>
        );
      } else {
        return (
          !suppressErrorDialog && (
            <OuterWrapper width={viewWidth} height={viewHeight}>
              <StyledModal
                title={'Error loading report'}
                buttonGroup={
                  <SmallModalButtonContainer>
                    <InvisibleButton onClick={hideErrorDialog}>
                      {messages.cancel}
                    </InvisibleButton>
                    <PrimaryButton onClick={logout}>Log out</PrimaryButton>
                  </SmallModalButtonContainer>
                }
              >
                <div className={'modal-content'}>
                  {errorMessage} Please logout and try again
                </div>
              </StyledModal>
            </OuterWrapper>
          )
        );
      }
    } else if (
      loading &&
      ((fetching && discoveriesLoading) || (!fetching && !discoveriesLoading))
    ) {
      return (
        <>
          {advanced && (
            <div>
              Loading
              <pre>
                {JSON.stringify({ fetching, loading, discoveriesLoading })}
              </pre>
            </div>
          )}
          <SkeletonVizLoader />
        </>
      );
    } else if (loading && discoveriesLoading && !fetching) {
      return (
        <CenteredPanel>
          <CenteredPanelTitle>
            {messages.dashlet.reportDeletedTitle}
          </CenteredPanelTitle>
          <p>{messages.dashlet.reportDeletedDescr}</p>
        </CenteredPanel>
      );
    }
    return (
      <OuterWrapper width={viewWidth} height={viewHeight}>
        <ActiveFilterPanel
          panelStyle={{
            height: 40,
            overflowX: 'auto',
            borderBottomColor: PanelBorder,
            borderBottomStyle: 'solid',
            borderBottomWidth: 1,
          }}
          discoveryId={vizId}
          viz={viz}
        />
        {showLegendPanel && (
          <VizLegend
            panelStyle={{
              height: 'auto',
              flexShrink: 0,
              flexGrow: 0,
              borderBottomColor: PanelBorder,
              borderBottomStyle: 'solid',
              borderBottomWidth: 1,
              borderLeftWidth: 0,
              borderTopWidth: 0,
            }}
            forceHorizontal={true}
            isResizable={false}
            vizId={vizId}
          />
        )}
        <ChartErrorBoundary {...ErrorBoundaryComponentProps}>
          <Content vizId={vizId} />
        </ChartErrorBoundary>
        <MobileVizFooter
          hideLabels={true}
          onOpenInsights={openMobileInsights}
          vizId={vizId}
          overrideStyle={{
            width: 'calc(100% - 2px)',
            margin: '0 1px 1px',
            height: '36px',
            borderTopColor: PanelBorder,
            zIndex: '80',
          }}
          showInsightsBubble={hasInsights && !insightsChecked}
          insightsLoading={insightsLoading}
          hasInsights={hasInsights}
        />
        <RemoveFieldFromVizDialog />
        <AddFieldToVizWarningDialog />
        <QueryWarningDialog vizId={vizId} />
        <QueryErrorDialog vizId={vizId} />
      </OuterWrapper>
    );
  },
) as any;
