import { ComponentClass } from 'react';
import ImportReportsDuplicateDialog from '../../common/widgets/dialogs/ImportReportsDuplicateDialog';
import { IDataset } from '../../datasets';
import PromptDialog from '../../common/widgets/dialogs/PromptDialog';

export const PromptDialogTyped = PromptDialog as ComponentClass<{
  show?: boolean;
  detail?: any;
  doYes?: any;
  doNo?: any;
}>;

export const ImportReportsDuplicateDialogTyped = ImportReportsDuplicateDialog as ComponentClass<{
  show?: boolean;
  doSave?: any;
  doCancel?: any;
  reportsRequiringInput?: any;
  reportsNotRequiringInput?: any;
  datasets?: IDataset[];
}>;
