import PromptDialog from './PromptDialog';
import { connect } from 'react-redux';
import { messages } from '../../../i18n';
import { HelpBlock } from '../../../views/VizSaveDialog/ui';
import {
  FormRoot,
  ModalLabel,
} from '../../../views/VizSaveDialog/viz-save-dialog.styles';

const Unconnected = ({ show }) => {
  const message = (
    <FormRoot>
      <ModalLabel>
        <HelpBlock className='app-out-of-date-message'>
          {messages.formatString(
            messages.appOutOfDate,
            messages.nonTranslated.discover,
          )}
        </HelpBlock>
      </ModalLabel>
    </FormRoot>
  );
  const refresh = () => {
    window.location.reload();
  };
  return (
    <PromptDialog
      asInfo
      show={show}
      className='app-out-of-date-dialog'
      title='Application out of date'
      detail={message}
      doOk={refresh}
    />
  );
};

const mapStateToProps = state => ({
  show: state.main.showAppOutOfDate,
});

export default connect(mapStateToProps)(Unconnected);
