import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useDiscoverTheme } from '../../../common/emotion/theme';
import { svgColorizePartial } from '../../../common/emotion';

export const FilterTypePanel = styled.div(() => {
  const {
    colors: {
      IconActiveDarkColor,
      IconPrimaryHoverColor,
      IconSecondaryColorHover,
      SVGFillColor,
      SVGFillMediumColor,
      FilterNavLabel,
    },
  } = useDiscoverTheme();

  return css`
    button {
      &.Mui-selected,
      &:focus,
      &:hover {
        .filter-nav-content {
          color: ${FilterNavLabel};
        }
      }
      .filter-nav-content {
        color: ${IconPrimaryHoverColor};
      }
    }

    ${svgColorizePartial({
      fromColor: SVGFillColor,
      toColor: IconActiveDarkColor,
    })(`
      button.Mui-selected .filter-nav-icon,
      button:focus .filter-nav-icon,
      button:hover .filter-nav-icon,
    `)}
    ${svgColorizePartial({
      fromColor: SVGFillMediumColor,
      toColor: IconSecondaryColorHover,
    })(`
      button.Mui-selected .filter-nav-icon,
      button:focus .filter-nav-icon,
      button:hover .filter-nav-icon,
    `)}
    ${svgColorizePartial({
      fromColor: SVGFillColor,
      toColor: IconPrimaryHoverColor,
    })(`
      button .filter-nav-icon,
    `)}
    ${svgColorizePartial({
      fromColor: SVGFillMediumColor,
      toColor: SVGFillMediumColor,
    })(`
      button .filter-nav-icon,
    `)}
  `;
});

export const useTabStyles = () => {
  const {
    colors: { MediumBlue, CorvanaMediumText, LightFontWeight },
  } = useDiscoverTheme();

  return {
    padding: 0,
    textTransform: 'initial',
    fontWeight: LightFontWeight,
    fontSize: '16px',
    color: CorvanaMediumText,
    selected: {
      color: MediumBlue,
    },
    '&:hover': {
      color: MediumBlue,
    },
  };
};

export const FilterNavItem = styled.div`
  display: flex;
  flex-direction: column;

  &:first-of-type > .filter-nav-content {
    margin-left: 4px;
  }
  .filter-nav-icon {
    display: flex;
    justify-content: center;
    margin-bottom: 6px;
  }
  .filter-nav-content {
    margin: 0 8px;
  }
`;
