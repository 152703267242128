import { Component } from 'react';
import { connect } from 'react-redux';
import Main from '../../redux/actions/MainActions';
import { branch, compose, renderNothing } from 'react-recompose';
import { messages } from '../../../i18n';
import { ModalPortal } from './modal-portal';

class ErrorDetailDialog extends Component {
  render() {
    return (
      this.props.show && (
        <ModalPortal
          onHide={this.props.hideError}
          dialogClassName={'error-dialog'}
          title={this.props.title}
          buttonGroup={''}
        >
          <div>
            <h5>{messages.stackTrace}</h5>
            <pre>{this.props.errorDetail}</pre>
          </div>
        </ModalPortal>
      )
    );
  }
}

export default compose(
  connect(
    state => {
      return {
        show: state.main.showErrorDetailDialog,
        errorDetail: state.main.errorDetail,
        title: state.main.errorTitle,
      };
    },
    dispatch => {
      return {
        hideError: () => {
          dispatch(Main.showErrorDetail(false, undefined, undefined));
        },
      };
    },
  ),
  branch(props => {
    return !props.show;
  }, renderNothing),
)(ErrorDetailDialog);
