import { css } from '@emotion/react';
import { Menu } from '@mui/material';
import styled from '@emotion/styled';

export const StyledMenu = styled(Menu)(
  ({
    theme: {
      colors: {
        MediumBorder,
        Strokes,
        DropdownBackgroundHover,
        Gray90,
        ContentText,
      },
    },
  }: any) => css`
    border-radius: 0px;
    box-shadow: none;

    .MuiMenu-list {
      padding-top: 0px;
      padding-bottom: 0px;
      border: ${MediumBorder} 1px solid;
      .MuiMenuItem-root {
        padding: 0px 15px 0px px !important;
        margin: 0 !important;
        height: 35px !important;
        border-bottom: 1px ${Strokes} solid !important;
        color: ${ContentText} !important;
      }
      &:hover {
        background-color: ${DropdownBackgroundHover} !important;
        .sicon {
          color: ${Gray90};
        }
        a {
          color: ${ContentText} !important;
        }
      }
    }
  `,
);

export const mobileVizFooterStyles = css`
  .mobile-viz-footer {
    border-top: 1px solid;
    display: flex;
    flex-direction: row;
    z-index: 1000;
    height: 5vh;
    pointer-events: none;
    .more-kabob {
      flex: 1;
      pointer-events: all;
    }
    .mobile-icon-button {
      pointer-events: all;
    }
    .mobile-link-button {
      margin-left: auto;
    }
    .mobile-icon-button.mobile {
      flex-grow: 2;
    }
    #more-viz-options-btn {
      background-color: transparent;
    }
    .more-kabob svg {
      top: 1px;
      position: relative;
    }
    > div {
      padding-right: 12px;
    }
    > div:first-of-type {
      padding-left: 12px;
    }
    > div:last-of-type {
      padding-right: 12px;
    }
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        .mobile-viz-footer > div:first-of-type {
          padding: 4px 0 0 12px;
        }
      }
    }
    .btn-group .btn-default {
      border: none;
      padding: 0;
      width: 24px;
    }
    .btn-default.active,
    .open > .dropdown-toggle.btn-default,
    .btn-group .btn-default:hover,
    .btn-group .btn-group-default:active,
    .btn-group .btn-default:active {
      border: none;
      box-shadow: none;
    }
    .btn-group .btn-default:focus {
      background-color: transparent;
    }
  }
`;

export const ClickthroughDiv = styled.div`
  width: 5px;
  pointer-events: none;
`;

export const MoreKabob = styled.div`
  display: flex;
  align-items: center;
`;
